import React from "react";

const ViewDrawer = ({ styleDrawer, cssHandle }) => {
  return (
    <div style={styleDrawer}>
      <div className={cssHandle}></div>
    </div>
  );
};

export default ViewDrawer;

//className={cssDrawer}