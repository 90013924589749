import React, { useState  } from "react";
import { useSelector } from "react-redux";
import { Typography } from '@mui/material';
import All from "./components/ALL";
import './App.css'

function App()  {
  const { scrollHeight } = useSelector(state => state.kim)

  return (

       <All scrollHeight ={scrollHeight}/>

  );
  
}

export default App;
