import React, { useState, useEffect } from 'react';
import './index.css';
import { useSelector, useDispatch } from 'react-redux';
import Draggable from 'react-draggable';
import { Typography } from '@mui/material';
import Sidebar from '../Sidebar';
import Main from '../Main';
import ViewDrawer from '../ViewDrawer';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Slide from '@mui/material/Slide';

import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';

import styled from 'styled-components';

import backgroundImage from './background.png';
import logoImage from './logo.png';

// 引入Redux的動作（action）和狀態（state）來自KIM_info/KIMSlice
import {
  deleteSubTask,
  setCurrentSubProject,
  deleteProject,
  deleteAllSubTask,
  addSubTask,
  setProjectWinClose,
  addProject,
  addDecideWin,
  addDecideWin2,
  setDecideWinClose,
  setDecideWin2Close,
  sendAddProject,
  setCurrentProject,
  setPotentialCurrentProjectName,
  setPotentialCurrentProjectKIM,
  setTotalCalcu,
} from '../../redux/features/KIM_info/KIMSlice';

// 定義一個名為 "All" 的React元件
export const All = ({ scrollHeight }) => {
  const [positionFirst, setPositionFirst] = useState({ positionFirstX: 0 });
  const {
    KIM_Tools,
    projects,
    addProjectWinOpen,
    decideKIMToolWinOpen,
    decideKIMToolWin2Open,
    potentialCurrentProjectName,
    potentialCurrentProjectsKIM,
    total,
  } = useSelector((state) => state.kim);

  // 定義拖曳事件的處理函式
  const onDragFirst = (e, data) => {
    setPositionFirst({ positionFirstX: data.x });
  };

  const dispatch = useDispatch();

  // 定義側邊欄、主要內容和抽屜的樣式
  const styleSidebar = {
    height: scrollHeight,
    backgroundColor: '#f5fbff',
    width: positionFirst.positionFirstX + 500,
    padding: '70px 30px 70px 30px',
  };

  const styleMain = {
    // backgroundColor: "beige",
  };

  const drawerFirst = {
    position: 'relative',
    height: scrollHeight,
    width: '10px',
    background: 'lightgrey',
    cursor: 'col-resize',
    '&:hover': {
      background: 'pink',
    },
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // 根據視窗尺寸和專案數量渲染不同的內容
  if (window.innerWidth > window.innerHeight && projects.length !== 0) {
    return (
      <div className="app">
        <Sidebar cssSidebar="sidebar" styleSidebar={styleSidebar} />
        <Draggable defaultPosition={{ x: 0, y: 0 }} position={{ x: positionFirst.positionFirstX }} onDrag={onDragFirst}>
          <div>
            <ViewDrawer styleDrawer={drawerFirst} cssHandle="handleFirst" />
          </div>
        </Draggable>

        <Main cssMain="main" styleMain={styleMain} />
      </div>
    );
  } else if (window.innerWidth > window.innerHeight && projects.length === 0) {
    return (
      <div
        style={{
          height: '100%',
          minHeight: '100vh',
          width: '100%',
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: `center`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `cover`,
          backgroundAttachment: `fixed`,
          color: 'black',
        }}
      >
        {/* 在這個條件下，渲染包含對話框和其他元素的內容 */}
        {/* 注意：這部分程式碼有HTML和Material-UI元件的結合 */}

        <Dialog
          onClose={() => {
            return dispatch(setProjectWinClose(true));
          }}
          open={addProjectWinOpen}
        >
          <DialogTitle
            sx={{
              width: '400px',
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              mb: '20px',
              fontSize: '25px',
            }}
          >
            新增KIM專案
          </DialogTitle>
          <Block justifyContent="flex-start" flexDirection="row" pb="30px">
            <Box sx={{ color: 'black', alignItems: 'cetner', margin: 'auto 10px auto 20px' }}>ProjectName: </Box>
            <TextField
              id="outlined-basic"
              defaultValue={potentialCurrentProjectName}
              label="請輸入名稱"
              variant="outlined"
              onChange={(e) => {
                return dispatch(setPotentialCurrentProjectName(e.target.value));
              }}
            />
          </Block>
          <Block justifyContent="flex-start" flexDirection="row" pb="20px">
            <Box sx={{ color: 'black', alignItems: 'cetner', margin: 'auto 40px auto 20px' }}>KIM-Tool: </Box>

            <Box sx={{ width: '200px' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">請選擇KIM-Tool</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={potentialCurrentProjectsKIM}
                  label="請選擇KIM-Tool"
                  onChange={(e) => {
                    return dispatch(setPotentialCurrentProjectKIM(e.target.value));
                  }}
                >
                  {KIM_Tools && KIM_Tools.length > 0
                    ? KIM_Tools.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
              </FormControl>
            </Box>
          </Block>
          <Block justifyContent="flex-end" pb="30px" flexDirection="row">
            <Box
              sx={{
                width: '50px',
                color: 'black',
                height: '50px',
                fontWeight: 'bold',
                border: '2px black solid',
                borderRadius: '50px',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  backgroundColor: 'black',
                  color: 'white',
                },
              }}
              onClick={(e) => {
                return dispatch(
                  sendAddProject({ name: potentialCurrentProjectName, KIM: potentialCurrentProjectsKIM })
                );
              }}
            >
              OK
            </Box>
          </Block>
        </Dialog>

        <Container maxWidth="md">
          <Box sx={{ border: '0px solid white', padding: '30px' }}>
            <Box
              sx={{ color: '#42652B', fontSize: '40px', fontWeight: '800', marginBottom: '15px', textAlign: 'center' }}
            >
              人因危害風險評估工具
              <br />
              KIM Tool 2019
            </Box>
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction={{ xs: 'column', sm: 'row' }}
              useFlexGap
              flexWrap="wrap"
              justifyContent="space-around"
            >
              <Box sx={{ color: '#42652B', fontSize: '20px' }}>✓ 涵蓋六種評估工具 </Box>
              <Box sx={{ color: '#42652B', fontSize: '20px' }}>✓ 評估工具挑選指引 </Box>
              <Box sx={{ color: '#42652B', fontSize: '20px' }}>✓ 子項作業整併計算 </Box>
              <Box sx={{ color: '#42652B', fontSize: '20px' }}>✓ 提供內插公式 </Box>
            </Stack>

            <br />

            {/* 顯示一些文本和一個按鈕，允許用戶新增KIM專案 */}

            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction={{ xs: 'column', sm: 'row' }}
              useFlexGap
              flexWrap="wrap"
              sx={{ marginBottom: '20px' }}
            >
              <Box
                sx={{
                  color: 'rgba(0,0,0,1)',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  marginBottom: '0',
                  flex: '1 1 auto',
                }}
              >
                使用說明：
              </Box>

              <Box
                sx={{ color: 'rgba(0,0,0,0.8)', fontSize: '18px', marginBottom: '0', flex: '1 1 calc(100% - 110px)' }}
              >
                本「軟體產品」受著作權法、國際著作權條約以及其他智慧財產權之法律及條約之保護。「軟體產品」僅係經授權使用，而非販售賣斷，經「軟體產品」評估之結果，僅供人因危害風險評估參考，如對評估之結果有疑義者，請洽職業醫學專科醫師或相關專業人員詢問。
              </Box>
            </Stack>

            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction={{ xs: 'column', sm: 'row' }}
              useFlexGap
              flexWrap="wrap"
              sx={{ marginBottom: '20px' }}
            >
              <Box
                sx={{
                  color: 'rgba(0,0,0,1)',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  marginBottom: '0',
                  flex: '1 1 auto',
                }}
              >
                版權聲明：
              </Box>
              <Box
                sx={{ color: 'rgba(0,0,0,0.8)', fontSize: '18px', marginBottom: '0', flex: '1 1 calc(100% - 110px)' }}
              >
                感謝 BAuA 及 LASI 正式授權推廣使用 KIM PP (Version2019)、KIM LHC (Version2019)、KIM MHO
                (Version2019)、KIM ABP (Version2019)、KIM BM (Version2019)、KIM BF(Version2019)。
                <br />
                本軟體禁止任何商業行為及用途。
                <br />
                Thanks to the Federal Institute for Occupational Safety and Health (BAuA) AND Committee of the Laender
                for Occupational Safety and Health (LASI), Germany for their kindly permission of translation on the
                KIM-LHC (2019), KIM-PP (2019), and KIM-MHO(2019), KIM ABP (Version2019), KIM BM (Version2019), KIM BF
                (Version2019). <br />
                Any commercial use is prohibited.
              </Box>
            </Stack>

            <Stack spacing={{ xs: 1, sm: 2 }} direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
              <Box sx={{ flex: '1 1 auto' }}>
                <Stack
                  spacing={{ xs: 1, sm: 2 }}
                  direction={{ xs: 'column', sm: 'row' }}
                  useFlexGap
                  flexWrap="wrap"
                  sx={{ marginBottom: '20px' }}
                >
                  <Box
                    sx={{
                      color: 'rgba(0,0,0,1)',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      marginBottom: '0',
                      flex: '1 1 auto',
                    }}
                  >
                    版權所有：
                  </Box>
                  <Box
                    sx={{
                      color: 'rgba(0,0,0,0.8)',
                      fontSize: '18px',
                      marginBottom: '0',
                      flex: '1 1 calc(100% - 110px)',
                    }}
                  >
                    <Box component="img" src={logoImage} width={'20px'} sx={{ marginBottom: '3px' }}></Box>
                    <Box component="span" sx={{ marginLeft: '5px' }}>
                      財團法人職業災害預防及重建中心
                    </Box>
                  </Box>
                </Stack>

                <Stack
                  spacing={{ xs: 1, sm: 2 }}
                  direction={{ xs: 'column', sm: 'row' }}
                  useFlexGap
                  flexWrap="wrap"
                  sx={{ marginBottom: '20px' }}
                >
                  <Box
                    sx={{
                      color: 'rgba(0,0,0,1)',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      marginBottom: '0',
                      flex: '1 1 auto',
                    }}
                  >
                    軟體開發：
                  </Box>
                  <Box
                    sx={{
                      color: 'rgba(0,0,0,0.8)',
                      fontSize: '18px',
                      marginBottom: '0',
                      flex: '1 1 calc(100% - 110px)',
                    }}
                  >
                    臺北科技大學 人因工程與績效評估研究室
                  </Box>
                </Stack>
              </Box>

              <Box
                onClick={() => {
                  return dispatch(addProject());
                }}
                sx={{
                  fontSize: '25px',
                  fontWeight: '900',
                  border: '2px solid white',
                  borderRadius: '10px',
                  color: 'white',
                  padding: '3%',
                  cursor: 'pointer',
                  background: 'linear-gradient(180deg, rgba(41,171,226,1) 0%, rgba(0,113,188,1) 100%)',
                  textAlign: 'center',
                  '&:hover': {
                    background: '#F09135',
                    color: 'white',
                    border: '2px solid rgba(247,246,240,0.6)',
                    boxShadow: '0px 0px 6px 1px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <Box sx={{ width: '60px;', hegih: '60px;', margin: '0 auto' }}>
                  <svg width="60px" height="60px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4 12H20M12 4V20"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Box>
                點選新增專案
              </Box>
            </Stack>
          </Box>
        </Container>
      </div>
    );
  } else {
    return (
      // 在螢幕尺寸不合適時，顯示一條警告消息
      <Typography
        sx={{
          color: 'white',
          backgroundColor: 'gray',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        為了方便使用，請改用電腦/平板操作，或者重新整理畫面。
      </Typography>
    );
  }

  // return (
  //   <div className="app" >
  //     <Sidebar cssSidebar="sidebar" styleSidebar={styleSidebar} />
  //     <Draggable
  //       defaultPosition={{ x: 0, y: 0 }}
  //       position={{ x: positionFirst.positionFirstX }}
  //       onDrag={onDragFirst}
  //     >
  //       <div>
  //         <ViewDrawer styleDrawer={drawerFirst} cssHandle="handleFirst" />
  //       </div>
  //     </Draggable>

  //     <Main cssMain="main" styleMain={styleMain} />
  //   </div>
  // )
};

const Block = styled.div`
  // border:1px solid red;
  color: #ffffff;
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.justifyContent};
  padding-bottom: ${(props) => props.pb};
`;

export default All;
