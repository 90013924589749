import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

export const KIM_info = createSlice({
  name: 'KIM_info',
  initialState: {
    scrollHeight: '10000px',
    KIM_Tools: ['KIM-LHC', 'KIM-PP', 'KIM-MHO', 'KIM-ABP', 'KIM-BM', 'KIM-BF'],
    addProjectWinOpen: false,
    decideKIMToolWinOpen: false,
    decideKIMToolWin2Open: false,
    potentialCurrentProjectName: 'Project 1',
    potentialCurrentProjectsKIM: 'KIM-LHC',
    kimpp2people: false,
    currentProject: '',
    countAccumProject: 0,
    currentSubProject: 1,
    currentProjectsKIM: '',//'KIM-LHC'
    currentProjectsKIMBM: 'A',//A or B
    projectsName: [
      // 'Project1',
      // 'Project2',
    ],
    projectsKIM: [
      // 'KIM-LHC',
      // 'KIM-LHC'
    ],
    projects: [
      // [
      //   //Project1

      //   {
      //     sex:"F",
      //     one:1,//time
      //     two:[4,6],
      //     three:1,
      //     four:1,
      //     five:1,
      //     okPosition:[

      //     ]
      //     total:[
      //
      //     ]
      //   }

      // ],
      // [
      //   //Project2
      //   {
      //   sex:"M",
      //   one:1,//time
      //   two:1,
      //   three:3,
      //   four:1,
      //   five:1,
      //   okPosition:[

      //   ]},
      //     total:[
      //
      //     ]
      // ],
    ],
    okPosition: [
      [

      ],
      [

      ]
    ],
    total: [
      //子項目1
      // []
      //子項目2
      // []
    ]

  },
  reducers: {
    setKimpp2people: (state, action) => {
      state.kimpp2people = action.payload
    },
    setCurrentProjectsKIMBM: (state, action) => {
      state.currentProjectsKIMBM = action.payload

      const project = state.currentProject - 1
      const subProject = state.currentSubProject - 1

      state.okPosition[0][1] = {}
      state.okPosition[0][2] = {}
      state.okPosition[0][3] = {}
      state.okPosition[0][4] = {}

      state.projects[project][subProject].okPosition = state.okPosition

      state.projects[project][subProject].two = 0
      state.projects[project][subProject].three = 0
      state.projects[project][subProject].four = 0
      state.projects[project][subProject].five = 0

    },
    setScrollHeight: (state, action) => {
      state.scrollHeight = action.payload
    },
    setSex: (state, action) => {

      const projects = state.projects
      const currentProject = state.currentProject
      const currentSubProject = state.currentSubProject
      const subProject = projects[currentProject - 1][currentSubProject - 1];

      for (let i = 0; i < projects[currentProject - 1].length; i++) {
        if (action.payload == true) {
          projects[currentProject - 1][i].sex = 'M'
        } else {
          projects[currentProject - 1][i].sex = 'F'
        }
      }


    },
    setTotalCalcu: (state, action) => {
      const total = state.total
      const projects = state.projects
      const currentProjectsKIM = state.currentProjectsKIM
      const currentProject = state.currentProject
      const currentSubProject = state.currentSubProject

      const subProject = projects[currentProject - 1][currentSubProject - 1];
      if (currentProject && currentProjectsKIM === 'KIM-LHC') {

       
              
        for (let i = 0; i < projects[currentProject - 1].length; i++) {
           //有效負荷重量
          if (subProject.sex === 'M') {
      
            state.total[i][0] =  projects[currentProject - 1][i].two[0]
          } else {
            state.total[i][0] =  projects[currentProject - 1][i].two[1]
          }

        }

        // for (let i = 0; i < projects[currentProject - 1].length; i++) {
        //   if (action.payload == true) {
        //     projects[currentProject - 1][i].sex = 'M'
        //   } else {
        //     projects[currentProject - 1][i].sex = 'F'
        //   }
        // }
        
        //負重處理條件
        state.total[currentSubProject - 1][1] = subProject.three
        //身體姿勢+額外
        let additonal= subProject.five + subProject.six + subProject.seven + subProject.eight + subProject.nine
        if (additonal>=6){
          additonal=6
        }
        state.total[currentSubProject - 1][2] = subProject.four + additonal
        
        //不友善的工作條件
        state.total[currentSubProject - 1][3] = subProject.ten + subProject.eleven + subProject.twelve + subProject.thirteen + subProject.fourteen + subProject.fifteen + subProject.sixteen
        //工作組織/時間分配
        state.total[currentSubProject - 1][4] = subProject.seventeen
        //
        state.total[currentSubProject - 1][5] = ""

          for (let i = 0; i < projects[currentProject - 1].length; i++) {
                    //總評級
          state.total[i][6] =
          parseFloat(state.total[i][0])
          + parseFloat(state.total[i][1])
          + parseFloat(state.total[i][2])
          + parseFloat(state.total[i][3])
          + parseFloat(state.total[i][4])
 
         }
 
        //次/分/米
        if (subProject.one === 1) {
          state.total[currentSubProject - 1][7] = 5
        } else if (subProject.one === 1.5) {
          state.total[currentSubProject - 1][7] = 20
        } else if (subProject.one === 2) {
          state.total[currentSubProject - 1][7] = 50
        } else if (subProject.one === 2.5) {
          state.total[currentSubProject - 1][7] = 100
        } else if (subProject.one === 3) {
          state.total[currentSubProject - 1][7] = 150
        } else if (subProject.one === 3.5) {
          state.total[currentSubProject - 1][7] = 220
        } else if (subProject.one === 4) {
          state.total[currentSubProject - 1][7] = 300
        } else if (subProject.one === 5) {
          state.total[currentSubProject - 1][7] = 500
        } else if (subProject.one === 6) {
          state.total[currentSubProject - 1][7] = 750
        } else if (subProject.one === 7) {
          state.total[currentSubProject - 1][7] = 1000
        } else if (subProject.one === 8) {
          state.total[currentSubProject - 1][7] = 1500
        } else if (subProject.one === 9) {
          state.total[currentSubProject - 1][7] = 2000
        } else if (subProject.one === 10) {
          state.total[currentSubProject - 1][7] = 2500
        } else {
          if (Math.round((Math.pow((subProject.one - 0.56), 2)) * (5 / 0.1936)) === 0) {
            state.total[currentSubProject - 1][7] = 0
          } else {
            state.total[currentSubProject - 1][7] = (Math.pow((subProject.one - 0.56), 2)) * (5 / 0.1936)
          }
        }
        //時間權重
        if (Math.round((Math.pow((subProject.one - 0.56), 2)) * (5 / 0.1936)) === 0) {
          state.total[currentSubProject - 1][8] = 0
        } else {
          state.total[currentSubProject - 1][8] = subProject.one
        }

        
       for (let i = 0; i < projects[currentProject - 1].length; i++) {
        //子項風險
            state.total[i][9] = state.total[i][6] * state.total[i][8]
      }


        //總風險值
        //BubbleSort (降冪)
        var n = 0
        if (projects[currentProject - 1].length > 1 && state.total[1][9] !== 0) {
          var tsk = [[], []]
          for (var i = 0; i < projects[currentProject - 1].length; i++) {
            tsk[0].push(state.total[i][6])//總評級
            tsk[1].push(state.total[i][7])//次/分/米
          }
          n = tsk[1].length

          var tmp = 0;
          //bubble sort
          for (var j = 0; j < n; j++) {
            for (var k = 0; k < n - j; k++) {
              if (tsk[0][k] < tsk[0][k + 1]) { //switch
                for (var i = 0; i < 2; i++) {
                  tmp = tsk[i][k]
                  tsk[i][k] = tsk[i][k + 1]
                  tsk[i][k + 1] = tmp
                }
              }
            }
          }

          //Combination
          var Tsk_comb = 0
          var t_1 = 0
          var t_2 = 0
          Tsk_comb = tsk[0][0] * (0.56 + 0.44 * Math.sqrt(tsk[1][0] / 5))
          t_1 = tsk[1][0] //次/分/米1
          for (var j = 1; j < n; j++) {
            if (tsk[1][j] !== "") {
              t_2 = parseFloat(t_1) + parseFloat(tsk[1][j])
              Tsk_comb = Tsk_comb + tsk[0][j] * ((0.56 + 0.44 * Math.sqrt(t_2 / 5)) - (0.56 + 0.44 * Math.sqrt(t_1 / 5)))
              t_1 = t_2
            }
          }
          state.total[0][10] = Math.round(Tsk_comb * 100) / 100 //總風險值

          ////其他子項目的總風險值欄位為“”
          for (var j = 1; j < state.total.length; j++) {
            state.total[j][10] = ""
          }
        } else {
          state.total[0][10] = state.total[0][9]
        }

        //存
        for (var j = 0; j < state.total.length; j++) {
          state.projects[currentProject - 1][j].total = state.total[j]
        }

      } else if (currentProject && currentProjectsKIM === 'KIM-PP') {

        
        //負載總重量
        state.total[currentSubProject - 1][0] = subProject.two
    

        // 車道條件
        state.total[currentSubProject - 1][1] = subProject.three + subProject.four

        // 不友善的工作條件
        state.total[currentSubProject - 1][2] = subProject.five + subProject.six + subProject.seven + subProject.eight + subProject.nine + subProject.ten

        // 不利的搬運設備特性
        state.total[currentSubProject - 1][3] = subProject.eleven

        // 身體姿勢/動作
        state.total[currentSubProject - 1][4] = subProject.twelve

        // 工作組織/時間分配
        state.total[currentSubProject - 1][5] = subProject.thirteen

        //總評級
        state.total[currentSubProject - 1][6] = state.total[currentSubProject - 1][0] + state.total[currentSubProject - 1][1] + state.total[currentSubProject - 1][2] + state.total[currentSubProject - 1][3] + state.total[currentSubProject - 1][4] + state.total[currentSubProject - 1][5]

        //次/分/米
        if (subProject.one === 1) {
          state.total[currentSubProject - 1][7] = 40
        } else if (subProject.one === 1.5) {
          state.total[currentSubProject - 1][7] = 200
        } else if (subProject.one === 2) {
          state.total[currentSubProject - 1][7] = 400
        } else if (subProject.one === 2.5) {
          state.total[currentSubProject - 1][7] = 800
        } else if (subProject.one === 3) {
          state.total[currentSubProject - 1][7] = 1200
        } else if (subProject.one === 3.5) {
          state.total[currentSubProject - 1][7] = 1800
        } else if (subProject.one === 4) {
          state.total[currentSubProject - 1][7] = 2500
        } else if (subProject.one === 5) {
          state.total[currentSubProject - 1][7] = 4200
        } else if (subProject.one === 6) {
          state.total[currentSubProject - 1][7] = 6300
        } else if (subProject.one === 7) {
          state.total[currentSubProject - 1][7] = 8400
        } else if (subProject.one === 8) {
          state.total[currentSubProject - 1][7] = 11000
        } else if (subProject.one === 9) {
          state.total[currentSubProject - 1][7] = 15000
        } else if (subProject.one === 10) {
          state.total[currentSubProject - 1][7] = 20000
        } else {
          if (Math.round((Math.pow((subProject.one - 0.56), 2)) * (42 / 0.1936)) === 0) {
            state.total[currentSubProject - 1][7] = 0
          } else {
            state.total[currentSubProject - 1][7] = (Math.pow((subProject.one - 0.56), 2)) * (42 / 0.1936)
          }
        }

        //時間權重
        if (Math.round((Math.pow((subProject.one - 0.56), 2)) * (42 / 0.1936)) === 0) {
          state.total[currentSubProject - 1][8] = 0
        } else {
          state.total[currentSubProject - 1][8] = subProject.one
        }







      
        
        for (let i = 0; i < projects[currentProject - 1].length; i++) {
              //子項風險
          if (subProject.sex === 'M') {
            state.total[i][9] = state.total[i][6] * state.total[i][8]
          } else {
            state.total[i][9] = (state.total[i][6] * state.total[i][8] * 1.3).toFixed(2)
          }

        }



        if (state.kimpp2people) {
          state.total[currentSubProject - 1][9] = state.total[currentSubProject - 1][9] * 1.2
        } else {
          state.total[currentSubProject - 1][9] = state.total[currentSubProject - 1][9] * 1
        }




        //總風險值
        //BubbleSort (降冪)
        var n = 0
        if (projects[currentProject - 1].length > 1 && state.total[1][9] !== 0) {
          var tsk = [[], []]
          for (var i = 0; i < projects[currentProject - 1].length; i++) {
            tsk[0].push(state.total[i][6])//總評級
            tsk[1].push(state.total[i][7])//次/分/米
          }
          n = tsk[1].length

          var tmp = 0;
          //bubble sort
          for (var j = 0; j < n; j++) {
            for (var k = 0; k < n - j; k++) {
              if (tsk[0][k] < tsk[0][k + 1]) { //switch
                for (var i = 0; i < 2; i++) {
                  tmp = tsk[i][k]
                  tsk[i][k] = tsk[i][k + 1]
                  tsk[i][k + 1] = tmp
                }
              }
            }
          }

          //Combination
          var Tsk_comb = 0
          var t_1 = 0
          var t_2 = 0
          Tsk_comb = tsk[0][0] * (0.56 + 0.44 * Math.sqrt(tsk[1][0] / 42))
          t_1 = tsk[1][0] //次/分/米1
          for (var j = 1; j < n; j++) {
            if (tsk[1][j] !== "") {
              t_2 = parseFloat(t_1) + parseFloat(tsk[1][j])
              Tsk_comb = Tsk_comb + tsk[0][j] * ((0.56 + 0.44 * Math.sqrt(t_2 / 42)) - (0.56 + 0.44 * Math.sqrt(t_1 / 42)))
              t_1 = t_2
            }
          }
          
          if (subProject.sex === 'M') {
            state.total[0][10] = Math.round(Tsk_comb * 100) / 100 //總風險值
          } else {
            state.total[0][10] = (Math.round(Tsk_comb * 100) / 100)  * 1.3 
          }


          ////其他子項目的總風險值欄位為“”
          for (var j = 1; j < state.total.length; j++) {
            state.total[j][10] = ""
          }
        } else {
          state.total[0][10] = state.total[0][9]
        }

        //存
        for (var j = 0; j < state.total.length; j++) {
          state.projects[currentProject - 1][j].total = state.total[j]
        }


      } else if (currentProject && currentProjectsKIM === 'KIM-MHO') {


        //KIM-MHO" Then 'Linear
        //手指/手部區域的施力方式
        state.total[currentSubProject - 1][0]
          = subProject.two + subProject.three + subProject.four + subProject.five
          + subProject.six + subProject.twelve + subProject.thirteen + subProject.fourteen
          + subProject.fifteen + subProject.sixteen + subProject.seventeen



        //力量傳遞／抓握條件
        state.total[currentSubProject - 1][1] = subProject['twenty-four']
        //手／臂位置及動作
        state.total[currentSubProject - 1][2] = subProject['twenty-five']
        //不利工作狀況
        state.total[currentSubProject - 1][3] = subProject['twenty-six']

        //身體姿勢/動作
        state.total[currentSubProject - 1][4] = subProject['twenty-seven']

        //工作組織/時間分配
        state.total[currentSubProject - 1][5] = subProject['twenty-eight']

        //總評級
        state.total[currentSubProject - 1][6] = state.total[currentSubProject - 1][0] + state.total[currentSubProject - 1][1] + state.total[currentSubProject - 1][2] + state.total[currentSubProject - 1][3] + state.total[currentSubProject - 1][4] + state.total[currentSubProject - 1][5]

        //次/分/米
        if (subProject.one * 60 === 0) {
          state.total[currentSubProject - 1][7] = 0
        } else {
          state.total[currentSubProject - 1][7] = subProject.one * 60
        }


        //時間權重
        state.total[currentSubProject - 1][8] = subProject.one

        //子項風險
        state.total[currentSubProject - 1][9] = state.total[currentSubProject - 1][6] * state.total[currentSubProject - 1][8]


        state.total[0][10] = 0
        //總風險值
        var n = 0
        if (projects[currentProject - 1].length > 1 && state.total[1][9] !== 0) {
          for (var i = 0; i < projects[currentProject - 1].length; i++) {
            state.total[0][10] += state.total[i][9]
          }
          ////其他子項目的總風險值欄位為“”
          for (var j = 1; j < state.total.length; j++) {
            state.total[j][10] = ""
          }
        } else {
          state.total[0][10] = state.total[0][9]
        }

        //存
        for (var j = 0; j < state.total.length; j++) {
          state.projects[currentProject - 1][j].total = state.total[j]
        }

      } else if (currentProject && currentProjectsKIM === 'KIM-BM') {
        if (state.currentProjectsKIMBM == 'A') {
          //A: 運動類型與攜帶重量
          state.total[currentSubProject - 1][0] = subProject.two
          //A: 負載中心位置
          state.total[currentSubProject - 1][1] = subProject.three
          //A 姿勢
          state.total[currentSubProject - 1][2] = subProject.four
          //A 不利工作狀況
          state.total[currentSubProject - 1][3] = subProject.five
        } else {
          // B 身體運動
          state.total[currentSubProject - 1][0] = subProject.two
          //B 無
          state.total[currentSubProject - 1][1] = 0
          //B 無
          state.total[currentSubProject - 1][2] = 0
          // B 不利工作狀況
          state.total[currentSubProject - 1][3] = subProject.four + subProject.five
        }


        //工作組織/時間分配
        state.total[currentSubProject - 1][4] = subProject.six

        //總評級
        state.total[currentSubProject - 1][5] = state.total[currentSubProject - 1][0] + state.total[currentSubProject - 1][1] + state.total[currentSubProject - 1][2] + state.total[currentSubProject - 1][3] + state.total[currentSubProject - 1][4]


        //次/分/米
        if (subProject.one === 1) {
          state.total[currentSubProject - 1][6] = 1
        } else if (subProject.one === 1.5) {
          state.total[currentSubProject - 1][6] = 5
        } else if (subProject.one === 2) {
          state.total[currentSubProject - 1][6] = 10
        } else if (subProject.one === 2.5) {
          state.total[currentSubProject - 1][6] = 20
        } else if (subProject.one === 3) {
          state.total[currentSubProject - 1][6] = 30
        } else if (subProject.one === 3.5) {
          state.total[currentSubProject - 1][6] = 45
        } else if (subProject.one === 4) {
          state.total[currentSubProject - 1][6] = 60
        } else if (subProject.one === 5) {
          state.total[currentSubProject - 1][6] = 100
        } else if (subProject.one === 6) {
          state.total[currentSubProject - 1][6] = 150
        } else if (subProject.one === 7) {
          state.total[currentSubProject - 1][6] = 210
        } else if (subProject.one === 8) {
          state.total[currentSubProject - 1][6] = 270
        } else if (subProject.one === 9) {
          state.total[currentSubProject - 1][6] = 360
        } else if (subProject.one === 10) {
          state.total[currentSubProject - 1][6] = 480
        } else {
          if (Math.round((Math.pow((subProject.one - 0.56), 2)) * (1 / 0.1936)) === 0) {
            state.total[currentSubProject - 1][6] = 0
          } else {
            state.total[currentSubProject - 1][6] = (Math.pow((subProject.one - 0.56), 2)) * (1 / 0.1936)
          }
        }


        //時間權重
        if (Math.round((Math.pow((subProject.one - 0.56), 2)) * (1 / 0.1936)) === 0) {
          state.total[currentSubProject - 1][7] = 0
        } else {
          state.total[currentSubProject - 1][7] = subProject.one
        }



        //子項風險


        //施力
        for (let i = 0; i < projects[currentProject - 1].length; i++) {
          if (subProject.sex === 'M') {
            state.total[i][8] = state.total[i][5] * state.total[i][7]
          } else {
            state.total[i][8] = (state.total[i][5] * state.total[i][7]) * 1.3
          }
  

        }

       
        //總風險值
        //BubbleSort (降冪)
        var n = 0
        if (projects[currentProject - 1].length > 1 && state.total[1][8] !== 0) {
          var tsk = [[], []]
          for (var i = 0; i < projects[currentProject - 1].length; i++) {
            tsk[0].push(state.total[i][5])//總評級
            tsk[1].push(state.total[i][6])//次/分/米
          }
          n = tsk[1].length

          var tmp = 0;
          //bubble sort
          for (var j = 0; j < n; j++) {
            for (var k = 0; k < n - j; k++) {
              if (tsk[0][k] < tsk[0][k + 1]) { //switch
                for (var i = 0; i < 2; i++) {
                  tmp = tsk[i][k]
                  tsk[i][k] = tsk[i][k + 1]
                  tsk[i][k + 1] = tmp
                }
              }
            }
          }

          //Combination
          var Tsk_comb = 0
          var t_1 = 0
          var t_2 = 0
          Tsk_comb = tsk[0][0] * (0.56 + 0.44 * Math.sqrt(tsk[1][0]))
          t_1 = tsk[1][0] //次/分/米1
          for (var j = 1; j < n; j++) {
            if (tsk[1][j] !== "") {
              t_2 = parseFloat(t_1) + parseFloat(tsk[1][j])
              Tsk_comb = Tsk_comb + tsk[0][j] * ((0.56 + 0.44 * Math.sqrt(t_2)) - (0.56 + 0.44 * Math.sqrt(t_1)))
              t_1 = t_2
            }
          }
       

          if (subProject.sex === 'M') {
            state.total[0][9] = Math.round(Tsk_comb * 100) / 100 //總風險值
          } else {
            state.total[0][9] = (Math.round(Tsk_comb * 100) / 100) * 1.3
          }
  

          ////其他子項目的總風險值欄位為“”
          for (var j = 1; j < state.total.length; j++) {
            state.total[j][9] = ""
          }
        } else {
          state.total[0][9] = state.total[0][8]
        }

        //存
        for (var j = 0; j < state.total.length; j++) {
          state.projects[currentProject - 1][j].total = state.total[j]
        }

      } else if (currentProject && currentProjectsKIM === 'KIM-BF') {


    
        for (let i = 0; i < projects[currentProject - 1].length; i++) {
             //施力
        if (subProject.sex === 'M') {
          state.total[i][0] = subProject.two
        } else {
          state.total[i][0] = subProject.two * 1.5
        }
       }


        //施力對稱
        state.total[currentSubProject - 1][1] = subProject.three

        //身體姿勢
        state.total[currentSubProject - 1][2] = subProject.four

        //不利工作狀況
        state.total[currentSubProject - 1][3] = subProject.five + subProject.six + subProject.seven + subProject.eight + subProject.nine + subProject.ten

        //工作組織/時間分配
        state.total[currentSubProject - 1][4] = subProject.eleven

        //總評級
        state.total[currentSubProject - 1][5] = state.total[currentSubProject - 1][0] + state.total[currentSubProject - 1][1] + state.total[currentSubProject - 1][2] + state.total[currentSubProject - 1][3] + state.total[currentSubProject - 1][4]
        //次/分/米
        if (subProject.one === 1) {
          state.total[currentSubProject - 1][6] = 1
        } else if (subProject.one === 1.5) {
          state.total[currentSubProject - 1][6] = 5
        } else if (subProject.one === 2) {
          state.total[currentSubProject - 1][6] = 10
        } else if (subProject.one === 2.5) {
          state.total[currentSubProject - 1][6] = 20
        } else if (subProject.one === 3) {
          state.total[currentSubProject - 1][6] = 30
        } else if (subProject.one === 3.5) {
          state.total[currentSubProject - 1][6] = 45
        } else if (subProject.one === 4) {
          state.total[currentSubProject - 1][6] = 60
        } else if (subProject.one === 5) {
          state.total[currentSubProject - 1][6] = 100
        } else if (subProject.one === 6) {
          state.total[currentSubProject - 1][6] = 150
        } else if (subProject.one === 7) {
          state.total[currentSubProject - 1][6] = 210
        } else if (subProject.one === 8) {
          state.total[currentSubProject - 1][6] = 270
        } else if (subProject.one === 9) {
          state.total[currentSubProject - 1][6] = 360
        } else if (subProject.one === 10) {
          state.total[currentSubProject - 1][6] = 480
        } else {
          if (Math.round((Math.pow((subProject.one - 0.56), 2)) * (1 / 0.1936)) === 0) {
            state.total[currentSubProject - 1][6] = 0
          } else {
            state.total[currentSubProject - 1][6] = Math.round((Math.pow((subProject.one - 0.56), 2)) * (1 / 0.1936))
          }
        }
        //時間權重
        state.total[currentSubProject - 1][7] = subProject.one
        

        
        for (let i = 0; i < projects[currentProject - 1].length; i++) {
          //子項風險
        state.total[i][8] = state.total[i][7] * state.total[i][5]

    }


        //總風險值
        //BubbleSort (降冪)
        var n = 0
        if (projects[currentProject - 1].length > 1 && state.total[1][8] !== 0) {
          var tsk = [[], []]
          for (var i = 0; i < projects[currentProject - 1].length; i++) {
            tsk[0].push(state.total[i][5])//總評級
            tsk[1].push(state.total[i][6])//次/分/米
          }
          n = tsk[1].length

          var tmp = 0;
          //bubble sort
          for (var j = 0; j < n; j++) {
            for (var k = 0; k < n - j; k++) {
              if (tsk[0][k] < tsk[0][k + 1]) { //switch
                for (var i = 0; i < 2; i++) {
                  tmp = tsk[i][k]
                  tsk[i][k] = tsk[i][k + 1]
                  tsk[i][k + 1] = tmp
                }
              }
            }
          }

          //Combination
          var Tsk_comb = 0
          var t_1 = 0
          var t_2 = 0
          Tsk_comb = tsk[0][0] * (0.56 + 0.44 * Math.sqrt(tsk[1][0]))
          t_1 = tsk[1][0] //次/分/米1
          for (var j = 1; j < n; j++) {
            if (tsk[1][j] !== "") {
              t_2 = parseFloat(t_1) + parseFloat(tsk[1][j])
              Tsk_comb = Tsk_comb + tsk[0][j] * ((0.56 + 0.44 * Math.sqrt(t_2)) - (0.56 + 0.44 * Math.sqrt(t_1)))
              t_1 = t_2
            }
          }
          state.total[0][9] = Math.round(Tsk_comb * 100) / 100 //總風險值

          ////其他子項目的總風險值欄位為“”
          for (var j = 1; j < state.total.length; j++) {
            state.total[j][9] = ""
          }
        } else {
          state.total[0][9] = state.total[0][8]
        }

        //存
        for (var j = 0; j < state.total.length; j++) {
          state.projects[currentProject - 1][j].total = state.total[j]
        }

      } else if (currentProject && currentProjectsKIM === 'KIM-ABP') {


        //KIM-ABP" Then 'Linear
        //A/B/C姿勢負荷總和
        //A
        state.total[currentSubProject - 1][0][0]
          = subProject.two
          + subProject.three
          + subProject.four
          + subProject.five
          + subProject.six
          + subProject.seven
        //B
        state.total[currentSubProject - 1][0][1]
          = subProject.eight
          + subProject.nine
          + subProject.ten
          + subProject.eleven
        //C
        state.total[currentSubProject - 1][0][2]
          = subProject.twelve
          + subProject.thirteen
          + subProject.fourteen



        //A/B/C不利工作狀況
        //A
        state.total[currentSubProject - 1][1][0]
          = subProject.fifteen + subProject.eighteen + subProject['twenty-one'] + subProject['twenty-four']
        //B
        state.total[currentSubProject - 1][1][1]
          = subProject.sixteen + subProject.nineteen + subProject['twenty-two'] + subProject['twenty-five']
        //C
        state.total[currentSubProject - 1][1][2]
          = subProject.seventeen + subProject.twenty + subProject['twenty-three'] + subProject['twenty-six']






        //A/B/C其他工作條件
        //A
        state.total[currentSubProject - 1][2][0]
          = subProject['twenty-seven'] + subProject['twenty-eight'] + subProject['twenty-nine'] + subProject['thirty'] + subProject['thirty-one']
        //B
        state.total[currentSubProject - 1][2][1]
          = subProject['thirty-two'] + subProject['thirty-three'] + subProject['thirty-four'] + subProject['thirty-five'] + subProject['thirty-six']
        //C
        state.total[currentSubProject - 1][2][2]
          = subProject['thirty-seven'] + subProject['thirty-eight'] + subProject['thirty-nine'] + subProject.forty + subProject['forty-one']



        //總評級
        let sumA = state.total[currentSubProject - 1][0][0] + state.total[currentSubProject - 1][1][0] + state.total[currentSubProject - 1][2][0]
        let sumB = state.total[currentSubProject - 1][0][1] + state.total[currentSubProject - 1][1][1] + state.total[currentSubProject - 1][2][1]
        let sumC = state.total[currentSubProject - 1][0][2] + state.total[currentSubProject - 1][1][2] + state.total[currentSubProject - 1][2][2]

        state.total[currentSubProject - 1][3] = Math.max(sumA, sumB, sumC)


        if (projects[currentProject - 1].length >= 2) {

          var set = 1
          var maxNumber = 0

          for (let i = 0; i < projects[currentProject - 1].length; i++) {
            for (let j = 0; j < 3; j++) {
              const number = state.total[i][0][j] + state.total[i][1][j] + state.total[i][2][j]
              if (number > maxNumber) {
                maxNumber = number
                set = j //0:A 1:B 2:C
              }
            }

            
          }
          for (let i = 0; i < projects[currentProject - 1].length; i++) {
            state.total[i][3] = state.total[i][0][set] + state.total[i][1][set] + state.total[i][2][set]
            
          }

        
        }





        //次/分/米
        state.total[currentSubProject - 1][4] = subProject.one


        //時間權重
        state.total[currentSubProject - 1][5] = subProject.one

      
        for (let i = 0; i < projects[currentProject - 1].length; i++) {
           //子項風險
        state.total[i][6] = state.total[i][3] * state.total[i][5]

    }



        state.total[0][7] = 0
        //總風險值
        var n = 0
        if (projects[currentProject - 1].length > 1 && state.total[1][6] !== 0) {
          for (var i = 0; i < projects[currentProject - 1].length; i++) {
            state.total[0][7] += state.total[i][6]
          }
          ////其他子項目的總風險值欄位為“”
          for (var j = 1; j < state.total.length; j++) {
            state.total[j][7] = ""
          }
        } else {
          state.total[0][7] = state.total[0][6]
        }

        //存
        for (var j = 0; j < state.total.length; j++) {
          state.projects[currentProject - 1][j].total = state.total[j]
        }

      }




    },
    setPotentialCurrentProjectName: (state, action) => {
      state.potentialCurrentProjectName = action.payload;
    },
    setPotentialCurrentProjectKIM: (state, action) => {
      state.potentialCurrentProjectsKIM = action.payload;
    },
    setCurrentProject: (state, action) => {

      state.projects[state.currentProject - 1][state.currentSubProject - 1].okPosition = state.okPosition
      state.projects[state.currentProject - 1][state.currentSubProject - 1].total = state.total[state.currentSubProject - 1]

      const index = state.projectsName.findIndex(object => {
        return object === action.payload;
      });
      state.currentProject = index + 1;
      state.currentSubProject = 1;
      state.currentProjectsKIM = state.projectsKIM[index];

      if (state.projects[state.currentProject - 1][state.currentSubProject - 1].okPosition.length = 2) {
        state.okPosition = state.projects[state.currentProject - 1][state.currentSubProject - 1].okPosition
      }

      state.total = []
      for (var j = 0; j < state.projects[state.currentProject - 1].length; j++) {
        state.total.push(state.projects[state.currentProject - 1][j].total)
      }


    },
    setCurrentSubProject: (state, action) => {
      state.projects[state.currentProject - 1][state.currentSubProject - 1].okPosition = state.okPosition

      state.currentSubProject = action.payload
      if (state.projects[state.currentProject - 1][state.currentSubProject - 1].okPosition.length = 2) {
        state.okPosition = state.projects[state.currentProject - 1][state.currentSubProject - 1].okPosition
      }

    },
    setProjectWinClose: (state, action) => {
      state.addProjectWinOpen = false
    },
    setDecideWinClose: (state, action) => {
      state.decideKIMToolWinOpen = false
    },
    setDecideWin2Close: (state, action) => {
      state.decideKIMToolWin2Open = action.payload
    },
    addProject: (state, action) => {
      state.addProjectWinOpen = true
      state.countAccumProject += 1
      state.potentialCurrentProjectName = "Project" + (state.countAccumProject)
      // projectsName.push

    },
    addDecideWin2: (state, action) => {
      state.decideKIMToolWin2Open = true

    },
    addDecideWin: (state, action) => {
      state.decideKIMToolWinOpen = true

    },
    sendAddProject: (state, action) => {

      var SEX = "M"
      if (state.currentProject != '') {
        const projects = state.projects
        const currentProject = state.currentProject
        const currentSubProject = state.currentSubProject
        const subProject = projects[currentProject - 1][currentSubProject - 1];

        if (subProject) {
          SEX = subProject.sex
        }

      }

      axios({
        method: 'post',
        url: 'https://coapre.com/manasystem/API/API_Browse.php?PJname='+action.payload.KIM,
        //API要求的資料
        data: {
            name: 'alysa@gmail.com'
          
        }
    })
    .then( (response) => console.log(response))


      state.projectsName.push(action.payload.name)
      state.projectsKIM.push(action.payload.KIM)
      state.currentProjectsKIM = action.payload.KIM
      state.currentProject = Object.keys(state.projectsName).length
      state.currentSubProject = 1
      state.projects.push([])

      if (action.payload.KIM === 'KIM-LHC') {
        state.okPosition = [[], []]
        state.total = []
        state.total.push([0, 0, 0, 0, 0, "", 0, 0, 0, 0, 0])
        state.projects[state.currentProject - 1].push(
          {
            'sex': SEX,
            'one': 0,
            'two': [0, 0],
            'three': 0,
            'four': 0,
            'five': 0,
            'six': 0,
            'seven': 0,
            'eight': 0,
            'nine': 0,
            'ten': 0,
            'eleven': 0,
            'twelve': 0,
            'thirteen': 0,
            'fourteen': 0,
            'fifteen': 0,
            'sixteen': 0,
            'seventeen': 0,
            'eighteen': 0,//kg, 荷重內插用

            total: [0, 0, 0, 0, 0, "", 0, 0, 0, 0, 0],
            okPosition: [[], []]
          })
      } else if (action.payload.KIM === 'KIM-PP') {
        state.okPosition = [[], []]
        state.total = []
        state.total.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        state.projects[state.currentProject - 1].push(
          {
            'sex': SEX,
            'one': 0,
            'two': 0,
            'three': 0,
            'four': 0,
            'five': 0,
            'six': 0,
            'seven': 0,
            'eight': 0,
            'nine': 0,
            'ten': 0,
            'eleven': 0,
            'twelve': 0,
            'thirteen': 0,
            'fourteen': 0,//kg, 荷重內插用
            total: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            okPosition: [[], []]
          })
      } else if (action.payload.KIM === 'KIM-MHO') {
        state.okPosition = [[], []]
        state.total = []
        state.total.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        state.projects[state.currentProject - 1].push(
          {
            'sex': SEX,
            'one': 0,
            'two': 0,
            'three': 0,
            'four': 0,
            'five': 0,
            'six': 0,
            'seven': 0,//施力大小, 荷重內插用
            'eight': 0,//施力大小, 荷重內插用
            'nine': 0,//施力大小, 荷重內插用
            'ten': 0,//施力大小, 荷重內插用
            'eleven': 0,//施力大小, 荷重內插用
            'twelve': 0,
            'thirteen': 0,
            'fourteen': 0,
            'fifteen': 0,
            'sixteen': 0,
            'seventeen': 0,
            'eighteen': 0,//施力大小, 荷重內插用
            'nineteen': 0,//施力大小, 荷重內插用
            'twenty': 0,//施力大小, 荷重內插用
            'twenty-one': 0,//施力大小, 荷重內插用
            'twenty-two': 0,//施力大小, 荷重內插用
            'twenty-three': 0,//施力大小, 荷重內插用
            'twenty-four': 0,
            'twenty-five': 0,
            'twenty-six': 0,
            'twenty-seven': 0,
            'twenty-eight': 0,
            total: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            okPosition: [[], []]
          })
      } else if (action.payload.KIM === 'KIM-ABP') {
        state.okPosition = [[], []]
        state.total = []
        state.total.push([[0, 0, 0], [0, 0, 0], [0, 0, 0], 0, 0, 0, 0, 0])
        state.projects[state.currentProject - 1].push(
          {
            'sex': SEX,
            'one': 0,
            'two': 0,
            'three': 0,
            'four': 0,
            'five': 0,
            'six': 0,
            'seven': 0,
            'eight': 0,
            'nine': 0,
            'ten': 0,
            'eleven': 0,
            'twelve': 0,
            'thirteen': 0,
            'fourteen': 0,
            'fifteen': 0,
            'sixteen': 0,
            'seventeen': 0,
            'eighteen': 0,
            'nineteen': 0,
            'twenty': 0,
            'twenty-one': 0,
            'twenty-two': 0,
            'twenty-three': 0,
            'twenty-four': 0,
            'twenty-five': 0,
            'twenty-six': 0,
            'twenty-seven': 0,
            'twenty-eight': 0,
            'twenty-nine': 0,
            'thirty': 0,
            'thirty-one': 0,
            'thirty-two': 0,
            'thirty-three': 0,
            'thirty-four': 0,
            'thirty-five': 0,
            'thirty-six': 0,
            'thirty-seven': 0,
            'thirty-eight': 0,
            'thirty-nine': 0,
            'forty': 0,
            'forty-one': 0,
            'forty-two': 0,//內插
            'forty-three': 0,//內插
            'forty-four': 0,//內插
            'forty-five': 0,//內插
            'forty-six': 0,//內插
            'forty-seven': 0,//內插
            'forty-eight': 0,//內插
            'forty-nine': 0,//內插
            'fifty': 0,//內插
            'fifty-one': 0,//內插
            'fifty-two': 0,//內插
            'fifty-three': 0,//內插
            'fifty-four': 0,//內插
            total: [[0, 0, 0], [0, 0, 0], [0, 0, 0], 0, 0, 0, 0, 0],
            okPosition: [[], []]
          })
      } else if (action.payload.KIM === 'KIM-BF') {
        state.okPosition = [[], []]
        state.total = []
        state.total.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        state.projects[state.currentProject - 1].push(
          {
            'sex': SEX,
            'one': 0,
            'two': 0,
            'three': 0,
            'four': 0,
            'five': 0,
            'six': 0,
            'seven': 0,
            'eight': 0,
            'nine': 0,
            'ten': 0,
            'eleven': 0,
            'twelve': 0,//施力 內插用
            total: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            okPosition: [[], []]
          })
      } else if (action.payload.KIM === 'KIM-BM') {
        state.okPosition = [[], []]
        state.total = []
        state.total.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        state.projects[state.currentProject - 1].push(
          {
            'sex': SEX,
            'one': 0,
            'two': 0,
            'three': 0,
            'four': 0,
            'five': 0,
            'six': 0,
            'seven': 0,//重量 內插用
            total: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            okPosition: [[], []]
          })
      }


      state.addProjectWinOpen = false

      //state.okPosition = state.projects[state.currentProject-1][state.currentSubProject-1].okPosition 
    },
    ////////////////
    deleteProject: (state, action) => {

      if (state.projects.length > 1) {
        // state.projects =state.projects.filter((element, index) => index != state.currentProject-1)
        // state.projectsName =  state.projectsName.filter((element, index) => index != state.currentProject-1)
        // state.projectsKIM = state.projectsKIM.filter((element, index) => index != state.currentProject-1)
        // state.okPosition = state.okPosition.filter((element, index) => index != state.currentProject-1)
        delete state.projects[state.currentProject - 1]
        state.projects = state.projects.filter(x => x !== undefined);
        delete state.projectsName[state.currentProject - 1]
        state.projectsName = state.projectsName.filter(x => x !== undefined);
        delete state.projectsKIM[state.currentProject - 1]

        state.projectsKIM = state.projectsKIM.filter(x => x !== undefined);
        state.currentProject = 1


        state.total = []
        for (var j = 0; j < state.projects[state.currentProject - 1].length; j++) {
          state.total.push(state.projects[state.currentProject - 1][j].total)
        }

      } else {
        state.projects = []
        state.projectsName = []
        state.projectsKIM = []
        state.currentProject = ''
        state.currentProjectsKIM = ''
        state.total = []
      }


    },
    addSubTask: (state, action) => {


      var SEX = "M"
      if (state.currentProject != '') {
        const projects = state.projects
        const currentProject = state.currentProject
        const currentSubProject = state.currentSubProject
        const subProject = projects[currentProject - 1][currentSubProject - 1];

        if (subProject) {
          SEX = subProject.sex
        }

      }



      state.currentSubProject = state.currentSubProject + 1
      state.okPosition = [[], []]

      if (state.currentProjectsKIM === 'KIM-LHC') {
        state.total.push([0, 0, 0, 0, 0, "", 0, 0, 0, 0, 0])
        state.projects[state.currentProject - 1].push(
          {
            'sex': SEX,
            'one': 0,
            'two': [0, 0],
            'three': 0,
            'four': 0,
            'five': 0,
            'six': 0,
            'seven': 0,
            'eight': 0,
            'nine': 0,
            'ten': 0,
            'eleven': 0,
            'twelve': 0,
            'thirteen': 0,
            'fourteen': 0,
            'fifteen': 0,
            'sixteen': 0,
            'seventeen': 0,
            'eighteen': 0,//kg, 荷重內插用
            total: [0, 0, 0, 0, 0, "", 0, 0, 0, 0, 0],
            okPosition: [[], []]
          })
      } else if (state.currentProjectsKIM === 'KIM-PP') {
        state.total.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        state.projects[state.currentProject - 1].push(
          {
            'sex': SEX,
            'one': 0,
            'two': 0,
            'three': 0,
            'four': 0,
            'five': 0,
            'six': 0,
            'seven': 0,
            'eight': 0,
            'nine': 0,
            'ten': 0,
            'eleven': 0,
            'twelve': 0,
            'thirteen': 0,
            'fourteen': 0,//kg, 荷重內插用
            total: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            okPosition: [[], []]
          })
      } else if (state.currentProjectsKIM === 'KIM-MHO') {
        state.total.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

          state.projects[state.currentProject - 1].push(
            {
              'sex': SEX,
              'one': 0,
              'two': 0,
              'three': 0,
              'four': 0,
              'five': 0,
              'six': 0,
              'seven': 0,//施力大小, 荷重內插用
              'eight': 0,//施力大小, 荷重內插用
              'nine': 0,//施力大小, 荷重內插用
              'ten': 0,//施力大小, 荷重內插用
              'eleven': 0,//施力大小, 荷重內插用
              'twelve': 0,
              'thirteen': 0,
              'fourteen': 0,
              'fifteen': 0,
              'sixteen': 0,
              'seventeen': 0,
              'eighteen': 0,//施力大小, 荷重內插用
              'nineteen': 0,//施力大小, 荷重內插用
              'twenty': 0,//施力大小, 荷重內插用
              'twenty-one': 0,//施力大小, 荷重內插用
              'twenty-two': 0,//施力大小, 荷重內插用
              'twenty-three': 0,//施力大小, 荷重內插用
              'twenty-four': 0,
              'twenty-five': 0,
              'twenty-six': 0,
              'twenty-seven': 0,
              'twenty-eight': 0,
              total: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              okPosition: [[], []]
            })


          
      } else if (state.currentProjectsKIM === 'KIM-ABP') {
        state.total.push([[0, 0, 0], [0, 0, 0], [0, 0, 0], 0, 0, 0, 0, 0])
        state.projects[state.currentProject - 1].push(
          {
            'sex': SEX,
            'one': 0,
            'two': 0,
            'three': 0,
            'four': 0,
            'five': 0,
            'six': 0,
            'seven': 0,
            'eight': 0,
            'nine': 0,
            'ten': 0,
            'eleven': 0,
            'twelve': 0,
            'thirteen': 0,
            'fourteen': 0,
            'fifteen': 0,
            'sixteen': 0,
            'seventeen': 0,
            'eighteen': 0,
            'nineteen': 0,
            'twenty': 0,
            'twenty-one': 0,
            'twenty-two': 0,
            'twenty-three': 0,
            'twenty-four': 0,
            'twenty-five': 0,
            'twenty-six': 0,
            'twenty-seven': 0,
            'twenty-eight': 0,
            'twenty-nine': 0,
            'thirty': 0,
            'thirty-one': 0,
            'thirty-two': 0,
            'thirty-three': 0,
            'thirty-four': 0,
            'thirty-five': 0,
            'thirty-six': 0,
            'thirty-seven': 0,
            'thirty-eight': 0,
            'thirty-nine': 0,
            'forty': 0,
            'forty-one': 0,
            'forty-two': 0,//內插
            'forty-three': 0,//內插
            'forty-four': 0,//內插
            'forty-five': 0,//內插
            'forty-six': 0,//內插
            'forty-seven': 0,//內插
            'forty-eight': 0,//內插
            'forty-nine': 0,//內插
            'fifty': 0,//內插
            'fifty-one': 0,//內插
            'fifty-two': 0,//內插
            'fifty-three': 0,//內插
            'fifty-four': 0,//內插
            total: [[0, 0, 0], [0, 0, 0], [0, 0, 0], 0, 0, 0, 0, 0],
            okPosition: [[], []]
          })
      } else if (state.currentProjectsKIM === 'KIM-BM') {
        state.total.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        state.projects[state.currentProject - 1].push(
          {
            'sex': SEX,
            'one': 0,
            'two': 0,
            'three': 0,
            'four': 0,
            'five': 0,
            'six': 0,
            'seven': 0, //重量 內插用
            total: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            okPosition: [[], []]
          })
      } else if (state.currentProjectsKIM === 'KIM-BF') {
        state.total.push([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
        state.projects[state.currentProject - 1].push(
          {
            'sex': SEX,
            'one': 0,
            'two': 0,
            'three': 0,
            'four': 0,
            'five': 0,
            'six': 0,
            'seven': 0,
            'eight': 0,
            'nine': 0,
            'ten': 0,
            'eleven': 0,
            'twelve': 0,//施力
            total: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            okPosition: [[], []]
          })
      }

    },
    addLastSubTask: (state, action) => {

      let length = state.total.length
      state.total.push(state.projects[state.currentProject - 1][length - 1].total)
      state.projects[state.currentProject - 1].push(state.projects[state.currentProject - 1][length - 1])

      state.currentSubProject = length + 1
      state.okPosition = state.projects[state.currentProject - 1][length - 1].okPosition
    },
    pasteCopiedSubTask: (state, action) => {

    },
    deleteAllSubTask: (state, action) => {
      // state.projects[state.currentProject-1] = []
      // state.currentSubProject =1
      // state.projects[state.currentProject - 1] = state.projects[state.currentProject - 1][0]
      // state.total = state.total.filter((element, index) => index === 0)
    },
    copiedSubTask: (state, action) => {

    },
    deleteSubTask: (state, action) => {
      const index = action.payload
      if (index >= 0) {
        delete state.projects[state.currentProject - 1][index]
        state.projects[state.currentProject - 1] = state.projects[state.currentProject - 1].filter(x => x !== undefined);
        delete state.total[index]
        state.total = state.total.filter(x => x !== undefined);

        state.currentSubProject = 1
        state.okPosition = state.projects[state.currentProject - 1][state.currentSubProject - 1].okPosition
      }

    },
    setValue: (state, action) => {
      const project = state.currentProject - 1
      const block = action.payload.block
      const subProject = state.currentSubProject - 1
      const page = action.payload.page - 1
      const bblock = action.payload.bblock - 1



      if (JSON.stringify(state.okPosition[page][bblock]) !== JSON.stringify(action.payload.position)
        || (action.payload.position['height'] == '0.95%' && action.payload.position['width'] == '7%')
        || (action.payload.position['height'] == '0.8%' && action.payload.position['width'] == '3%')) {
        state.projects[project][subProject][block] = action.payload.value
      } else {
        state.projects[project][subProject][block] = 0
      }


      if (state.currentProjectsKIM === 'KIM-LHC') {

        //額外評級點數
        //1
        if (block === 'five') {
          //'five'
          state.projects[project][subProject]['six'] = 0
          state.projects[project][subProject]['seven'] = 0
          state.projects[project][subProject]['eight'] = 0
          state.projects[project][subProject]['nine'] = 0
        }
        // console.log(block)
        // if(block==='six'||block==='seven'||block==='eight'||block==='nine'){
        //   state.projects[project][subProject]['five'] = 0
        // }
        //2
        if (block === 'sixteen') {
          //'five'
          state.projects[project][subProject]['ten'] = 0
          state.projects[project][subProject]['eleven'] = 0
          state.projects[project][subProject]['twelve'] = 0
          state.projects[project][subProject]['thirteen'] = 0
          state.projects[project][subProject]['fourteen'] = 0
          state.projects[project][subProject]['fifteen'] = 0
        }

      }
      if (state.currentProjectsKIM === 'KIM-BF') {

        //額外評級點數
        //1
        if (block === 'ten') {
          //'five'
          state.projects[project][subProject]['five'] = 0
          state.projects[project][subProject]['six'] = 0
          state.projects[project][subProject]['seven'] = 0
          state.projects[project][subProject]['eight'] = 0
          state.projects[project][subProject]['nine'] = 0
        }

      }



      // state.userLngLat = state.userLngLat.filter((a) => a.name !== action.payload)

      // const index = state.userLngLat.findIndex(object => {
      //   return object.name ==== action.payload.selectedUser;
      // });
      // state.userLngLat[index].coordinates = [action.payload.longitude, action.payload.latitude]
      // state.userLngLat[index].zoom = action.payload.zoom
    },
    setValue_Normal: (state, action) => {
      const project = state.currentProject - 1
      const block = action.payload.block
      const subProject = state.currentSubProject - 1

      state.projects[project][subProject][block] = action.payload.value

    },
    addOkPostion: (state, action) => {
      const page = action.payload.page - 1
      const bblock = action.payload.bblock - 1

      if (state.currentProjectsKIM === 'KIM-LHC') {

        //額外評級點數
        //1
        if (bblock === 4) {
          //bblock='five'
          state.okPosition[page][5] = {}
          state.okPosition[page][6] = {}
          state.okPosition[page][7] = {}
          state.okPosition[page][8] = {}
        }
        if (bblock === 5 || bblock === 6 || bblock === 7 || bblock === 8) {
          state.okPosition[page][4] = {}
        }
        //2
        if (bblock === 15) {
          //bblock='sixteen'
          state.okPosition[page][9] = {}
          state.okPosition[page][10] = {}
          state.okPosition[page][11] = {}
          state.okPosition[page][12] = {}
          state.okPosition[page][13] = {}
          state.okPosition[page][14] = {}
        }
        if (bblock === 9
          || bblock === 10
          || bblock === 11
          || bblock === 12
          || bblock === 13
          || bblock === 14) {
          state.okPosition[page][15] = {}
        }

      }
      if (state.currentProjectsKIM === 'KIM-BF') {

        //額外評級點數
        //1
        if (bblock === 9) {
          //block='five'
          state.okPosition[page][4] = {}
          state.okPosition[page][5] = {}
          state.okPosition[page][6] = {}
          state.okPosition[page][7] = {}
          state.okPosition[page][8] = {}
        }
        if (bblock === 4 || bblock === 5 || bblock === 6 || bblock === 7 || bblock === 8) {
          state.okPosition[page][9] = {}
        }


      }

      const project = state.currentProject - 1
      const subProject = state.currentSubProject - 1
      const block = action.payload.block
      const value = action.payload.value

      if (JSON.stringify(state.okPosition[page][bblock]) !== JSON.stringify(action.payload.position)
        || (action.payload.position['height'] == '0.95%' && action.payload.position['width'] == '7%')
        || (action.payload.position['height'] == '0.8%' && action.payload.position['width'] == '3%')) {
        state.okPosition[page][bblock] = action.payload.position
      } else {
        state.okPosition[page][bblock] = {}
      }
      state.projects[project][subProject].okPosition = state.okPosition

    },
    changeOkPosition: (state, action) => {
      // console.log(changeOkPosition)
      state.okPosition = [[], []]
      //state.projects[state.currentProject-1][state.currentSubProject-1].okPosition 
    }




  }
})

// Action creators are generated for each case reducer function
export const {
  setCurrentProjectsKIMBM,
  setTotalCalcu,
  deleteSubTask,
  setCurrentSubProject,
  changeOkPosition,
  deleteProject,
  deleteAllSubTask,
  addSubTask,
  sendAddProject,
  setPotentialCurrentProjectName,
  setPotentialCurrentProjectKIM,
  setProjectWinClose,
  addProject,
  setCurrentProject,
  setValue,
  setValue_Normal,
  addOkPostion,
  setSex,
  setScrollHeight,
  addDecideWin,
  addDecideWin2,
  setDecideWinClose,
  setDecideWin2Close,
  setKimpp2people,
  addLastSubTask
} = KIM_info.actions

// export const selectCount = (state) => state.counter.value
export default KIM_info.reducer


