//Create a store 
import {configureStore} from "@reduxjs/toolkit"
import rootReducer from "./rootReducer"
import { setupListeners } from '@reduxjs/toolkit/query'

//configure the store with App
const store = configureStore({
    reducer: rootReducer,
});

setupListeners(store.dispatch)
export default store;

