import { createSlice } from '@reduxjs/toolkit'

export const buttonSlice = createSlice({
  name: 'button',
  initialState: {
    selectedFunctionIndex:1,
    selectedCityModeIndex:2,
    selectedDisplayModeIndex:0,
    selectedLocationIndex:0,
    
    currentPage:"Taiwan",
    //Main Button Change Event
    mainActivte:false,
    selectedMainText:'Taiwan',
    selectedMainText_show:'Taiwan',

    //Display Mode Button Change Event
    displayActivte:false,
    selectedDisplayModeText:'Mapbox Satellite',
    selectedDisplayModeText_show:'Mapbox Satellites',

    //Function Button Change Event
    functionActivte:false,
    selectedFunctionText:'Tourist',
    selectedFunctionText_show:'Tourist',

    //City  Button Change Event
    cityActivte:false,
    selectedCityText:'新北市',
    selectedCityText_show:'新北市',
  },
  reducers: {
    selectFunctionButton: (state, action) => {
      state.selectedFunctionIndex = action.payload
      // console.log(document.getElementById('VVVVV').offsetTop)
      // document.getElementById("Function").scrollTop = 10
    },
    selectedCityModeButton: (state, action) => {
      state.selectedCityModeIndex = action.payload
    },
    selectedDisplayModeButton: (state, action) => {
      state.selectedDisplayModeIndex = action.payload
    },
    selectedLocationButton: (state, action) => {
      state.selectedLocationIndex = action.payload
    },
    clickAddFunction: (state, action) => {
      alert('clickAddFunction')
    },
    clickAddMode: (state, action) => {
      alert('clickAddMode')
    },
    clickAddLocation: (state, action) => {
      alert('clickAddLocation')
    },



    ///
    changeCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    //Main Button Change Event
    selectedMainButton_Text: (state, action) => {
      state.selectedMainText = action.payload
    },
    selectedMainButton_Text_Show: (state, action) => {
      state.selectedMainText_show = action.payload
    },
    changeMainActivte:(state, action) => {
      state.mainActivte = action.payload
    },

    //Display Mode Button Change Event
    selectedDisplayModeButton_Text: (state, action) => {
      state.selectedDisplayModeText = action.payload
    },
    selectedDisplayModeButton_Text_Show: (state, action) => {
      state.selectedDisplayModeText_show = action.payload
    },
    changeDisplayActivte:(state, action) => {
      state.displayActivte = action.payload
    },


    //Function Button Change Event
    selectedFunctionButton_Text: (state, action) => {
      state.selectedFunctionText = action.payload
    },
    selectedFunctionButton_Text_Show: (state, action) => {
      state.selectedFunctionText_show = action.payload
    },
    changeFunctionActivte:(state, action) => {
      state.functionActivte = action.payload
    },

    //City  Button Change Event
    selectedCityButton_Text: (state, action) => {
      state.selectedCityText = action.payload
    },
    selectedCityButton_Text_Show: (state, action) => {
      state.selectedCityText_show = action.payload
    },
    changeCityActivte:(state, action) => {
      state.cityActivte = action.payload
    }

  }
})


// Action creators are generated for each case reducer function
export const { 
  selectFunctionButton,
  selectedDisplayModeButton,
  selectedCityModeButton,
  selectedLocationButton,
  clickAddFunction,
  clickAddMode,
  clickAddLocation,
  ///
  changeCurrentPage,
  //Main Button Change Event
  selectedMainButton_Text,
  selectedMainButton_Text_Show,
  changeMainActivte,
  //Display Mode Button Change Event
  selectedDisplayModeButton_Text,
  selectedDisplayModeButton_Text_Show,
  changeDisplayActivte,
  //Function Button Change Event
  selectedFunctionButton_Text,
  selectedFunctionButton_Text_Show,
  changeFunctionActivte,
  //City  Button Change Event
  selectedCityButton_Text,
  selectedCityButton_Text_Show,
  changeCityActivte
  
} = buttonSlice.actions

// export const selectCount = (state) => state.counter.value
export default buttonSlice.reducer