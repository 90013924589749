
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate, } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DropdownButton, Dropdown } from 'react-bootstrap';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from '@mui/material/Tooltip';

import Key_BF from "./BF.jpg";
import Key_MHO from "./MHO.jpg";
import Key_LHC from "./LHC.jpg";
import Key_PP from "./PP.jpg";
import Key_ABP from "./ABP.jpg";
import Key_BM from "./BM.jpg";

import {
  deleteSubTask,
  setCurrentSubProject,
  addLastSubTask,
  deleteProject,
  deleteAllSubTask,
  addSubTask,
  setProjectWinClose,
  addProject,
  addDecideWin,
  addDecideWin2,
  setDecideWinClose,
  setDecideWin2Close,
  sendAddProject,
  setCurrentProject,
  setPotentialCurrentProjectName,
  setPotentialCurrentProjectKIM,
  setTotalCalcu
} from "../../redux/features/KIM_info/KIMSlice";
import { ElectricalServicesRounded } from "@mui/icons-material";





export const SideBar = ({ cssSidebar, styleSidebar }) => {
  const dispatch = useDispatch()

  const { KIM_Tools, projects, projectsName, projectsKIM,
    currentProject,
    currentProjectsKIM,
    currentSubProject,
    addProjectWinOpen,
    decideKIMToolWinOpen,
    decideKIMToolWin2Open,
    potentialCurrentProjectName, potentialCurrentProjectsKIM, total, scrollHeight
  } = useSelector(state => state.kim)



  const [value, setValue] = useState('mho');

  const style1 = {
    position: 'fixed',
    height: scrollHeight,
    width: '300px',

  };
  const style2 = {
    position: 'fixed',
    width: '300px',
    height: scrollHeight,
    color: '#42abed',
    fontWeight: '800'
  };


  if (styleSidebar.width < 450 && styleSidebar.width > 158) {
    return (

      <div className={cssSidebar} style={styleSidebar}>
        <Box sx={{ display: 'flex' }}>
          {/* <MenuIcon sx={{color:'white'}}/> */}
          <div style={style2}>&nbsp;&nbsp;KIM Tool '19</div>
        </Box>
      </div>
    );
  } else if (styleSidebar.width < 158) {
    return (
      <div></div>
    );
  }


  const OkDecison1 = () => {

    dispatch(setDecideWinClose())
    dispatch(addDecideWin2())
  }



  const OkDecison2 = () => {
    dispatch(setDecideWin2Close())
  }


  const OkDecison3 = () => {
    dispatch(setDecideWin2Close())
    dispatch(setPotentialCurrentProjectKIM(`KIM-${value.toUpperCase()}`))
  }





  return (

    <div className={cssSidebar} style={styleSidebar}>
      <div style={style1}>
        <Dialog onClose={() => { return dispatch(setProjectWinClose(true)) }} open={addProjectWinOpen}>
          <DialogTitle sx={{ width: "400px", fontWeight: 'bold', display: 'flex', justifyContent: 'center', mb: '20px', fontSize: '25px' }}>Add Project</DialogTitle>
          <Block justifyContent='flex-start' flexDirection='row' pb='30px'>
            <Box sx={{ color: 'black', alignItems: 'cetner', margin: 'auto 10px auto 20px' }}>ProjectName: </Box>
            <TextField id="outlined-basic" defaultValue={potentialCurrentProjectName} label="請輸入名稱" variant="outlined" onChange={(e) => { return dispatch(setPotentialCurrentProjectName(e.target.value)) }} />
          </Block>
          <Block justifyContent='flex-start' flexDirection='row' pb='20px'>
            <Box sx={{ color: 'black', alignItems: 'cetner', margin: 'auto 40px auto 20px' }}>KIM-Tool: </Box>

            <Box sx={{ width: '200px', }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">請選擇KIM-Tool</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={potentialCurrentProjectsKIM}
                  label="請選擇KIM-Tool"
                  onChange={(e) => { return dispatch(setPotentialCurrentProjectKIM(e.target.value)) }}
                >
                  {KIM_Tools && KIM_Tools.length > 0 ? (
                    KIM_Tools.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>{item}</MenuItem>
                      )
                    })) : (null)}
                </Select>
              </FormControl>
            </Box>

          </Block>
          <Block justifyContent='flex-end' pb='30px' flexDirection='row'>
            <Box sx={{
              width: '50px',
              color: 'black',
              height: '50px',
              fontWeight: 'bold',
              border: '2px black solid',
              borderRadius: '50px',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                backgroundColor: 'black',
                color: 'white',
              }
            }} onClick={(e) => { return dispatch(sendAddProject({ name: potentialCurrentProjectName, KIM: potentialCurrentProjectsKIM })) }}>
              OK
            </Box>
          </Block>
        </Dialog>

        <Dialog
          width='400px'
          maxWidth="false"
          open={decideKIMToolWinOpen}
          onClose={() => { return dispatch(setDecideWinClose()) }}
        >
          <DialogTitle sx={{ fontWeight: 800 }}>1 . 初步評估</DialogTitle>
          <DialogContent dividers>
            <Typography sx={{ bgcolor: 'white', width: '100%', fontWeight: 900 }}>請在以下六個選項中選出一個最符合您作業內容的敘述:</Typography>

            <div id="col" style={{ display: 'flex', flexDirection: 'columns' }}>
              <div id="row" sx={{ display: 'flex', flexDirection: 'columns', padding: '100px' }}>

                <div style={{ width: '100%', height: '33%', display: 'flex', flexDirection: 'column' }}>

                  <img
                    width="300px"
                    src={`${Key_MHO}`}
                    // alt={item.title}
                    loading="lazy"
                  />

                  <div>
                    <Radio
                      checked={value === 'mho'}
                      onChange={(e) => setValue('mho')}
                      value={value}
                      name="radio-buttons"
                    />
                    1. 在每一輪班中存在一小時以上的上肢重複性工作
                  </div>
                </div>
                <div style={{ width: '100%', height: '33%', display: 'flex', flexDirection: 'column' }}>

                  <img
                    width="300px"
                    src={`${Key_PP}`}
                    // alt={item.title}
                    loading="lazy"
                  />

                  <div>
                    <Radio
                      checked={value === 'pp'}
                      onChange={(e) => setValue('pp')}
                      value={value}
                      name="radio-buttons"
                    />
                    3. 以全身施力推/拉重物，並使用帶輪/滑軌工具
                  </div>
                </div>
                <div style={{ width: '100%', height: '33%', display: 'flex', flexDirection: 'column' }}>

                  <img
                    width="300px"
                    src={`${Key_LHC}`}
                    // alt={item.title}
                    loading="lazy"
                  />

                  <div>
                    <Radio
                      checked={value === 'lhc'}
                      onChange={(e) => setValue('lhc')}
                      value={value}
                      name="radio-buttons"
                    />
                    5. 人手抬舉/放下或攜行3公斤以上的物品，且單趟距離不超過10公尺
                  </div>
                </div>
              </div>

              <div id="row" sx={{ display: 'flex', flexDirection: 'columns', padding: '100px' }}>

                <div style={{ width: '100%', height: '33%', display: 'flex', flexDirection: 'column' }}>

                  <img
                    width="300px"
                    src={`${Key_ABP}`}
                    // alt={item.title}
                    loading="lazy"
                  />

                  <div>
                    <Radio
                      checked={value === 'abp'}
                      onChange={(e) => setValue('abp')}
                      value={value}
                      name="radio-buttons"
                    />
                    2. 大部分工作時間中，頭/頸、軀幹/上身維持4秒以上的不良姿勢
                  </div>
                </div>
                <div style={{ width: '100%', height: '33%', display: 'flex', flexDirection: 'column' }}>

                  <img
                    width="300px"
                    src={`${Key_BM}`}
                    // alt={item.title}
                    loading="lazy"
                  />

                  <div>
                    <Radio
                      checked={value === 'bm'}
                      onChange={(e) => setValue('bm')}
                      value={value}
                      name="radio-buttons"
                    />
                    4. 人力攜(運)行3公斤以上的物品，且單趟距離超過10公尺
                  </div>
                </div>
                <div style={{ width: '100%', height: '33%', display: 'flex', flexDirection: 'column' }}>

                  <img
                    width="300px"
                    src={`${Key_BF}`}
                    // alt={item.title}
                    loading="lazy"
                  />

                  <div>
                    <Radio
                      checked={value === 'bf'}
                      onChange={(e) => setValue('bf')}
                      value={value}
                      name="radio-buttons"
                    />
                    6. 工作期間採全身施力，且無抬舉、推拉、攜行之行為
                  </div>
                </div>
              </div>
            </div>

          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => dispatch(setDecideWinClose())} >
              Cancel
            </Button>
            <Button onClick={OkDecison1}>Ok</Button>
          </DialogActions>
        </Dialog>

        {/* 
                
        <Dialog
          width='400px'
          maxWidth="false"
          // TransitionProps={{ onEntering: handleEntering }}
          open={decideKIMToolWin2Open}
          onClose={() => { return dispatch(setDecideWin2Close()) }}
        // {...other}
        >
          <DialogTitle sx={{ fontWeight: 800 }}>1 . 初步評估 = 2.進一步評估</DialogTitle>
          <DialogContent dividers>
            <Typography sx={{ bgcolor: 'white', width: '100%', fontWeight:900}}>請在以下六個選項中選出一個最符合您作業內容的敘述:</Typography>

          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => {return dispatch(setDecideWin2Close()), dispatch(addDecideWin())}} >
              Back
            </Button>
            <Button onClick={OkDecison2} >Ok</Button>
          </DialogActions>
        </Dialog> */}


        <Dialog
          width='400px'
          maxWidth="false"
          // TransitionProps={{ onEntering: handleEntering }}
          open={decideKIMToolWin2Open}
          onClose={() => { return dispatch(setDecideWin2Close()) }}
        // {...other}
        >
          <DialogTitle sx={{ fontWeight: 800 }}>評估結果</DialogTitle>
          <DialogContent dividers>
            <Typography sx={{ bgcolor: 'white', width: '100%', fontWeight: 900 }}>KIM-{value.toUpperCase()}</Typography>

          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => { return dispatch(setDecideWin2Close()), dispatch(addDecideWin()) }} >
              Back
            </Button>
            <Button onClick={OkDecison3} >Ok</Button>
          </DialogActions>
        </Dialog>

        <Block justifyContent='center' flexDirection='column'>
          <Block pb='20px' justifyContent='space-between' flexDirection='row'>
            <DropdownButton
              id="dropdown-no-caret"
              variant="outline-danger"
              onSelect={(e) => { return dispatch(setCurrentProject(e)) }}
              title={currentProject ? projectsName[currentProject - 1] : '請新增專案'}
            >

              {projectsName && projectsName.length > 0 ? (projectsName.map((item, index) => {
                return (
                  <Dropdown.Item key={index} eventKey={item}>
                    {item}
                  </Dropdown.Item>
                );
              })) : (null)}
            </DropdownButton>



            <Block flexDirection='row'>

              <Tooltip title="新增專案">
                <IconButton>
                  <AddIcon onClick={() => { return (dispatch(addProject())) }}
                    sx={{
                      color: '#42abed', '&:hover': {
                        border: "1px #42abed solid",
                        borderRadius: '3px'
                      }
                    }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title="永久刪除此專案">
                <IconButton>
                  <DeleteForeverIcon
                    sx={{
                      color: '#42abed', '&:hover': {
                        border: "1px #42abed solid",
                        borderRadius: '3px'
                      }
                    }}
                    onClick={() => { return (dispatch(deleteProject())) }}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title="評估工具挑選指引">
                <IconButton>
                  <QuestionMarkIcon
                    sx={{
                      color: '#42abed', '&:hover': {
                        border: "1px #42abed solid",
                        borderRadius: '3px'
                      }
                    }}
                    onClick={() => { return (dispatch(addDecideWin())) }}
                  />
                </IconButton>
              </Tooltip>
            </Block>

          </Block>
          <List
            style={{
              backgroundColor: 'rgba(66, 171, 237,0.8)',
              color: 'white',
              borderRadius: '10px',

          
            }}>


            {projects && projects.length > 0 ? (projects[currentProject - 1].map((item, index) => {
              if (currentSubProject - 1 == index) {
                return (
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    backgroundColor: 'rgba(66, 171, 237,1)',
                  }}>
                    <ListItemButton
                      onClick={() => { return dispatch(setCurrentSubProject(index + 1)) }}>
                      subTask{index + 1}
                    </ListItemButton>
                    <ListItemIcon>

                      {/* <IconButton
                       
                          sx={{
                            display: 'flex',
                            mr: '-10px',
                            alignItems: "flex-end",
                          }}>

                          <ContentCopyIcon sx={{ color: 'white' }} />

                        </IconButton> */}

                      <Tooltip title="刪除作業">
                        <IconButton
                          sx={{
                            display: 'flex',
                            // mr:'-10px',
                            alignItems: "flex-end",

                          }}>

                          <DeleteIcon sx={{ color: 'white' }} onClick={() => { return dispatch(deleteSubTask(index)), dispatch(setTotalCalcu()) }} />

                        </IconButton>
                      </Tooltip>
                    </ListItemIcon>
                  </Box>
                );
              } else {
                return (
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    "&:hover": {
                      backgroundColor: 'rgba(239, 71, 111,0.2)',
                    }
                  }}>
                    <ListItemButton
                      onClick={() => { return dispatch(setCurrentSubProject(index + 1)) }}>
                      subTask{index + 1}
                    </ListItemButton>
                    <ListItemIcon>

                      {/* <IconButton
                     
                          sx={{
                            display: 'flex',
                            mr: '-10px',
                            alignItems: "flex-end",
                          }}>

                          <ContentCopyIcon sx={{ color: 'white' }} />

                        </IconButton> */}

                      <Tooltip title="刪除作業">
                        <IconButton
                          sx={{
                            display: 'flex',
                            // mr:'-10px',
                            alignItems: "flex-end",

                          }}>

                          <DeleteIcon sx={{ color: 'white' }} onClick={() => { return dispatch(deleteSubTask(index)), dispatch(setTotalCalcu()) }} />

                        </IconButton>
                      </Tooltip>
                    </ListItemIcon>
                  </Box>
                );
              }
            })) : (null)}

          </List>
          <Block justifyContent='flex-end' flexDirection='row'>
            <Tooltip title="新增空白作業">
              <IconButton
              >
                <NoteAddIcon
                  sx={{
                    color: '#42abed',
                    backgroundColor: '#f5fbff',
                    borderRadius: '3px',
                    '&:hover': {
                      border: "2px white solid",
                      borderRadius: '3px'
                    }
                  }}
                  onClick={() => { return (dispatch(addSubTask()), dispatch(setTotalCalcu())) }}
                />

              </IconButton >
            </Tooltip>
            <Tooltip title="複製上一個作業">
              <IconButton
              >
                {/* 貼上上一個子項作業內容 */}
                <FileCopyIcon
                  sx={{
                    color: '#42abed',
                    backgroundColor: '#f5fbff',
                    borderRadius: '3px',
                    '&:hover': {
                      border: "2px white solid",
                      borderRadius: '3px'
                    }
                  }}
                  onClick={() => { return (dispatch(addLastSubTask()), dispatch(setTotalCalcu())) }}
                />
              </IconButton>
            </Tooltip>

            {/* <Tooltip title="刪除所有作業">
            <IconButton
            >
              <DeleteForeverIcon
                sx={{
                  color: '#42abed',
                  backgroundColor: '#f5fbff',
                  borderRadius: '3px',
                  '&:hover': {
                    border: "2px white solid",
                    borderRadius: '3px'
                  }
                }}
                onClick={() => { return (dispatch(deleteAllSubTask())) }}
              />
            </IconButton>
            </Tooltip> */}

          </Block>
        </Block>


  
      </div>
    </div>
  );

};


const Text = styled.div`
  font-size: 14px;
  color: #7e7e7e;
  `;

const H1 = styled.div`
  width: 150px;
  display: flex;
  font-family: Helvetica Neue;
  font-weight: 500;
  color: #ffffff;
`;
const H3 = styled.div`
  color:black;
  width: 27vw;
  display: flex;
  //font-family: Helvetica Neue;
  font-weight: 500;
  height: ${(props) => props.height};
`;


const Block = styled.div`
  // border:1px solid red;
  color: #ffffff; 
  display: flex;
  flex-direction:${(props) => props.flexDirection};
  justify-content: ${(props) => props.justifyContent};
  padding-bottom:${(props) => props.pb};
  
`;
const Element1 = styled.img`
  width: 10vh;
  height: ;
  //padding:78vh 0vh;
  align-items: flex-start;
  position:absolute;
  left:3vh;
  top:89vh; 
`;

export default SideBar;