import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { selectedCityModeButton, clickAddMode, changeCityActivte, changeCurrentPage } from "../../redux/features/Button/buttonSlice";
import { setKimpp2people, setValue, setValue_Normal, addOkPostion, changeOkPosition, setTotalCalcu, setSex, setScrollHeight, setCurrentProjectsKIMBM } from "../../redux/features/KIM_info/KIMSlice";
import { Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { styled as muiStyle } from '@mui/material/styles';

import Interpolation from './interpolation';

import {
  addProject,
} from "../../redux/features/KIM_info/KIMSlice";
// import Table from 'rc-table';

import KIM_LHC_1 from "./KIM-LHC-中文版/KIM-LHC-中文版-1.jpg";
import KIM_LHC_2 from "./KIM-LHC-中文版/KIM-LHC-中文版-2.jpg";
import KIM_PP_1 from "./KIM-PP-中文版/KIM-PP-中文版-1.jpg";
import KIM_PP_2 from "./KIM-PP-中文版/KIM-PP-中文版-2.jpg";
import KIM_MHO_1 from "./KIM-MHO-中文版/KIM-MHO-中文版-1.jpg";
import KIM_MHO_2 from "./KIM-MHO-中文版/KIM-MHO-中文版-2.jpg";
import KIM_ABP_1 from "./KIM-ABP-中文版/KIM-ABP-中文版-1.jpg";
import KIM_ABP_2 from "./KIM-ABP-中文版/KIM-ABP-中文版-2.jpg";
import KIM_BM_1 from "./KIM-BM-中文版/KIM-BM-中文版-1.jpg";
import KIM_BM_2 from "./KIM-BM-中文版/KIM-BM-中文版-2.jpg";
import KIM_BF_1 from "./KIM-BF-中文版/KIM-BF-中文版-1.jpg";
import KIM_BF_2 from "./KIM-BF-中文版/KIM-BF-中文版-2.jpg";
import OK from "./ok.png";
import pointer from "./pointer.png"

import Box from '@mui/material/Box';
import SideBar from "../Sidebar";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ConstructionOutlined, QrCodeScannerOutlined } from "@mui/icons-material";


const AntSwitch = muiStyle(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

var totalRisk = 0;
var totalRiskColor;

// document.body.style.zoom = "150%";
export const Main = ({ cssMain, styleMain }) => {
  const { selectedCityModeIndex } = useSelector(state => state.button)
  const { kimpp2people, projects, okPosition, currentProject, currentSubProject, currentProjectsKIM, total, currentProjectsKIMBM } = useSelector(state => state.kim)

  const dispatch = useDispatch();

  const KIM_LHC_Rating = ['子項作業 Sub Task', '有效負荷重量', '負重處理條件', '身體姿勢+額外', '不友善的工作條件', '工作組織/時間分配', '', '總評級 Sum(li)', '次/分/米 Time', '時間權重 Time_W', '子項風險 PWi', '總風險值 PWtot']
  const KIM_PP_Rating = ['子項作業 Sub Task', '負載總重量', '車道條件', '不友善的工作條件', '不利的搬運設備特性', '身體姿勢/動作', '工作組織/時間分配', '總評級 Sum(li)', '公尺 Distance', '時間權重 Time_W', '子項風險 PWi', '總風險值 PWtot']
  const KIM_MHO_Rating = ['子項作業 Sub Task', '手指/手部區域的施力方式', '力量傳遞／抓握條件', '手／臂位置及動作', '不利工作狀況', '身體姿勢/動作', '工作組織/時間分配', '總評級 Sum(li)', '次/分/米 Time', '時間權重 Time_W', '子項風險 PWi', '總風險值 PWtot']
  const KIM_ABP_Rating = ['子項作業 Sub Task', 'A/B/C姿勢負荷總和', '不利工作狀況', '其他工作條件', '總評級 Sum(li)', '次/分/米 Time', '時間權重 Time_W', '子項風險 PWi', '總風險值 PWtot']
  const KIM_BF_Rating = ['子項作業 Sub Task', '施力', '施力對稱', '身體姿勢', '不利工作狀況', '工作組織/時間分配', '總評級 Sum(li)', '次/分/米 Time', '時間權重 Time_W', '子項風險 PWi', '總風險值 PWtot']
  let KIM_BM_Rating = []
  if (currentProjectsKIMBM == 'A') {
    KIM_BM_Rating = ['子項作業 Sub Task', 'A 沒有設備輔助的身體運動', 'A 負載中心位置', 'A 姿勢', 'A 不利工作狀況', '工作組織/時間分配', '總評級 Sum(li)', '次/分/米 Time', '時間權重 Time_W', '子項風險 PWi', '總風險值 PWtot']
  } else {
    KIM_BM_Rating = ['子項作業 Sub Task', ' B 以肌肉力量驅動的身體運動', 'B 無', 'B 無', 'B 不利工作狀況', '工作組織/時間分配', '總評級 Sum(li)', '次/分/米 Time', '時間權重 Time_W', '子項風險 PWi', '總風險值 PWtot']

  }

  if (total.length !== 0) {

    if (currentProjectsKIM === 'KIM-LHC' || currentProjectsKIM === 'KIM-PP' || currentProjectsKIM === 'KIM-MHO') {
      totalRisk = total[0][10]
    } else if (currentProjectsKIM === 'KIM-ABP') {
      totalRisk = total[0][7]
    } else if (currentProjectsKIM === 'KIM-BM') {
      totalRisk = total[0][9]
    } else if (currentProjectsKIM === 'KIM-BF') {
      totalRisk = total[0][9]
    }

    if (totalRisk < 20 && totalRisk > 0) {
      totalRiskColor = 'rgba(72,178,52,0.8)'
    } else if (totalRisk >= 20 && totalRisk < 50) {
      totalRiskColor = 'rgba(198,217,53,1)'
    } else if (totalRisk >= 50 && totalRisk < 100) {
      totalRiskColor = 'rgba(239,233,57,1)'
    } else if (totalRisk >= 100) {
      totalRiskColor = 'rgba(233,70,27,0.9)'
    } else {
      totalRiskColor = 'rgba(0,0,0,0.2)'
    }
  }




  // const scrollToTop = () =>{
  //   window.scrollTo({
  //     top: 0, 
  //     behavior: 'smooth'
  //     /* you can also use 'auto' behaviour
  //        in place of 'smooth' */
  //   });
  // };



  const scrollToDown1 = () => {
    document.getElementById('Page-1').scrollIntoView();
  };
  const scrollToDown2 = () => {
    document.getElementById('Page-2').scrollIntoView();
  };
  const scrollToDown3 = () => {
    document.getElementById('Rating').scrollIntoView();
  };



  if (document.getElementById("KIM") !== null) {
    const newScrollHeight = String(document.getElementById("KIM").offsetHeight) + 'px'
    dispatch(setScrollHeight(newScrollHeight))
  }


  dispatch(changeCurrentPage('City'))

  function changeValue(Block, Value, bBlock, Position, Page) {
    dispatch(setValue({ block: Block, bblock: bBlock, position: Position, page: Page, value: Value }))
    dispatch(addOkPostion({ block: Block, bblock: bBlock, position: Position, page: Page, value: Value }))
    dispatch(setTotalCalcu())

  }

  const transformOptions = {
    initialScale: 1,
    minScale: 0.5,
    maxScale: 2
  }

  if (currentProjectsKIM === 'KIM-LHC') {


    return (


      <div className={cssMain} style={styleMain}>

        <Union id="KIM">
          <ScollDownButton bottom={'100px'} onClick={() => scrollToDown1()} > &nbsp;P1&nbsp; </ScollDownButton>
          <ScollDownButton bottom={'50px'} onClick={() => scrollToDown2()} > &nbsp;P2&nbsp; </ScollDownButton>
          <ScollDownButton bottom={'0px'} onClick={() => scrollToDown3()} > Risk </ScollDownButton>

          <TransformWrapper doubleClick={{ disabled: true }} wheel={{ activationKeys: ["Alt"] }}>
            <TransformComponent>


              <Stack sx={{ position: 'absolute', padding: '10px', right: '50px' }} direction="row" spacing={1} alignItems="center">
                <Typography>女</Typography>
                <AntSwitch
                  checked={projects[currentProject - 1][currentSubProject - 1].sex === 'M' ? true : false}
                  onChange={(evt) => { return dispatch(setSex(evt.target.checked)), dispatch(setTotalCalcu()) }}
                  defaultChecked={projects[currentProject - 1][currentSubProject - 1].sex === 'M' ? true : false}
                  inputProps={{ 'aria-label': 'ant design' }} />
                <Typography>男</Typography>
              </Stack>

              <Block id="Page-1">
                {okPosition[0].map((item, index) => (
                  <OkIcon height={item.height} width={item.width} top={item.top} left={item.left} />
                  // <Box sx={{
                  //   height: `${item.height}`, width: `${item.width}`, top: `${item.top}`, left: `${item.left}`
                  //   , bgColor: '#121212', opacity:'0.6'
                  // }}></Box>
                ))
                }
                <>
                  {/* one */}
                  <Interpolation name={'時間內插'} title={'搬運次數'} content={'(範圍:1~6000次)'}

                    //In_function={(n) => { return 0.56 + 0.44 * Math.pow((n / 5), 0.5) }} re_function={(e) => { return (Math.pow((e - 0.56), 2)) * (5 / 0.1936) }} //原code

                    //fair
                    In_function={(n) => {
                        var cc
                        if(n == 5){
                          cc = 1
                        }else if(n == 20){
                                      cc = 1.5
                        }else if(n == 50){
                                      cc = 2
                        }else if(n == 100){
                                      cc = 2.5
                        }else if(n == 150){
                                      cc = 3
                        }else if(n == 220){
                                      cc = 3.5
                        }else if(n == 300){
                                      cc = 4
                        }else if(n == 500){
                                      cc = 5
                        }else if(n == 750){
                                      cc = 6
                        }else if(n == 1000){
                                      cc = 7
                        }else if(n == 1500){
                                      cc = 8
                        }else if(n == 2000){
                                      cc = 9
                        }else if(n == 2500){
                                      cc = 10
                        }else{
                           cc =  0.56 + 0.44 * Math.pow((n / 5), 0.5)
                        }
                        return cc
                      }}
                    re_function={(e) => { return (Math.pow((e - 0.56), 2)) * (5 / 0.1936) }}
                    //fair

                    block={'one'} bblock={1} position={{ height: '0.95%', width: '7%', top: '6.1%', left: '81.5%' }} page={1} />

                  <Cell height={'2%'} width={'4.5%'} top={'7%'} left={'29.2%'} onClick={() => changeValue('one', 1, 1, { height: '2%', width: '4.5%', top: '7%', left: '28.8%' }, 1)} />
                  <Cell height={'2%'} width={'4.5%'} top={'7%'} left={'33.6%'} onClick={() => changeValue('one', 1.5, 1, { height: '2%', width: '4.5%', top: '7%', left: '33.6%' }, 1)} />
                  <Cell height={'2%'} width={'4.5%'} top={'7%'} left={'38.1%'} onClick={() => changeValue('one', 2, 1, { height: '2%', width: '4.5%', top: '7%', left: '38.1%' }, 1)} />
                  <Cell height={'2%'} width={'4.5%'} top={'7%'} left={'42.6%'} onClick={() => changeValue('one', 2.5, 1, { height: '2%', width: '4.5%', top: '7%', left: '42.6%' }, 1)} />
                  <Cell height={'2%'} width={'4.9%'} top={'7%'} left={'47%'} onClick={() => changeValue('one', 3, 1, { height: '2%', width: '4.5%', top: '7%', left: '47%' }, 1)} />
                  <Cell height={'2%'} width={'4.9%'} top={'7%'} left={'51.98%'} onClick={() => changeValue('one', 3.5, 1, { height: '2%', width: '4.5%', top: '7%', left: '51.98%' }, 1)} />
                  <Cell height={'2%'} width={'4.9%'} top={'7%'} left={'56.81%'} onClick={() => changeValue('one', 4, 1, { height: '2%', width: '4.9%', top: '7%', left: '56.81%' }, 1)} />
                  <Cell height={'2%'} width={'4.9%'} top={'7%'} left={'61.8%'} onClick={() => changeValue('one', 5, 1, { height: '2%', width: '4.9%', top: '7%', left: '61.8%' }, 1)} />
                  <Cell height={'2%'} width={'4.9%'} top={'7%'} left={'66.8%'} onClick={() => changeValue('one', 6, 1, { height: '2%', width: '4.9%', top: '7%', left: '66.8%' }, 1)} />
                  <Cell height={'2%'} width={'4.9%'} top={'7%'} left={'71.6%'} onClick={() => changeValue('one', 7, 1, { height: '2%', width: '4.9%', top: '7%', left: '71.6%' }, 1)} />
                  <Cell height={'2%'} width={'4.9%'} top={'7%'} left={'76.5%'} onClick={() => changeValue('one', 8, 1, { height: '2%', width: '4.9%', top: '7%', left: '76.5%' }, 1)} />
                  <Cell height={'2%'} width={'4.9%'} top={'7%'} left={'81.5%'} onClick={() => changeValue('one', 9, 1, { height: '2%', width: '4.9%', top: '7%', left: '81.5%' }, 1)} />
                  <Cell height={'2%'} width={'4.9%'} top={'7%'} left={'86.4%'} onClick={() => changeValue('one', 10, 1, { height: '2%', width: '4.5%', top: '7%', left: '86.4%' }, 1)} />


                  {/* two */}
                  <Interpolation name={'荷重內插'} title={'有效負荷重量'} content={'(範圍:0.1~100kg)'}
                    In_function={(n) => {
                      let result = [0, 0]
                      if (n < 3) {
                        result[0] = 3
                        result[1] = 4.5
                      } else if (3 <= n && n <= 15) {
                        result[0] = (0.4 * n + 2)
                        result[1] = (0.6 * n + 3)
                      } else if (15 <= n && n <= 25) {
                        result[0] = (0.02 * Math.pow(n, 2) - 0.1 * n + 5)
                        result[1] = 2.6 * n - 27
                      } else if (25 < n && n <= 35) {
                        result[0] = (2 * n - 35)
                        result[1] = 75
                      } else if (35 < n && n <= 40) {
                        result[0] = 75
                        result[1] = 85
                      } else if (n > 40) {
                        result[0] = 100
                        result[1] = 100
                      }
                      dispatch(setValue_Normal({ block: 'eighteen', value: n }))
                      return result
                    }}
                    re_function={(e) => {
                      return e
                    }}
                    block={'two'} bblock={2} position={{ height: '0.95%', width: '7%', top: '9.2%', left: '81.5%' }} page={1} />

                  <Cell height={'0.8%'} width={'82.1%'} top={'10.8%'} left={'9%'} onClick={() => { return changeValue('two', [4, 6], 2, { height: '0.8%', width: '82.1%', top: '10.8%', left: '9%' }, 1), dispatch(setValue_Normal({ block: 'eighteen', value: 5 })) }} />
                  <Cell height={'0.8%'} width={'82.1%'} top={'11.5%'} left={'9%'} onClick={() => { return changeValue('two', [6, 9], 2, { height: '0.8%', width: '82.1%', top: '11.5%', left: '9%' }, 1), dispatch(setValue_Normal({ block: 'eighteen', value: 10 })) }} />
                  <Cell height={'0.8%'} width={'82.1%'} top={'12.2%'} left={'9%'} onClick={() => { return changeValue('two', [8, 12], 2, { height: '0.8%', width: '82.1%', top: '12.2%', left: '9%' }, 1), dispatch(setValue_Normal({ block: 'eighteen', value: 15 })) }} />
                  <Cell height={'0.8%'} width={'82.1%'} top={'12.9%'} left={'9%'} onClick={() => { return changeValue('two', [11, 25], 2, { height: '0.8%', width: '82.1%', top: '12.9%', left: '9%' }, 1), dispatch(setValue_Normal({ block: 'eighteen', value: 20 })) }} />
                  <Cell height={'0.8%'} width={'82.1%'} top={'13.6%'} left={'9%'} onClick={() => { return changeValue('two', [15, 75], 2, { height: '0.8%', width: '82.1%', top: '13.6%', left: '9%' }, 1), dispatch(setValue_Normal({ block: 'eighteen', value: 25 })) }} />
                  <Cell height={'0.8%'} width={'82.1%'} top={'14.3%'} left={'9%'} onClick={() => { return changeValue('two', [25, 85], 2, { height: '0.8%', width: '82.1%', top: '14.3%', left: '9%' }, 1), dispatch(setValue_Normal({ block: 'eighteen', value: 30 })) }} />
                  <Cell height={'0.8%'} width={'82.1%'} top={'15%'} left={'9%'} onClick={() => { return changeValue('two', [35, 100], 2, { height: '0.8%', width: '82.1%', top: '15%', left: '9%' }, 1), dispatch(setValue_Normal({ block: 'eighteen', value: 35 })) }} />
                  <Cell height={'0.8%'} width={'82.1%'} top={'15.7%'} left={'9%'} onClick={() => { return changeValue('two', [75, 100], 2, { height: '0.8%', width: '82.1%', top: '15.7%', left: '9%' }, 1), dispatch(setValue_Normal({ block: 'eighteen', value: 40 })) }} />
                  <Cell height={'0.8%'} width={'82.1%'} top={'16.4%'} left={'9%'} onClick={() => { return changeValue('two', [100, 100], 2, { height: '0.8%', width: '82.1%', top: '16.4%', left: '9%' }, 1), dispatch(setValue_Normal({ block: 'eighteen', value: 50 })) }} />

                  {/* three */}
                  <Interpolation name={'條件內插'} title={'負重處理條件'} content={'(範圍:0~4)'}
                    In_function={(n) => { return n }} re_function={(e) => { return e }}
                    block={'three'} bblock={3} position={{ height: '0.95%', width: '7%', top: '18.4%', left: '81.5%' }} page={1} />
                  <Cell height={'0.8%'} width={'82.1%'} top={'20.1%'} left={'9%'} onClick={() => changeValue('three', 0, 3, { height: '0.8%', width: '82.1%', top: '20.1%', left: '9%' }, 1)} />
                  <Cell height={'0.8%'} width={'82.1%'} top={'20.9%'} left={'9%'} onClick={() => changeValue('three', 2, 3, { height: '0.8%', width: '82.1%', top: '20.9%', left: '9%' }, 1)} />
                  <Cell height={'0.8%'} width={'82.1%'} top={'21.7%'} left={'9%'} onClick={() => changeValue('three', 4, 3, { height: '0.8%', width: '82.1%', top: '21.7%', left: '9%' }, 1)} />

                  {/* four */}
                  <Interpolation name={'姿勢內插'} title={'身體姿勢評級'} content={'(範圍:0~20)'}
                    In_function={(n) => { return parseFloat(n) }} re_function={(e) => { return parseFloat(e) }}
                    block={'four'} bblock={4} position={{ height: '0.95%', width: '7%', top: '25%', left: '81.5%' }} page={1} />

                  <Cell height={'3.4%'} width={'25.2%'} top={'27%'} left={'9%'} onClick={() => changeValue('four', 0, 4, { height: '3.4%', width: '25.2%', top: '27%', left: '9%' }, 1)} />
                  <Cell height={'4.7%'} width={'25.2%'} top={'30.4%'} left={'9%'} onClick={() => changeValue('four', 3, 4, { height: '4.7%', width: '25.2%', top: '30.4%', left: '9%' }, 1)} />
                  <Cell height={'3.6%'} width={'25.2%'} top={'35.1%'} left={'9%'} onClick={() => changeValue('four', 5, 4, { height: '3.6%', width: '25.2%', top: '35.1%', left: '9%' }, 1)} />
                  <Cell height={'2.6%'} width={'25.2%'} top={'38.7%'} left={'9%'} onClick={() => changeValue('four', 7, 4, { height: '2.6%', width: '25.2%', top: '38.7%', left: '9%' }, 1)} />
                  <Cell height={'2.9%'} width={'25.2%'} top={'41.3%'} left={'9%'} onClick={() => changeValue('four', 9, 4, { height: '2.9%', width: '25.2%', top: '41.3%', left: '9%' }, 1)} />

                  <Cell height={'3.4%'} width={'25.2%'} top={'27%'} left={'33.8%'} onClick={() => changeValue('four', 10, 4, { height: '3.4%', width: '25.2%', top: '27%', left: '33.8%' }, 1)} />
                  <Cell height={'4.7%'} width={'25.2%'} top={'30.4%'} left={'33.8%'} onClick={() => changeValue('four', 13, 4, { height: '4.7%', width: '25.2%', top: '30.4%', left: '33.8%' }, 1)} />
                  <Cell height={'3.6%'} width={'25.2%'} top={'35.1%'} left={'33.8%'} onClick={() => changeValue('four', 15, 4, { height: '3.6%', width: '25.2%', top: '35.1%', left: '33.8%' }, 1)} />
                  <Cell height={'2.6%'} width={'25.2%'} top={'38.7%'} left={'33.8%'} onClick={() => changeValue('four', 18, 4, { height: '2.6%', width: '25.2%', top: '38.7%', left: '33.8%' }, 1)} />
                  <Cell height={'2.9%'} width={'25.2%'} top={'41.3%'} left={'33.8%'} onClick={() => changeValue('four', 20, 4, { height: '2.9%', width: '25.2%', top: '41.3%', left: '33.8%' }, 1)} />

                  {/* five : if click five then 6789 =0 */}
                  {/* <Cell height={'1.1%'} width={'9%'} top={'25.9%'} left={'82.2%'} onClick={() => changeValue('five', 0, 5, { height: '1.1%', width: '9%', top: '25.9%', left: '82.2%' }, 1)}>+0</Cell> */}


                  {/* six */}
                  <Cell height={'1.6%'} width={'9.2%'} top={'27%'} left={'82.1%'} onClick={() => changeValue('six', 1, 6, { height: '1.6%', width: '9.2%', top: '27%', left: '82.1%' }, 1)} />
                  <Cell height={'1.9%'} width={'9.2%'} top={'28.6%'} left={'82.1%'} onClick={() => changeValue('six', 3, 6, { height: '1.9%', width: '9.2%', top: '28.6%', left: '82.1%' }, 1)} />

                  {/* seven */}
                  <Cell height={'2.3%'} width={'9.2%'} top={'30.5%'} left={'82.1%'} onClick={() => changeValue('seven', 1, 7, { height: '2.3%', width: '9.2%', top: '30.5%', left: '82.1%' }, 1)} />
                  <Cell height={'2.3%'} width={'9.2%'} top={'32.8%'} left={'82.1%'} onClick={() => changeValue('seven', 3, 7, { height: '2.3%', width: '9.2%', top: '32.8%', left: '82.1%' }, 1)} />

                  {/* eight */}
                  <Cell height={'1.7%'} width={'9.2%'} top={'35%'} left={'82.1%'} onClick={() => changeValue('eight', 0.5, 8, { height: '1.7%', width: '9.2%', top: '35%', left: '82.1%' }, 1)} />
                  <Cell height={'2%'} width={'9.2%'} top={'36.7%'} left={'82.1%'} onClick={() => changeValue('eight', 1, 8, { height: '1.7%', width: '9.2%', top: '36.7%', left: '82.1%' }, 1)} />

                  {/* nine */}
                  <Cell height={'0.9%'} width={'9.2%'} top={'38.7%'} left={'82.1%'} onClick={() => changeValue('nine', 1, 9, { height: '0.9%', width: '9.2%', top: '38.7%', left: '82.1%' }, 1)} />
                  <Cell height={'1.7%'} width={'9.2%'} top={'39.6%'} left={'82.1%'} onClick={() => changeValue('nine', 2, 9, { height: '1.7%', width: '9.2%', top: '39.6%', left: '82.1%' }, 1)} />
                </>
                <img
                  overflow='scroll'
                  width="100%"
                  src={`${KIM_LHC_1}`}
                />
              </Block>
              <Block id="Page-2">
                {okPosition[1].map((item, index) => (
                  <OkIcon height={item.height} width={item.width} top={item.top} left={item.left} />
                ))
                }

                <>


                  {/* ten */}
                  <Cell height={'1%'} width={'46%'} top={'53.2%'} left={'39%'} onClick={() => changeValue('ten', 1, 10, { height: '1%', width: '46%', top: '53.2%', left: '39%' }, 2)} />
                  <Cell height={'2.2%'} width={'46%'} top={'54.1%'} left={'39%'} onClick={() => changeValue('ten', 2, 10, { height: '2.2%', width: '46%', top: '54.1%', left: '39%' }, 2)} />


                  {/* eleven */}
                  <Cell height={'1.4%'} width={'75.8%'} top={'56.3%'} left={'9.3%'} onClick={() => changeValue('eleven', 1, 11, { height: '1.4%', width: '75.8%', top: '56.3%', left: '9.3%' }, 2)} />
                  <Cell height={'1.5%'} width={'75.8%'} top={'57.7%'} left={'9.3%'} onClick={() => changeValue('eleven', 2, 11, { height: '1.5%', width: '75.8%', top: '57.7%', left: '9.3%' }, 2)} />

                  {/* twelve */}
                  <Cell height={'1.3%'} width={'75.8%'} top={'59.2%'} left={'9.3%'} onClick={() => changeValue('twelve', 1, 12, { height: '1.3%', width: '75.8%', top: '59.2%', left: '9.3%' }, 2)} />

                  {/* thirteen */}
                  <Cell height={'2.1%'} width={'75.8%'} top={'60.5%'} left={'9.3%'} onClick={() => changeValue('thirteen', 1, 13, { height: '2.1%', width: '75.8%', top: '60.5%', left: '9.3%' }, 2)} />
                  <Cell height={'2.2%'} width={'75.8%'} top={'62.6%'} left={'9.3%'} onClick={() => changeValue('thirteen', 2, 13, { height: '2.2%', width: '75.8%', top: '62.6%', left: '9.3%' }, 2)} />

                  {/* fourteen */}
                  <Cell height={'1.5%'} width={'75.8%'} top={'64.8%'} left={'9.3%'} onClick={() => changeValue('fourteen', 1, 14, { height: '1.5%', width: '75.8%', top: '64.8%', left: '9.3%' }, 2)} />

                  {/* fifteen */}
                  <Cell height={'1.4%'} width={'75.8%'} top={'66.3%'} left={'9.3%'} onClick={() => changeValue('fifteen', 2, 15, { height: '1.4%', width: '75.8%', top: '66.3%', left: '9.3%' }, 2)} />
                  <Cell height={'1.1%'} width={'75.8%'} top={'67.7%'} left={'9.3%'} onClick={() => changeValue('fifteen', 5, 15, { height: '1.1%', width: '75.8%', top: '67.7%', left: '9.3%' }, 2)} />

                  {/* sixteen */}
                  <Cell height={'0.7%'} width={'75.8%'} top={'68.8%'} left={'9.3%'} onClick={() => changeValue('sixteen', 0, 16, { height: '0.7%', width: '75.8%', top: '68.8%', left: '9.3%' }, 2)} />

                  {/* seventeen */}
                  <Interpolation name={'組織內插'} title={'時間分配評級'} content={'(範圍:0~4)'}
                    In_function={(n) => { return parseFloat(n) }} re_function={(e) => { return parseFloat(e) }}
                    block={'seventeen'} bblock={17} position={{ height: '0.95%', width: '7%', top: '70.1%', left: '81.5%' }} page={2} />

                  <Cell height={'1.6%'} width={'81.8%'} top={'71.8%'} left={'9.3%'} onClick={() => changeValue('seventeen', 0, 17, { height: '1.6%', width: '81.8%', top: '71.8%', left: '9.3%' }, 2)} />
                  <Cell height={'1.6%'} width={'81.8%'} top={'73.4%'} left={'9.3%'} onClick={() => changeValue('seventeen', 2, 17, { height: '1.6%', width: '81.8%', top: '73.4%', left: '9.3%' }, 2)} />
                  <Cell height={'1.7%'} width={'81.8%'} top={'75%'} left={'9.3%'} onClick={() => changeValue('seventeen', 4, 17, { height: '1.7%', width: '81.8%', top: '75%', left: '9.3%' }, 2)} />

                </>
                <img
                  overflow='scroll'
                  width="100%"
                  src={`${KIM_LHC_2}`}
                />

              </Block>
            </TransformComponent>
          </TransformWrapper>

          {/* Rating */}
          <Block height={'100vh'} id="Rating" >

            <Box
              sx={{
                border: '5px solid transparent',
                position: 'absolute',
                backgroundColor: totalRiskColor,
                //  ml: 'auto',
                //  mr: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                margin: 'auto',
                left: 0,
                right: 0,
                height: '100vh',
                //  width:'60vw',
                width: '100%',
              }}
            >



              {totalRisk >= 100 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 高 <br /> a.很可能超過身體負荷 <br />b.更明顯的疾病和/或功能障礙，具有病理意義的結構性損傷</Typography>
              }
              {totalRisk >= 50 && totalRisk < 100 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 中高<br /> a.對於一般耐受力的人，也有可能超過身體負荷 <br />b.疾病（疼痛），可能包括功能障礙，多數情況下可以恢復，沒有形態上的表現</Typography>
              }
              {totalRisk >= 20 && totalRisk < 50 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 中低<br /> a.對於耐受力較差的人，有可能超過身體負荷 <br />b.疲勞、低度適應問題，可於休息時間獲得恢復</Typography>
              }
              {totalRisk < 20 && totalRisk > 0 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 低<br /> a.不太可能會超過身體負荷 <br />b.預計不會有健康風險</Typography>
              }
              {totalRisk == 0 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值</Typography>
              }


              <Table>
                <Tr>
                  {KIM_LHC_Rating.map((item, index) =>

                  // <Th justify_content = 'center' font_weight='900'>{item}</Th>
                  {
                    if (index == 0 || index == 11) {
                      return (
                        <Th justify_content='center'
                          font_weight='900'>{item}</Th>
                      )
                    } else if (index == 7 || index == 9) {
                      return (
                        <Th
                          justify_content='center'
                          border_bottom='1px black solid'
                          font_weight='900'>{item}</Th>
                      )
                    } else if (index == 8 || index == 10) {
                      return (
                        <Th
                          justify_content='center'>{item}</Th>
                      )
                    } else {
                      return (
                        <Th justify_content='left'
                          border_bottom='1px gray solid'>{item}</Th>
                      )
                    }
                  }
                  )}
                </Tr>

                {total.map((item, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    {total[index].map((item, index) => {
                      if (index == 8 || index == 10) {
                        return (
                          <Td key={index} border_bottom='1px black solid'>{(Math.round(item * 100) / 100)}</Td>
                        )
                      } else if (index == 9 || index == 11 || index == 12) {
                        return (
                          <Td key={index} >{(Math.round(item * 100) / 100)}</Td>
                        )
                      } else {
                        return (
                          <Td key={index} border_bottom='1px gray solid'>{(Math.round(item * 100) / 100)}</Td>
                        )
                      }
                    }
                    )
                    }
                  </Tr>
                ))}


              </Table>
            </Box>
          </Block>
        </Union>
      </div>


    );
  } else if (currentProjectsKIM === 'KIM-PP') {
    return (
      <div className={cssMain} style={styleMain}>
        <Union id="KIM">
          <ScollDownButton bottom={'100px'} onClick={() => scrollToDown1()} > &nbsp;P1&nbsp; </ScollDownButton>
          <ScollDownButton bottom={'50px'} onClick={() => scrollToDown2()} > &nbsp;P2&nbsp; </ScollDownButton>
          <ScollDownButton bottom={'0px'} onClick={() => scrollToDown3()} > Risk </ScollDownButton>

          <TransformWrapper doubleClick={{ disabled: true }} wheel={{ activationKeys: ["Alt"] }}>
            <TransformComponent>
      

              <Stack sx={{ position: 'absolute', padding: '10px', right: '50px' }} direction="row" spacing={1} alignItems="center">
                <Typography>女</Typography>
                <AntSwitch
                  checked={projects[currentProject - 1][currentSubProject - 1].sex === 'M' ? true : false}
                  onChange={(evt) => { return dispatch(setSex(evt.target.checked)), dispatch(setTotalCalcu()) }}
                  defaultChecked={projects[currentProject - 1][currentSubProject - 1].sex === 'M' ? true : false}
                  inputProps={{ 'aria-label': 'ant design' }} />
                <Typography>男</Typography>
              </Stack>
              <FormControlLabel sx={{ position: 'absolute', left: '30px' }}
                control={<Checkbox
                  kimpp2people
                  onChange={(evt) => { return dispatch(setKimpp2people(evt.target.checked)), dispatch(setTotalCalcu()) }}
                />} label="兩人合力推拉" />

   

              <Block id="Page-1">
                {okPosition[0].map((item, index) => (
                  <OkIcon height={item.height} width={item.width} top={item.top} left={item.left} />
                ))
                }
                <>

                  {/* one */}
                  <Interpolation name={'距離內插'} title={'推拉距離'} content={'(單位:公尺)'}
                    //In_function={(n) => { return 0.56 + 0.44 * Math.pow((n / 42), 0.5) }} re_function={(e) => { return (Math.pow((e - 0.56), 2)) * (42 / 0.1936) }} //原code
                    //fair
                    In_function={(n) => {
                        var cc
                        if(n == 40){
                          cc = 1
                    }else if(n == 200){
                                  cc = 1.5
                    }else if(n == 400){
                                  cc = 2
                    }else if(n == 800){
                                  cc = 2.5
                    }else if(n == 1200){
                                  cc = 3
                    }else if(n == 1800){
                                  cc = 3.5
                    }else if(n == 2500){
                                  cc = 4
                    }else if(n == 4200){
                                  cc = 5
                    }else if(n == 6300){
                                  cc = 6
                    }else if(n == 8400){
                                  cc = 7
                    }else if(n == 11000){
                                  cc = 8
                    }else if(n == 15000){
                                  cc = 9
                    }else if(n == 20000){
                                  cc = 10
                    }else{
                        cc =  0.56 + 0.44 * Math.pow((n / 42), 0.5)
                    }
                         return cc
                    }}
                    re_function={(e) => { return (Math.pow((e - 0.56), 2)) * (42 / 0.1936) }}
                    //fair


                    block={'one'} bblock={1} position={{ height: '0.95%', width: '7%', top: '5.65%', left: '81.5%' }} page={1} />

                  <Interpolation name={'分鐘內插'} title={'推拉時間'} content={'(單位:分鐘)'}
                    //In_function={(n) => { return 0.56 + 0.44 * Math.pow((n), 0.5) }} re_function={(e) => { return (Math.pow((e - 0.56), 2)) * (1 / 0.1936) }} //原code
                    //fair
                    In_function={(n) => {
                        var cc
                        if(n == 1){
                          cc = 1
                        }else if(n == 5){
                                      cc = 1.5
                        }else if(n == 10){
                                      cc = 2
                        }else if(n == 20){
                                      cc = 2.5
                        }else if(n == 30){
                                      cc = 3
                        }else if(n == 45){
                                      cc = 3.5
                        }else if(n == 60){
                                      cc = 4
                        }else if(n == 100){
                                      cc = 5
                        }else if(n == 150){
                                      cc = 6
                        }else if(n == 210){
                                      cc = 7
                        }else if(n == 270){
                                      cc = 8
                        }else if(n == 360){
                                      cc = 9
                        }else if(n == 480){
                                      cc = 10
                        }else{
                            cc =  0.56 + 0.44 * Math.pow((n), 0.5)
                        }
                          return cc
                        }}
                    re_function={(e) => { return (Math.pow((e - 0.56), 2)) * (1 / 0.1936) }}
                    //fair

                    block={'one'} bblock={1} position={{ height: '0.95%', width: '7%', top: '5.65%', left: '70.5%' }} page={1} />


                  <Cell height={'2.2%'} width={'5%'} top={'6.6%'} left={'23.2%'} onClick={() => changeValue('one', 1, 1, { height: '2.2%', width: '5.4%', top: '6.6%', left: '22.9%' }, 1)} />
                  <Cell height={'2.2%'} width={'4.5%'} top={'6.6%'} left={'28.1%'} onClick={() => changeValue('one', 1.5, 1, { height: '2.2%', width: '4.5%', top: '6.6%', left: '28.1%' }, 1)} />
                  <Cell height={'2.2%'} width={'4.8%'} top={'6.6%'} left={'32.5%'} onClick={() => changeValue('one', 2, 1, { height: '2.2%', width: '4.8%', top: '6.6%', left: '32.4%' }, 1)} />
                  <Cell height={'2.2%'} width={'4.9%'} top={'6.6%'} left={'37.2%'} onClick={() => changeValue('one', 2.5, 1, { height: '2.2%', width: '4.9%', top: '6.6%', left: '37.1%' }, 1)} />
                  <Cell height={'2.2%'} width={'5.4%'} top={'6.6%'} left={'42.0%'} onClick={() => changeValue('one', 3, 1, { height: '2.2%', width: '5.5%', top: '6.6%', left: '42.0%' }, 1)} />
                  <Cell height={'2.2%'} width={'5.4%'} top={'6.6%'} left={'47.3%'} onClick={() => changeValue('one', 3.5, 1, { height: '2.2%', width: '5.7%', top: '6.6%', left: '47.3%' }, 1)} />
                  <Cell height={'2.2%'} width={'5.4%'} top={'6.6%'} left={'52.6%'} onClick={() => changeValue('one', 4, 1, { height: '2.2%', width: '5.6%', top: '6.6%', left: '52.8%' }, 1)} />
                  <Cell height={'2.2%'} width={'5.4%'} top={'6.6%'} left={'58%'} onClick={() => changeValue('one', 5, 1, { height: '2.2%', width: '5.4%', top: '6.6%', left: '58.2%' }, 1)} />
                  <Cell height={'2.2%'} width={'5.5%'} top={'6.6%'} left={'63.3%'} onClick={() => changeValue('one', 6, 1, { height: '2.2%', width: '5.8%', top: '6.6%', left: '63.3%' }, 1)} />
                  <Cell height={'2.2%'} width={'5.4%'} top={'6.6%'} left={'68.7%'} onClick={() => changeValue('one', 7, 1, { height: '2.2%', width: '5.6%', top: '6.6%', left: '68.9%' }, 1)} />
                  <Cell height={'2.2%'} width={'5.8%'} top={'6.6%'} left={'74%'} onClick={() => changeValue('one', 8, 1, { height: '2.2%', width: '5.8%', top: '6.6%', left: '74.4%' }, 1)} />
                  <Cell height={'2.2%'} width={'5.8%'} top={'6.6%'} left={'79.7%'} onClick={() => changeValue('one', 9, 1, { height: '2.2%', width: '6.0%', top: '6.6%', left: '79.9%' }, 1)} />
                  <Cell height={'2.2%'} width={'5.6%'} top={'6.6%'} left={'85.5%'} onClick={() => changeValue('one', 10, 1, { height: '2.2%', width: '5.6%', top: '6.6%', left: '85.5%' }, 1)} />

                  {/* two */}

                  {/* Wheelbarrow */}
                  <Interpolation name={'內插'} title={'負載重量'} content={'(單位: kg)'}
                    In_function={(n) => {
                      let result = 0;
                      if (n <= 50) {
                        result = 3
                      } else if (50 < n && n <= 200) {
                        result = 6.667 * Math.pow(10, -5) * Math.pow(n, 2) + 0.03 * n + 1.333
                      } else if (200 < n && n <= 300) {
                        result = 50
                      }
                      dispatch(setValue_Normal({ block: 'fourteen', value: n }))
                      return result
                    }}
                    re_function={(e) => {
                      return e
                    }}
                    block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '17.5%', left: '20.2%' }} page={1} />

                  <Cell height={'0.9%'} width={'5.8%'} top={'18.2%'} left={'20.2%'} onClick={() => { return changeValue('two', 3, 2, { height: '0.9%', width: '5.8%', top: '18.2%', left: '20.2%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 50 })) }} />
                  <Cell height={'0.9%'} width={'5.8%'} top={'19.1%'} left={'20.2%'} onClick={() => { return changeValue('two', 5, 2, { height: '0.9%', width: '5.8%', top: '19.1%', left: '20.2%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 100 })) }} />
                  <Cell height={'0.8%'} width={'5.8%'} top={'20%'} left={'20.2%'} onClick={() => { return changeValue('two', 10, 2, { height: '0.8%', width: '5.8%', top: '20%', left: '20.2%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 200 })) }} />
                  <Cell height={'0.9%'} width={'5.8%'} top={'20.8%'} left={'20.2%'} onClick={() => { return changeValue('two', 50, 2, { height: '0.9%', width: '5.8%', top: '20.8%', left: '20.2%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 300 })) }} />
                  <Cell height={'5.1%'} width={'5.8%'} top={'21.7%'} left={'20.2%'} onClick={() => { return changeValue('two', 100, 2, { height: '5.1%', width: '5.8%', top: '21.7%', left: '20.2%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 400 })) }} />

                  {/* Hand truck */}
                  <Interpolation name={'內插'} title={'負載重量'} content={'(單位: kg)'}
                    In_function={(n) => {
                      let result = 0;
                      if (n <= 50) {
                        result = 2
                      } else if (50 < n && n <= 200) {
                        result = 6.667 * Math.pow(10, -5) * Math.pow(n, 2) + 0.01 * n + 1.333
                      } else if (200 < n && n <= 300) {
                        result = 0.06 * n - 6
                      } else if (300 < n && n <= 400) {
                        result = 50
                      }
                      dispatch(setValue_Normal({ block: 'fourteen', value: n }))
                      return result
                    }}
                    re_function={(e) => {
                      return e
                    }}
                    block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '17.5%', left: '25.9%' }} page={1} />

                  <Cell height={'0.9%'} width={'4.4%'} top={'18.2%'} left={'25.9%'} onClick={() => { return changeValue('two', 2, 2, { height: '0.9%', width: '4.4%', top: '18.2%', left: '25.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 50 })) }} />
                  <Cell height={'0.9%'} width={'4.4%'} top={'19.1%'} left={'25.9%'} onClick={() => { return changeValue('two', 3, 2, { height: '0.9%', width: '4.4%', top: '19.1%', left: '25.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 100 })) }} />
                  <Cell height={'0.8%'} width={'4.4%'} top={'20%'} left={'25.9%'} onClick={() => { return changeValue('two', 6, 2, { height: '0.8%', width: '4.4%', top: '20%', left: '25.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 200 })) }} />
                  <Cell height={'0.9%'} width={'4.4%'} top={'20.8%'} left={'25.9%'} onClick={() => { return changeValue('two', 12, 2, { height: '0.9%', width: '4.4%', top: '20.8%', left: '25.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 300 })) }} />
                  <Cell height={'0.8%'} width={'4.4%'} top={'21.7%'} left={'25.9%'} onClick={() => { return changeValue('two', 50, 2, { height: '0.8%', width: '4.4%', top: '21.7%', left: '25.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 400 })) }} />
                  <Cell height={'4.3%'} width={'4.4%'} top={'22.5%'} left={'25.9%'} onClick={() => { return changeValue('two', 100, 2, { height: '4.3%', width: '4.4%', top: '22.5%', left: '25.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 600 })) }} />

                  {/* Garbage can (single axis) */}
                  <Interpolation name={'內插'} title={'負載重量'} content={'(單位: kg)'}
                    In_function={(n) => {
                      let result = 0;
                      if (n <= 50) {
                        result = 2.5
                      } else if (50 < n && n <= 200) {
                        result = 0.03 * n + 1
                      } else if (200 < n && n <= 300) {
                        result = 50
                      }
                      dispatch(setValue_Normal({ block: 'fourteen', value: n }))
                      return result
                    }}
                    re_function={(e) => {
                      return e
                    }}
                    block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '17.5%', left: '30.1%' }} page={1} />

                  <Cell height={'0.9%'} width={'5.4%'} top={'18.2%'} left={'30.1%'} onClick={() => { return changeValue('two', 2.5, 2, { height: '0.9%', width: '5.4%', top: '18.2%', left: '30.1%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 50 })) }} />
                  <Cell height={'0.9%'} width={'5.4%'} top={'19.1%'} left={'30.1%'} onClick={() => { return changeValue('two', 4, 2, { height: '0.9%', width: '5.4%', top: '19.1%', left: '30.1%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 100 })) }} />
                  <Cell height={'0.8%'} width={'5.4%'} top={'20%'} left={'30.1%'} onClick={() => { return changeValue('two', 7, 2, { height: '0.8%', width: '5.4%', top: '20%', left: '30.1%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 200 })) }} />
                  <Cell height={'0.9%'} width={'5.4%'} top={'20.8%'} left={'30.1%'} onClick={() => { return changeValue('two', 50, 2, { height: '0.9%', width: '5.4%', top: '20.8%', left: '30.1%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 300 })) }} />
                  <Cell height={'5.1%'} width={'5.4%'} top={'21.7%'} left={'30.1%'} onClick={() => { return changeValue('two', 100, 2, { height: '5.1%', width: '5.4%', top: '21.7%', left: '30.1%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 400 })) }} />

                  {/* Wire mesh trolley, castors only */}
                  <Interpolation name={'內插'} title={'負載重量'} content={'(單位: kg)'}
                    In_function={(n) => {
                      let result = 0;
                      if (n <= 50) {
                        result = 2.5
                      } else if (50 < n && n <= 300) {
                        result = 0.01 * n + 2
                      } else if (300 < n && n <= 600) {
                        result = 1.667 * Math.pow(10, -5) * Math.pow(n, 2) + 8.333 * Math.pow(10, -3) * n + 1
                      } else if (600 < n && n <= 800) {
                        result = 50
                      }
                      dispatch(setValue_Normal({ block: 'fourteen', value: n }))
                      return result
                    }}
                    re_function={(e) => {
                      return e
                    }}
                    block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '17.5%', left: '35.4%' }} page={1} />


                  <Cell height={'0.9%'} width={'5.7%'} top={'18.2%'} left={'35.4%'} onClick={() => { return changeValue('two', 2.5, 2, { height: '0.9%', width: '5.7%', top: '18.2%', left: '35.4%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 50 })) }} />
                  <Cell height={'0.9%'} width={'5.7%'} top={'19.1%'} left={'35.4%'} onClick={() => { return changeValue('two', 3, 2, { height: '0.9%', width: '5.7%', top: '19.1%', left: '35.4%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 100 })) }} />
                  <Cell height={'0.8%'} width={'5.7%'} top={'20%'} left={'35.4%'} onClick={() => { return changeValue('two', 4, 2, { height: '0.8%', width: '5.7%', top: '20%', left: '35.4%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 200 })) }} />
                  <Cell height={'0.9%'} width={'5.7%'} top={'20.8%'} left={'35.4%'} onClick={() => { return changeValue('two', 5, 2, { height: '0.9%', width: '5.7%', top: '20.8%', left: '35.4%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 300 })) }} />
                  <Cell height={'0.8%'} width={'5.7%'} top={'21.7%'} left={'35.4%'} onClick={() => { return changeValue('two', 7, 2, { height: '0.8%', width: '5.7%', top: '21.7%', left: '35.4%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 400 })) }} />
                  <Cell height={'0.9%'} width={'5.7%'} top={'22.5%'} left={'35.4%'} onClick={() => { return changeValue('two', 12, 2, { height: '0.9%', width: '5.7%', top: '22.5%', left: '35.4%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 600 })) }} />
                  <Cell height={'0.9%'} width={'5.7%'} top={'23.4%'} left={'35.4%'} onClick={() => { return changeValue('two', 50, 2, { height: '0.9%', width: '5.7%', top: '23.4%', left: '35.4%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 800 })) }} />
                  <Cell height={'2.6%'} width={'5.7%'} top={'24.2%'} left={'35.4%'} onClick={() => { return changeValue('two', 100, 2, { height: '2.6%', width: '5.7%', top: '24.2%', left: '35.4%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1000 })) }} />

                  {/* Dumpster */}
                  <Interpolation name={'內插'} title={'負載重量'} content={'(單位: kg)'}
                    In_function={(n) => {
                      let result = 0;
                      if (n <= 50) {
                        result = 3
                      } else if (50 < n && n <= 300) {
                        result = 0.02 * n + 2
                      } else if (300 < n && n <= 400) {
                        result = 0.04 * n - 4
                      } else if (400 < n && n <= 600) {
                        result = 50
                      }
                      dispatch(setValue_Normal({ block: 'fourteen', value: n }))
                      return result
                    }}
                    re_function={(e) => {
                      return e
                    }}
                    block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '17.5%', left: '41.1%' }} page={1} />

                  <Cell height={'0.9%'} width={'6%'} top={'18.2%'} left={'41.1%'} onClick={() => { return changeValue('two', 3, 2, { height: '0.9%', width: '6%', top: '18.2%', left: '41.1%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 50 })) }} />
                  <Cell height={'0.9%'} width={'6%'} top={'19.1%'} left={'41.1%'} onClick={() => { return changeValue('two', 4, 2, { height: '0.9%', width: '6%', top: '19.1%', left: '41.1%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 100 })) }} />
                  <Cell height={'0.8%'} width={'6%'} top={'20%'} left={'41.1%'} onClick={() => { return changeValue('two', 6, 2, { height: '0.8%', width: '6%', top: '20%', left: '41.1%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 200 })) }} />
                  <Cell height={'0.9%'} width={'6%'} top={'20.8%'} left={'41.1%'} onClick={() => { return changeValue('two', 8, 2, { height: '0.9%', width: '6%', top: '20.8%', left: '41.1%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 300 })) }} />
                  <Cell height={'0.9%'} width={'6%'} top={'21.7%'} left={'41.1%'} onClick={() => { return changeValue('two', 12, 2, { height: '0.9%', width: '6%', top: '21.7%', left: '41.1%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 400 })) }} />
                  <Cell height={'0.9%'} width={'6%'} top={'22.5%'} left={'41.1%'} onClick={() => { return changeValue('two', 50, 2, { height: '0.9%', width: '6%', top: '22.5%', left: '41.1%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 600 })) }} />
                  <Cell height={'3.4%'} width={'6%'} top={'23.4%'} left={'41.1%'} onClick={() => { return changeValue('two', 100, 2, { height: '3.4%', width: '6%', top: '23.4%', left: '41.1%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 800 })) }} />

                  {/* Pallet trucks */}
                  <Interpolation name={'內插'} title={'負載重量'} content={'(單位: kg)'}
                    In_function={(n) => {
                      let result = 0;
                      if (n <= 100) {
                        result = 1
                      } else if (100 < n && n <= 600) {
                        result = 0.01 * n
                      } else if (600 < n && n <= 1000) {
                        result = 1.25 * Math.pow(10, -5) * Math.pow(n, 2) + 2.5 * Math.pow(10, -3) * n
                      } else if (1000 < n && n <= 1300) {
                        result = 50
                      }
                      dispatch(setValue_Normal({ block: 'fourteen', value: n }))
                      return result
                    }}
                    re_function={(e) => {
                      return e
                    }}
                    block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '17.5%', left: '46.9%' }} page={1} />

                  <Cell height={'0.9%'} width={'8%'} top={'18.2%'} left={'46.9%'} onClick={() => { return changeValue('two', 1, 2, { height: '0.9%', width: '8%', top: '18.2%', left: '46.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 50 })) }} />
                  <Cell height={'0.9%'} width={'8%'} top={'19.1%'} left={'46.9%'} onClick={() => { return changeValue('two', 1, 2, { height: '0.9%', width: '8%', top: '19.1%', left: '46.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 100 })) }} />
                  <Cell height={'0.8%'} width={'8%'} top={'20%'} left={'46.9%'} onClick={() => { return changeValue('two', 2, 2, { height: '0.8%', width: '8%', top: '20%', left: '46.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 200 })) }} />
                  <Cell height={'0.9%'} width={'8%'} top={'20.8%'} left={'46.9%'} onClick={() => { return changeValue('two', 3, 2, { height: '0.9%', width: '8%', top: '20.8%', left: '46.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 300 })) }} />
                  <Cell height={'0.9%'} width={'8%'} top={'21.7%'} left={'46.9%'} onClick={() => { return changeValue('two', 4, 2, { height: '0.9%', width: '8%', top: '21.7%', left: '46.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 400 })) }} />
                  <Cell height={'0.9%'} width={'8%'} top={'22.5%'} left={'46.9%'} onClick={() => { return changeValue('two', 6, 2, { height: '0.9%', width: '8%', top: '22.5%', left: '46.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 600 })) }} />
                  <Cell height={'0.9%'} width={'8%'} top={'23.4%'} left={'46.9%'} onClick={() => { return changeValue('two', 10, 2, { height: '0.9%', width: '8%', top: '23.4%', left: '46.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 800 })) }} />
                  <Cell height={'0.9%'} width={'8%'} top={'24.3%'} left={'46.9%'} onClick={() => { return changeValue('two', 15, 2, { height: '0.9%', width: '8%', top: '24.3%', left: '46.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1000 })) }} />
                  <Cell height={'0.7%'} width={'8%'} top={'25.2%'} left={'46.9%'} onClick={() => { return changeValue('two', 50, 2, { height: '0.7%', width: '8%', top: '25.2%', left: '46.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1300 })) }} />
                  <Cell height={'0.9%'} width={'8%'} top={'25.9%'} left={'46.9%'} onClick={() => { return changeValue('two', 100, 2, { height: '0.9%', width: '8%', top: '25.9%', left: '46.9%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1500 })) }} />

                  {/* Wire mesh trolleys with fixed castors or lockable swivel castors type 2 */}
                  <Interpolation name={'內插'} title={'負載重量'} content={'(單位: kg)'}
                    In_function={(n) => {
                      let result = 0;
                      if (n <= 100) {
                        result = 1
                      } else if (100 < n && n <= 300) {
                        result = 0.005 * n + 0.5
                      } else if (300 < n && n <= 600) {
                        result = 0.01 * n - 1
                      } else if (600 < n && n <= 1000) {
                        result = 1.25 * Math.pow(10, -5) * Math.pow(n, 2) - 2.5 * Math.pow(10, -3) * n + 2
                      } else if (1000 < n && n <= 1300) {
                        result = 50
                      }
                      dispatch(setValue_Normal({ block: 'fourteen', value: n }))
                      return result
                    }}
                    re_function={(e) => {
                      return e
                    }}
                    block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '17.5%', left: '54.7%' }} page={1} />



                  <Cell height={'0.9%'} width={'9.1%'} top={'18.2%'} left={'54.7%'} onClick={() => { return changeValue('two', 1, 2, { height: '0.9%', width: '9.1%', top: '18.2%', left: '54.7%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 50 })) }} />
                  <Cell height={'0.9%'} width={'9.1%'} top={'19.1%'} left={'54.7%'} onClick={() => { return changeValue('two', 1, 2, { height: '0.9%', width: '9.1%', top: '19.1%', left: '54.7%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 100 })) }} />
                  <Cell height={'0.8%'} width={'9.1%'} top={'20%'} left={'54.7%'} onClick={() => { return changeValue('two', 1.5, 2, { height: '0.8%', width: '9.1%', top: '20%', left: '54.7%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 200 })) }} />
                  <Cell height={'0.9%'} width={'9.1%'} top={'20.8%'} left={'54.7%'} onClick={() => { return changeValue('two', 2, 2, { height: '0.9%', width: '9.1%', top: '20.8%', left: '54.7%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 300 })) }} />
                  <Cell height={'0.9%'} width={'9.1%'} top={'21.7%'} left={'54.7%'} onClick={() => { return changeValue('two', 3, 2, { height: '0.9%', width: '9.1%', top: '21.7%', left: '54.7%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 400 })) }} />
                  <Cell height={'0.9%'} width={'9.1%'} top={'22.5%'} left={'54.7%'} onClick={() => { return changeValue('two', 5, 2, { height: '0.9%', width: '9.1%', top: '22.5%', left: '54.7%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 600 })) }} />
                  <Cell height={'0.9%'} width={'9.1%'} top={'23.4%'} left={'54.7%'} onClick={() => { return changeValue('two', 8, 2, { height: '0.9%', width: '9.1%', top: '23.4%', left: '54.7%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 800 })) }} />
                  <Cell height={'0.9%'} width={'9.1%'} top={'24.3%'} left={'54.7%'} onClick={() => { return changeValue('two', 12, 2, { height: '0.9%', width: '9.1%', top: '24.3%', left: '54.7%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1000 })) }} />
                  <Cell height={'0.7%'} width={'9.1%'} top={'25.2%'} left={'54.7%'} onClick={() => { return changeValue('two', 50, 2, { height: '0.7%', width: '9.1%', top: '25.2%', left: '54.7%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1300 })) }} />
                  <Cell height={'0.9%'} width={'9.1%'} top={'25.9%'} left={'54.7%'} onClick={() => { return changeValue('two', 100, 2, { height: '0.9%', width: '9.1%', top: '25.9%', left: '54.7%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1500 })) }} />

                  {/* Car with drawbar steering */}
                  <Interpolation name={'內插'} title={'負載重量'} content={'(單位: kg)'}
                    In_function={(n) => {
                      let result = 0;
                      if (n <= 100) {
                        result = 1
                      } else if (100 < n && n <= 400) {
                        result = 0.005 * n + 0.5
                      } else if (400 < n && n <= 600) {
                        result = 0.0075 * n - 0.5
                      } else if (600 < n && n <= 1000) {
                        result = 0.015 * n - 5
                      } else if (1000 < n && n <= 1300) {
                        result = 50
                      }
                      dispatch(setValue_Normal({ block: 'fourteen', value: n }))
                      return result
                    }}
                    re_function={(e) => {
                      return e
                    }}
                    block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '17.5%', left: '63.8%' }} page={1} />

                  <Cell height={'0.9%'} width={'11.1%'} top={'18.2%'} left={'63.8%'} onClick={() => { return changeValue('two', 1, 2, { height: '0.9%', width: '11.1%', top: '18.2%', left: '63.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 50 })) }} />
                  <Cell height={'0.9%'} width={'11.1%'} top={'19.1%'} left={'63.8%'} onClick={() => { return changeValue('two', 1, 2, { height: '0.9%', width: '11.1%', top: '19.1%', left: '63.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 100 })) }} />
                  <Cell height={'0.8%'} width={'11.1%'} top={'20%'} left={'63.8%'} onClick={() => { return changeValue('two', 1.5, 2, { height: '0.8%', width: '11.1%', top: '20%', left: '63.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 200 })) }} />
                  <Cell height={'0.9%'} width={'11.1%'} top={'20.8%'} left={'63.8%'} onClick={() => { return changeValue('two', 2, 2, { height: '0.9%', width: '11.1%', top: '20.8%', left: '63.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 300 })) }} />
                  <Cell height={'0.9%'} width={'11.1%'} top={'21.7%'} left={'63.8%'} onClick={() => { return changeValue('two', 2.5, 2, { height: '0.9%', width: '11.1%', top: '21.7%', left: '63.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 400 })) }} />
                  <Cell height={'0.9%'} width={'11.1%'} top={'22.5%'} left={'63.8%'} onClick={() => { return changeValue('two', 4, 2, { height: '0.9%', width: '11.1%', top: '22.5%', left: '63.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 600 })) }} />
                  <Cell height={'0.9%'} width={'11.1%'} top={'23.4%'} left={'63.8%'} onClick={() => { return changeValue('two', 7, 2, { height: '0.9%', width: '11.1%', top: '23.4%', left: '63.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 800 })) }} />
                  <Cell height={'0.9%'} width={'11.1%'} top={'24.3%'} left={'63.8%'} onClick={() => { return changeValue('two', 10, 2, { height: '0.9%', width: '11.1%', top: '24.3%', left: '63.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1000 })) }} />
                  <Cell height={'0.7%'} width={'11.1%'} top={'25.2%'} left={'63.8%'} onClick={() => { return changeValue('two', 50, 2, { height: '0.7%', width: '11.1%', top: '25.2%', left: '63.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1300 })) }} />
                  <Cell height={'0.9%'} width={'11.1%'} top={'25.9%'} left={'63.8%'} onClick={() => { return changeValue('two', 100, 2, { height: '0.9%', width: '11.1%', top: '25.9%', left: '63.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1500 })) }} />

                  {/* Overhead conveyor */}
                  <Interpolation name={'內插'} title={'負載重量'} content={'(單位: kg)'}
                    In_function={(n) => {
                      let result = 0;
                      if (n <= 100) {
                        result = 1
                      } else if (100 < n && n <= 400) {
                        result = 0.005 * n + 0.5
                      } else if (400 < n && n <= 600) {
                        result = 0.0075 * n - 0.5
                      } else if (600 < n && n <= 1000) {
                        result = 0.015 * n - 5
                      } else if (1000 < n && n <= 1300) {
                        result = 0.033333 * n - 23.3333
                      } else if (1300 < n && n <= 1600) {
                        result = 50
                      }
                      dispatch(setValue_Normal({ block: 'fourteen', value: n }))
                      return result
                    }}
                    re_function={(e) => {
                      return e
                    }}
                    block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '17.5%', left: '74.8%' }} page={1} />

                  <Cell height={'0.9%'} width={'9%'} top={'18.2%'} left={'74.8%'} onClick={() => { return changeValue('two', 1, 2, { height: '0.9%', width: '9%', top: '18.2%', left: '74.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 50 })) }} />
                  <Cell height={'0.9%'} width={'9%'} top={'19.1%'} left={'74.8%'} onClick={() => { return changeValue('two', 1, 2, { height: '0.9%', width: '9%', top: '19.1%', left: '74.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 100 })) }} />
                  <Cell height={'0.8%'} width={'9%'} top={'20%'} left={'74.8%'} onClick={() => { return changeValue('two', 1.5, 2, { height: '0.8%', width: '9%', top: '20%', left: '74.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 200 })) }} />
                  <Cell height={'0.9%'} width={'9%'} top={'20.8%'} left={'74.8%'} onClick={() => { return changeValue('two', 2, 2, { height: '0.9%', width: '9%', top: '20.8%', left: '74.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 300 })) }} />
                  <Cell height={'0.9%'} width={'9%'} top={'21.7%'} left={'74.8%'} onClick={() => { return changeValue('two', 2.5, 2, { height: '0.9%', width: '9%', top: '21.7%', left: '74.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 400 })) }} />
                  <Cell height={'0.9%'} width={'9%'} top={'22.5%'} left={'74.8%'} onClick={() => { return changeValue('two', 4, 2, { height: '0.9%', width: '9%', top: '22.5%', left: '74.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 600 })) }} />
                  <Cell height={'0.9%'} width={'9%'} top={'23.4%'} left={'74.8%'} onClick={() => { return changeValue('two', 7, 2, { height: '0.9%', width: '9%', top: '23.4%', left: '74.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 800 })) }} />
                  <Cell height={'0.9%'} width={'9%'} top={'24.3%'} left={'74.8%'} onClick={() => { return changeValue('two', 10, 2, { height: '0.9%', width: '9%', top: '24.3%', left: '74.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1000 })) }} />
                  <Cell height={'0.7%'} width={'9%'} top={'25.2%'} left={'74.8%'} onClick={() => { return changeValue('two', 20, 2, { height: '0.7%', width: '9%', top: '25.2%', left: '74.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1300 })) }} />
                  <Cell height={'0.9%'} width={'9%'} top={'25.9%'} left={'74.8%'} onClick={() => { return changeValue('two', 50, 2, { height: '0.9%', width: '9%', top: '25.9%', left: '74.8%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1500 })) }} />

                  {/* Suspension crane */}
                  <Interpolation name={'內插'} title={'負載重量'} content={'(單位: kg)'}
                    In_function={(n) => {
                      let result = 0;
                      if (n <= 50) {
                        result = 2
                      } else if (50 < n && n <= 300) {
                        result = 0.01 * n + 1.5
                      } else if (300 < n && n <= 800) {
                        result = 1.369 * Math.pow(10, -5) * Math.pow(n, 2) + 5.992 * Math.pow(10, -3) * n + 1.45
                      } else if (800 < n && n <= 1000) {
                        result = 50
                      }
                      dispatch(setValue_Normal({ block: 'fourteen', value: n }))
                      return result
                    }}
                    re_function={(e) => {
                      return e
                    }}
                    block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '17.5%', left: '83.6%' }} page={1} />


                  <Cell height={'0.9%'} width={'7.6%'} top={'18.2%'} left={'83.6%'} onClick={() => { return changeValue('two', 2, 2, { height: '0.9%', width: '7.6%', top: '18.2%', left: '83.6%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 50 })) }} />
                  <Cell height={'0.9%'} width={'7.6%'} top={'19.1%'} left={'83.6%'} onClick={() => { return changeValue('two', 2.5, 2, { height: '0.9%', width: '7.6%', top: '19.1%', left: '83.6%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 100 })) }} />
                  <Cell height={'0.8%'} width={'7.6%'} top={'20%'} left={'83.6%'} onClick={() => { return changeValue('two', 3.5, 2, { height: '0.8%', width: '7.6%', top: '20%', left: '83.6%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 200 })) }} />
                  <Cell height={'0.9%'} width={'7.6%'} top={'20.8%'} left={'83.6%'} onClick={() => { return changeValue('two', 4.5, 2, { height: '0.9%', width: '7.6%', top: '20.8%', left: '83.6%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 300 })) }} />
                  <Cell height={'0.9%'} width={'7.6%'} top={'21.7%'} left={'83.6%'} onClick={() => { return changeValue('two', 6, 2, { height: '0.9%', width: '7.6%', top: '21.7%', left: '83.6%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 400 })) }} />
                  <Cell height={'0.9%'} width={'7.6%'} top={'22.5%'} left={'83.6%'} onClick={() => { return changeValue('two', 10, 2, { height: '0.9%', width: '7.6%', top: '22.5%', left: '83.6%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 600 })) }} />
                  <Cell height={'0.9%'} width={'7.6%'} top={'23.4%'} left={'83.6%'} onClick={() => { return changeValue('two', 15, 2, { height: '0.9%', width: '7.6%', top: '23.4%', left: '83.6%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 800 })) }} />
                  <Cell height={'0.9%'} width={'7.6%'} top={'24.3%'} left={'83.6%'} onClick={() => { return changeValue('two', 50, 2, { height: '0.9%', width: '7.6%', top: '24.3%', left: '83.6%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1000 })) }} />
                  <Cell height={'1.6%'} width={'7.6%'} top={'25.2%'} left={'83.6%'} onClick={() => { return changeValue('two', 100, 2, { height: '1.6%', width: '7.6%', top: '25.2%', left: '83.6%' }, 1), dispatch(setValue_Normal({ block: 'fourteen', value: 1300 })) }} />

                  {/* three */}

                  <Cell height={'0.6%'} width={'5.9%'} top={'30.9%'} left={'73.1%'} onClick={() => changeValue('three', 0, 3, { height: '0.6%', width: '5.9%', top: '30.9%', left: '73.1%' }, 1)} />
                  <Cell height={'0.6%'} width={'5.9%'} top={'31.5%'} left={'73.1%'} onClick={() => changeValue('three', 0, 3, { height: '0.6%', width: '5.9%', top: '31.5%', left: '73.1%' }, 1)} />
                  <Cell height={'0.6%'} width={'5.9%'} top={'32.1%'} left={'73.1%'} onClick={() => changeValue('three', 0, 3, { height: '0.6%', width: '5.9%', top: '32.1%', left: '73.1%' }, 1)} />
                  <Cell height={'0.6%'} width={'5.9%'} top={'32.7%'} left={'73.1%'} onClick={() => changeValue('three', 1, 3, { height: '0.6%', width: '5.9%', top: '32.7%', left: '73.1%' }, 1)} />
                  <Cell height={'0.6%'} width={'5.9%'} top={'33.3%'} left={'73.1%'} onClick={() => changeValue('three', 3, 3, { height: '0.6%', width: '5.9%', top: '33.3%', left: '73.1%' }, 1)} />

                  <Cell height={'0.6%'} width={'5.6%'} top={'30.9%'} left={'79%'} onClick={() => changeValue('three', 0, 3, { height: '0.6%', width: '5.6%', top: '30.9%', left: '79%' }, 1)} />
                  <Cell height={'0.6%'} width={'5.6%'} top={'31.5%'} left={'79%'} onClick={() => changeValue('three', 0, 3, { height: '0.6%', width: '5.6%', top: '31.5%', left: '79%' }, 1)} />
                  <Cell height={'0.6%'} width={'5.6%'} top={'32.1%'} left={'79%'} onClick={() => changeValue('three', 1, 3, { height: '0.6%', width: '5.6%', top: '32.1%', left: '79%' }, 1)} />
                  <Cell height={'0.6%'} width={'5.6%'} top={'32.7%'} left={'79%'} onClick={() => changeValue('three', 2, 3, { height: '0.6%', width: '5.6%', top: '32.7%', left: '79%' }, 1)} />
                  <Cell height={'0.6%'} width={'5.6%'} top={'33.3%'} left={'79%'} onClick={() => changeValue('three', 5, 3, { height: '0.6%', width: '5.6%', top: '33.3%', left: '79%' }, 1)} />

                  <Cell height={'0.6%'} width={'6.7%'} top={'30.9%'} left={'84.5%'} onClick={() => changeValue('three', 0, 3, { height: '0.6%', width: '6.7%', top: '30.9%', left: '84.5%' }, 1)} />
                  <Cell height={'0.6%'} width={'6.7%'} top={'31.5%'} left={'84.5%'} onClick={() => changeValue('three', 1, 3, { height: '0.6%', width: '6.7%', top: '31.5%', left: '84.5%' }, 1)} />
                  <Cell height={'0.6%'} width={'6.7%'} top={'32.1%'} left={'84.5%'} onClick={() => changeValue('three', 2, 3, { height: '0.6%', width: '6.7%', top: '32.1%', left: '84.5%' }, 1)} />
                  <Cell height={'0.6%'} width={'6.7%'} top={'32.7%'} left={'84.5%'} onClick={() => changeValue('three', 3, 3, { height: '0.6%', width: '6.7%', top: '32.7%', left: '84.5%' }, 1)} />
                  <Cell height={'0.6%'} width={'6.7%'} top={'33.3%'} left={'84.5%'} onClick={() => changeValue('three', 6, 3, { height: '0.6%', width: '6.7%', top: '33.3%', left: '84.5%' }, 1)} />

                  {/* four  : if click 0 then 1~3 =0 */}
                  {/* 0 */}
                  <Cell height={'2.5%'} width={'4%'} top={'33.9%'} left={'25.9%'} onClick={() => changeValue('four', 0, 4, { height: '2.5%', width: '4%', top: '33.9%', left: '25.9%' }, 1)}>
                    +0
                  </Cell>
                  {/* 1~3 */}
                  <Cell height={'0.8%'} width={'6.5%'} top={'33.9%'} left={'54.4%'} onClick={() => changeValue('four', 5, 4, { height: '0.8%', width: '6.5%', top: '33.9%', left: '54.4%' }, 1)} />
                  <Cell height={'0.9%'} width={'6.5%'} top={'34.7%'} left={'54.4%'} onClick={() => changeValue('four', 10, 4, { height: '0.9%', width: '6.5%', top: '34.7%', left: '54.4%' }, 1)} />
                  <Cell height={'0.8%'} width={'6.5%'} top={'35.6%'} left={'54.4%'} onClick={() => changeValue('four', 25, 4, { height: '0.8%', width: '6.5%', top: '35.6%', left: '54.4%' }, 1)} />



                  {/* five*/}
                  <Cell height={'0.8%'} width={'18%'} top={'38.4%'} left={'61.5%'} onClick={() => changeValue('five', 3, 5, { height: '0.8%', width: '18%', top: '38.4%', left: '61.5%' }, 1)} />

                  {/* six*/}
                  <Cell height={'0.8%'} width={'9%'} top={'39.2%'} left={'61.5%'} onClick={() => changeValue('six', 3, 6, { height: '0.8%', width: '9%', top: '39.2%', left: '61.5%' }, 1)} />
                  <Cell height={'0.8%'} width={'9.1%'} top={'39.2%'} left={'70.4%'} onClick={() => changeValue('six', 1, 6, { height: '0.8%', width: '9.1%', top: '39.2%', left: '70.4%' }, 1)} />

                  {/* seven*/}
                  <Cell height={'0.8%'} width={'18%'} top={'40%'} left={'61.5%'} onClick={() => changeValue('seven', 3, 7, { height: '0.8%', width: '18%', top: '40%', left: '61.5%' }, 1)} />
                  {/* eight*/}
                  <Cell height={'0.8%'} width={'18%'} top={'40.7%'} left={'61.5%'} onClick={() => changeValue('eight', 1, 8, { height: '0.8%', width: '18%', top: '40.7%', left: '61.5%' }, 1)} />
                  {/* nine*/}
                  <Cell height={'0.8%'} width={'18%'} top={'41.5%'} left={'61.5%'} onClick={() => changeValue('nine', 2, 9, { height: '0.8%', width: '18%', top: '41.5%', left: '61.5%' }, 1)} />
                  {/* ten*/}
                  <Cell height={'0.8%'} width={'18%'} top={'42.3%'} left={'61.5%'} onClick={() => changeValue('ten', 0, 10, { height: '0.8%', width: '18%', top: '42.3%', left: '61.5%' }, 1)} />


                </>
                <img
                  overflow='scroll'
                  width="100%"
                  src={`${KIM_PP_1}`}
                />
              </Block>
              <Block id="Page-2">
                {okPosition[1].map((item, index) => (
                  <OkIcon height={item.height} width={item.width} top={item.top} left={item.left} />
                ))
                }

                <>

                  {/* eleven */}
                  <Cell height={'0.8%'} width={'18.3%'} top={'53.3%'} left={'62.6%'} onClick={() => changeValue('eleven', 2, 11, { height: '0.8%', width: '18.3%', top: '53.3%', left: '62.6%' }, 2)} />
                  <Cell height={'0.8%'} width={'18.3%'} top={'54.1%'} left={'62.6%'} onClick={() => changeValue('eleven', 3, 11, { height: '0.8%', width: '18.3%', top: '54.1%', left: '62.6%' }, 2)} />
                  <Cell height={'0.8%'} width={'18.3%'} top={'54.9%'} left={'62.6%'} onClick={() => changeValue('eleven', 2, 11, { height: '0.8%', width: '18.3%', top: '54.9%', left: '62.6%' }, 2)} />
                  <Cell height={'0.7%'} width={'18.3%'} top={'55.7%'} left={'62.6%'} onClick={() => changeValue('eleven', 2, 11, { height: '0.7%', width: '18.3%', top: '55.7%', left: '62.6%' }, 2)} />
                  <Cell height={'0.8%'} width={'18.3%'} top={'56.4%'} left={'62.6%'} onClick={() => changeValue('eleven', 0, 11, { height: '0.8%', width: '18.3%', top: '56.4%', left: '62.6%' }, 2)} />


                  {/* twelve */}
                  <Interpolation name={'姿勢內插'} title={'身體姿勢評級'} content={'(範圍:0~8)'}
                    In_function={(n) => { return parseFloat(n) }} re_function={(e) => { return parseFloat(e) }}
                    block={'twelve'} bblock={12} position={{ height: '0.95%', width: '7%', top: '57.5%', left: '74.8%' }} page={1} />

                  <Cell height={'2%'} width={'10.5%'} top={'58.4%'} left={'80.8%'} onClick={() => changeValue('twelve', 3, 12, { height: '2%', width: '10.5%', top: '58.4%', left: '80.8%' }, 2)} />
                  <Cell height={'2.6%'} width={'10.5%'} top={'60.4%'} left={'80.8%'} onClick={() => changeValue('twelve', 5, 12, { height: '2.6%', width: '10.5%', top: '60.4%', left: '80.8%' }, 2)} />
                  <Cell height={'3.6%'} width={'10.5%'} top={'63%'} left={'80.8%'} onClick={() => changeValue('twelve', 8, 12, { height: '3.6%', width: '10.5%', top: '63%', left: '80.8%' }, 2)} />

                  {/* thirteen */}
                  <Interpolation name={'組織內插'} title={'時間分配評級'} content={'(範圍:0~4)'}
                    In_function={(n) => { return parseFloat(n) }} re_function={(e) => { return parseFloat(e) }}
                    block={'thirteen'} bblock={13} position={{ height: '0.95%', width: '7%', top: '67.8%', left: '77.8%' }} page={2} />

                  <Cell height={'1.5%'} width={'7.8%'} top={'68.7%'} left={'83.4%'} onClick={() => changeValue('thirteen', 0, 13, { height: '1.4%', width: '7.8%', top: '68.7%', left: '83.4%' }, 2)} />
                  <Cell height={'1.6%'} width={'7.8%'} top={'70.2%'} left={'83.4%'} onClick={() => changeValue('thirteen', 2, 13, { height: '1.6%', width: '7.8%', top: '70.2%', left: '83.4%' }, 2)} />
                  <Cell height={'1.4%'} width={'7.8%'} top={'71.7%'} left={'83.4%'} onClick={() => changeValue('thirteen', 4, 13, { height: '1.4%', width: '7.8%', top: '71.7%', left: '83.4%' }, 2)} />

                </>
                <img
                  overflow='scroll'
                  width="100%"
                  src={`${KIM_PP_2}`}
                />

              </Block>
            </TransformComponent>
          </TransformWrapper>

          {/* Rating */}
          <Block height={'100vh'} id="Rating" >

            <Box
              sx={{
                border: '5px solid transparent',
                position: 'absolute',
                backgroundColor: totalRiskColor,
                //  ml: 'auto',
                //  mr: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                margin: 'auto',
                left: 0,
                right: 0,
                height: '100vh',
                //  width:'60vw',
                width: '100%',
              }}
            >
              {totalRisk >= 100 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 高 <br /> a.很可能超過身體負荷 <br />b.更明顯的疾病和/或功能障礙，具有病理意義的結構性損傷</Typography>
              }
              {totalRisk >= 50 && totalRisk < 100 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 中高<br /> a.對於一般耐受力的人，也有可能超過身體負荷 <br />b.疾病（疼痛），可能包括功能障礙，多數情況下可以恢復，沒有形態上的表現</Typography>
              }
              {totalRisk >= 20 && totalRisk < 50 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 中低<br /> a.對於耐受力較差的人，有可能超過身體負荷 <br />b.疲勞、低度適應問題，可於休息時間獲得恢復</Typography>
              }
              {totalRisk < 20 && totalRisk > 0 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 低<br /> a.不太可能會超過身體負荷 <br />b.預計不會有健康風險</Typography>
              }
              {totalRisk == 0 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值</Typography>
              }

              <Table>
                <Tr>
                  {KIM_PP_Rating.map((item, index) =>

                  // <Th justify_content = 'center' font_weight='900'>{item}</Th>
                  {
                    if (index == 0 || index == 11) {
                      return (
                        <Th justify_content='center'
                          font_weight='900'>{item}</Th>
                      )
                    } else if (index == 7 || index == 9) {
                      return (
                        <Th
                          justify_content='center'
                          border_bottom='1px black solid'
                          font_weight='900'>{item}</Th>
                      )
                    } else if (index == 8 || index == 10) {
                      return (
                        <Th
                          justify_content='center'>{item}</Th>
                      )
                    } else {
                      return (
                        <Th justify_content='left'
                          border_bottom='1px gray solid'>{item}</Th>
                      )
                    }
                  }
                  )}
                </Tr>

                {total.map((item, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    {total[index].map((item, index) => {
                      if (index == 8 || index == 10) {
                        return (
                          <Td key={index} border_bottom='1px black solid'>{(Math.round(item * 100) / 100)}</Td>
                        )
                      } else if (index == 9 || index == 11 || index == 12) {
                        return (
                          <Td key={index} >{(Math.round(item * 100) / 100)}</Td>
                        )
                      } else {
                        return (
                          <Td key={index} border_bottom='1px gray solid'>{(Math.round(item * 100) / 100)}</Td>
                        )
                      }
                    }
                    )
                    }
                  </Tr>
                ))}


              </Table>
            </Box>
          </Block>
        </Union>
      </div>
    );
  } else if (currentProjectsKIM === 'KIM-MHO') {
    return (
      <div className={cssMain} style={styleMain}>
        <Union id="KIM">
          <ScollDownButton bottom={'100px'} onClick={() => scrollToDown1()} > &nbsp;P1&nbsp; </ScollDownButton>
          <ScollDownButton bottom={'50px'} onClick={() => scrollToDown2()} > &nbsp;P2&nbsp; </ScollDownButton>
          <ScollDownButton bottom={'0px'} onClick={() => scrollToDown3()} > Risk </ScollDownButton>

          <TransformWrapper doubleClick={{ disabled: true }} wheel={{ activationKeys: ["Alt"] }}>
            <TransformComponent>
              <Stack sx={{ position: 'absolute', padding: '10px', right: '50px' }} direction="row" spacing={1} alignItems="center">
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '900' }} >女</Typography>
                <AntSwitch
                  sx={{}}
                  checked={projects[currentProject - 1][currentSubProject - 1].sex === 'M' ? true : false}
                  onChange={(evt) => { return dispatch(setSex(evt.target.checked)), dispatch(setTotalCalcu()) }}
                  defaultChecked={projects[currentProject - 1][currentSubProject - 1].sex === 'M' ? true : false}
                  inputProps={{ 'aria-label': 'ant design' }} />
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '900' }}
                >男</Typography>
              </Stack>

              <Block id="Page-1">
                {okPosition[0].map((item, index) => (
                  <OkIcon height={item.height} width={item.width} top={item.top} left={item.left} />
                ))
                }
                <>
                  {/* one */}
                  <Interpolation name={'時間內插'} title={'工作時間'} content={'(單位:分鐘)'}
                    In_function={(n) => { return n / 60 }} re_function={(e) => { return e * 60 }}
                    block={'one'} bblock={1} position={{ height: '0.95%', width: '7%', top: '6.3%', left: '81.5%' }} page={1} />

                  <Cell height={'1.8%'} width={'4.6%'} top={'7.2%'} left={'46.5%'} onClick={() => changeValue('one', 1, 1, { height: '1.8%', width: '4.6%', top: '7.2%', left: '47%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.5%'} top={'7.2%'} left={'51.1%'} onClick={() => changeValue('one', 2, 1, { height: '1.8%', width: '4.5%', top: '7.2%', left: '51.1%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.5%'} top={'7.2%'} left={'55.5%'} onClick={() => changeValue('one', 3, 1, { height: '1.8%', width: '4.5%', top: '7.2%', left: '55.5%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.5%'} top={'7.2%'} left={'60%'} onClick={() => changeValue('one', 4, 1, { height: '1.8%', width: '4.5%', top: '7.2%', left: '60%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.5%'} top={'7.2%'} left={'64.4%'} onClick={() => changeValue('one', 5, 1, { height: '1.8%', width: '4.5%', top: '7.2%', left: '64.4%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.5%'} top={'7.2%'} left={'68.8%'} onClick={() => changeValue('one', 6, 1, { height: '1.8%', width: '4.5%', top: '7.2%', left: '68.8%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.5%'} top={'7.2%'} left={'73.2%'} onClick={() => changeValue('one', 7, 1, { height: '1.8%', width: '4.5%', top: '7.2%', left: '73.2%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.5%'} top={'7.2%'} left={'77.6%'} onClick={() => changeValue('one', 8, 1, { height: '1.8%', width: '4.5%', top: '7.2%', left: '77.6%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.6%'} top={'7.2%'} left={'82%'} onClick={() => changeValue('one', 9, 1, { height: '1.8%', width: '4.6%', top: '7.2%', left: '82%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.6%'} top={'7.2%'} left={'86.5%'} onClick={() => changeValue('one', 10, 1, { height: '1.8%', width: '4.6%', top: '7.2%', left: '86.5%' }, 1)} />


                  {/* two */}

                  {/* 握持 */}
                  <> <Interpolation name={'內插'} title={'握持 低施力'} content={'(單位: 秒/每分鐘)'}
                    In_function={(n) => {
                      let result = 3.5 * 1.6 * n / 60
                      dispatch(setValue_Normal({ block: 'seven', value: n }))
                      return result
                    }}
                    re_function={(e) => {
                      return e
                    }}
                    block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '13.9%', left: '47.5%' }} page={1} />


                    <Interpolation name={'內插'} title={'握持 中等施力'} content={'(單位: 秒/每分鐘)'}
                      In_function={(n) => {
                        let result = 3.5 * 2.5 * n / 60
                        dispatch(setValue_Normal({ block: 'eight', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'three'} bblock={3} position={{ height: '0.8%', width: '3%', top: '15.5%', left: '47.5%' }} page={1} />


                    <Interpolation name={'內插'} title={'握持 高施力'} content={'(單位: 秒/每分鐘)'}
                      In_function={(n) => {
                        let result = 3.5 * 4 * n / 60
                        dispatch(setValue_Normal({ block: 'nine', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'four'} bblock={4} position={{ height: '0.8%', width: '3%', top: '16.8%', left: '47.5%' }} page={1} />

                    <Interpolation name={'內插'} title={'握持 極高施力'} content={'(單位: 秒/每分鐘)'}
                      In_function={(n) => {
                        let result = 3.5 * 6.3 * n / 60
                        dispatch(setValue_Normal({ block: 'ten', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'five'} bblock={5} position={{ height: '0.8%', width: '3%', top: '18.4%', left: '47.5%' }} page={1} />

                    <Interpolation name={'內插'} title={'握持 峰值施力'} content={'(單位: 秒/每分鐘)'}
                      In_function={(n) => {
                        let result = 0

                        if (1 <= n && n <= 15) {
                          result = 3.5 * 40 * n / 60
                        } else if (n > 15) {
                          result = 100
                        }
                        dispatch(setValue_Normal({ block: 'eleven', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'six'} bblock={6} position={{ height: '0.8%', width: '3%', top: '20.2%', left: '47.5%' }} page={1} /></>


                  {/* 握持 */}
                  <Cell height={'1.7%'} width={'5.5%'} top={'13.9%'} left={'52.3%'} onClick={() => { return changeValue('two', 5.5, 2, { height: '1.7%', width: '5.5%', top: '13.9%', left: '52.3%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 60 })) }} />
                  <Cell height={'1.7%'} width={'5.5%'} top={'13.9%'} left={'57.7%'} onClick={() => { return changeValue('two', 3, 2, { height: '1.7%', width: '5.5%', top: '13.9%', left: '57.7%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 30 })) }} />
                  <Cell height={'1.7%'} width={'4.5%'} top={'13.9%'} left={'63.1%'} onClick={() => { return changeValue('two', 1.5, 2, { height: '1.7%', width: '4.5%', top: '13.9%', left: '63.1%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 15 })) }} />

                  <Cell height={'1.3%'} width={'5.5%'} top={'15.5%'} left={'52.3%'} onClick={() => { return changeValue('three', 9, 3, { height: '1.3%', width: '5.5%', top: '15.5%', left: '52.3%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 60 })) }} />
                  <Cell height={'1.3%'} width={'5.5%'} top={'15.5%'} left={'57.7%'} onClick={() => { return changeValue('three', 4.5, 3, { height: '1.3%', width: '5.5%', top: '15.5%', left: '57.7%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 30 })) }} />
                  <Cell height={'1.3%'} width={'4.5%'} top={'15.5%'} left={'63.1%'} onClick={() => { return changeValue('three', 2.5, 3, { height: '1.3%', width: '4.5%', top: '15.5%', left: '63.1%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 15 })) }} />

                  <Cell height={'1.6%'} width={'5.5%'} top={'16.8%'} left={'52.3%'} onClick={() => { return changeValue('four', 14, 4, { height: '1.6%', width: '5.5%', top: '16.8%', left: '52.3%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 60 })) }} />
                  <Cell height={'1.6%'} width={'5.5%'} top={'16.8%'} left={'57.7%'} onClick={() => { return changeValue('four', 7, 4, { height: '1.6%', width: '5.5%', top: '16.8%', left: '57.7%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 30 })) }} />
                  <Cell height={'1.6%'} width={'4.5%'} top={'16.8%'} left={'63.1%'} onClick={() => { return changeValue('four', 3.5, 4, { height: '1.6%', width: '4.5%', top: '16.8%', left: '63.1%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 15 })) }} />

                  <Cell height={'1.8%'} width={'5.5%'} top={'18.4%'} left={'52.3%'} onClick={() => { return changeValue('five', 22, 5, { height: '1.8%', width: '5.5%', top: '18.4%', left: '52.3%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 60 })) }} />
                  <Cell height={'1.8%'} width={'5.5%'} top={'18.4%'} left={'57.7%'} onClick={() => { return changeValue('five', 11, 5, { height: '1.8%', width: '5.5%', top: '18.4%', left: '57.7%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 30 })) }} />
                  <Cell height={'1.8%'} width={'4.5%'} top={'18.4%'} left={'63.1%'} onClick={() => { return changeValue('five', 5.5, 5, { height: '1.8%', width: '4.5%', top: '18.4%', left: '63.1%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 15 })) }} />

                  <Cell height={'1.2%'} width={'10.8%'} top={'20.2%'} left={'52.3%'} onClick={() => { return changeValue('six', 100, 6, { height: '1.2%', width: '10.8%', top: '20.2%', left: '52.3%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 60 })) }} />
                  <Cell height={'1.2%'} width={'4.5%'} top={'20.2%'} left={'63.1%'} onClick={() => { return changeValue('six', 35, 6, { height: '1.2%', width: '4.5%', top: '20.2%', left: '63.1%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 15 })) }} />

                  {/* 移動 */}
                  <> <Interpolation name={'內插'} title={'移動 低施力'} content={'(單位: 次/每分鐘)'}
                    In_function={(n) => {
                      let result = 3.5 * 1.6 * n / 60
                      dispatch(setValue_Normal({ block: 'eighteen', value: n }))
                      return result
                    }}
                    re_function={(e) => {
                      return e
                    }}
                    block={'twelve'} bblock={12} position={{ height: '0.8%', width: '3%', top: '13.9%', left: '90.9%' }} page={1} />


                    <Interpolation name={'內插'} title={'移動 中等施力'} content={'(單位: 次/每分鐘)'}
                      In_function={(n) => {
                        let result = 3.5 * 2.5 * n / 60
                        dispatch(setValue_Normal({ block: 'nineteen', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'thirteen'} bblock={13} position={{ height: '0.8%', width: '3%', top: '15.5%', left: '90.9%' }} page={1} />


                    <Interpolation name={'內插'} title={'移動 高施力'} content={'(單位: 次/每分鐘)'}
                      In_function={(n) => {
                        let result = 3.5 * 4 * n / 60
                        dispatch(setValue_Normal({ block: 'twenty', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'fourteen'} bblock={14} position={{ height: '0.8%', width: '3%', top: '16.8%', left: '90.9%' }} page={1} />

                    <Interpolation name={'內插'} title={'移動 極高施力'} content={'(單位: 次/每分鐘)'}
                      In_function={(n) => {
                        let result = 0

                        if (1 <= n && n <= 60) {
                          result = 3 * 6.3 * n / 60
                        } else if (n > 60) {
                          result = 100
                        }
                        dispatch(setValue_Normal({ block: 'twenty-one', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'fifteen'} bblock={15} position={{ height: '0.8%', width: '3%', top: '18.4%', left: '90.9%' }} page={1} />

                    <Interpolation name={'內插'} title={'移動 峰值施力'} content={'(單位: 次/每分鐘)'}
                      In_function={(n) => {
                        let result = 0

                        if (1 <= n && n <= 15) {
                          result = 3 * 40 * n / 60
                        } else if (n > 15) {
                          result = 100
                        }
                        dispatch(setValue_Normal({ block: 'twenty-two', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'sixteen'} bblock={16} position={{ height: '0.8%', width: '3%', top: '20.2%', left: '90.9%' }} page={1} />

                    <Interpolation name={'內插'} title={'移動 用力敲擊'} content={'(單位: 次/每分鐘)'}
                      In_function={(n) => {
                        let result = 0

                        if (1 <= n && n <= 15) {
                          result = 3 * 40 * n / 60
                        } else if (n > 15) {
                          result = 100
                        }
                        dispatch(setValue_Normal({ block: 'twenty-three', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'seventeen'} bblock={17} position={{ height: '0.8%', width: '3%', top: '21.4%', left: '90.9%' }} page={1} /></>

                  {/* 移動 */}
                  <Cell height={'1.7%'} width={'4.5%'} top={'13.9%'} left={'67.6%'} onClick={() => { return changeValue('twelve', 0.5, 12, { height: '1.7%', width: '4.5%', top: '13.9%', left: '67.6%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 5 })) }} />
                  <Cell height={'1.7%'} width={'4.5%'} top={'13.9%'} left={'72%'} onClick={() => { return changeValue('twelve', 1, 12, { height: '1.7%', width: '4.5%', top: '13.9%', left: '72%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 15 })) }} />
                  <Cell height={'1.7%'} width={'4.8%'} top={'13.9%'} left={'76.5%'} onClick={() => { return changeValue('twelve', 2.5, 12, { height: '1.7%', width: '4.8%', top: '13.9%', left: '76.5%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 30 })) }} />
                  <Cell height={'1.7%'} width={'4.9%'} top={'13.9%'} left={'81.2%'} onClick={() => { return changeValue('twelve', 5, 12, { height: '1.7%', width: '4.9%', top: '13.9%', left: '81.2%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 60 })) }} />
                  <Cell height={'1.7%'} width={'4.8%'} top={'13.9%'} left={'86.1%'} onClick={() => { return changeValue('twelve', 7, 12, { height: '1.7%', width: '4.8%', top: '13.9%', left: '86.1%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 90 })) }} />

                  <Cell height={'1.3%'} width={'4.5%'} top={'15.5%'} left={'67.6%'} onClick={() => { return changeValue('thirteen', 0.5, 13, { height: '1.3%', width: '4.5%', top: '15.5%', left: '67.6%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 5 })) }} />
                  <Cell height={'1.3%'} width={'4.5%'} top={'15.5%'} left={'72%'} onClick={() => { return changeValue('thirteen', 2, 13, { height: '1.3%', width: '4.5%', top: '15.5%', left: '72%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 15 })) }} />
                  <Cell height={'1.3%'} width={'4.8%'} top={'15.5%'} left={'76.5%'} onClick={() => { return changeValue('thirteen', 4, 13, { height: '1.3%', width: '4.8%', top: '15.5%', left: '76.5%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 30 })) }} />
                  <Cell height={'1.3%'} width={'4.9%'} top={'15.5%'} left={'81.2%'} onClick={() => { return changeValue('thirteen', 7.5, 13, { height: '1.3%', width: '4.9%', top: '15.5%', left: '81.2%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 60 })) }} />
                  <Cell height={'1.3%'} width={'4.8%'} top={'15.5%'} left={'86.1%'} onClick={() => { return changeValue('thirteen', 11, 13, { height: '1.3%', width: '4.8%', top: '15.5%', left: '86.1%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 90 })) }} />


                  <Cell height={'1.6%'} width={'4.5%'} top={'16.8%'} left={'67.6%'} onClick={() => { return changeValue('fourteen', 1, 14, { height: '1.6%', width: '4.5%', top: '16.8%', left: '67.6%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 5 })) }} />
                  <Cell height={'1.6%'} width={'4.5%'} top={'16.8%'} left={'72%'} onClick={() => { return changeValue('fourteen', 3, 14, { height: '1.6%', width: '4.5%', top: '16.8%', left: '72%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 15 })) }} />
                  <Cell height={'1.6%'} width={'4.8%'} top={'16.8%'} left={'76.5%'} onClick={() => { return changeValue('fourteen', 6, 14, { height: '1.6%', width: '4.8%', top: '16.8%', left: '76.5%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 30 })) }} />
                  <Cell height={'1.6%'} width={'4.9%'} top={'16.8%'} left={'81.2%'} onClick={() => { return changeValue('fourteen', 12, 14, { height: '1.6%', width: '4.9%', top: '16.8%', left: '81.2%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 60 })) }} />
                  <Cell height={'1.6%'} width={'4.8%'} top={'16.8%'} left={'86.1%'} onClick={() => { return changeValue('fourteen', 18, 14, { height: '1.6%', width: '4.8%', top: '16.8%', left: '86.1%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 90 })) }} />

                  <Cell height={'1.8%'} width={'4.5%'} top={'18.4%'} left={'67.6%'} onClick={() => { return changeValue('fifteen', 1.5, 15, { height: '1.8%', width: '4.5%', top: '18.4%', left: '67.6%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 5 })) }} />
                  <Cell height={'1.8%'} width={'4.5%'} top={'18.4%'} left={'72%'} onClick={() => { return changeValue('fifteen', 5, 15, { height: '1.8%', width: '4.5%', top: '18.4%', left: '72%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 15 })) }} />
                  <Cell height={'1.8%'} width={'4.8%'} top={'18.4%'} left={'76.5%'} onClick={() => { return changeValue('fifteen', 10, 15, { height: '1.8%', width: '4.8%', top: '18.4%', left: '76.5%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 30 })) }} />
                  <Cell height={'1.8%'} width={'4.9%'} top={'18.4%'} left={'81.2%'} onClick={() => { return changeValue('fifteen', 19, 15, { height: '1.8%', width: '4.9%', top: '18.4%', left: '81.2%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 60 })) }} />

                  <Cell height={'1.2%'} width={'4.5%'} top={'20.2%'} left={'67.6%'} onClick={() => { return changeValue('sixteen', 8, 16, { height: '1.2%', width: '4.5%', top: '20.2%', left: '67.6%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 5 })) }} />
                  <Cell height={'1.2%'} width={'4.5%'} top={'20.2%'} left={'72%'} onClick={() => { return changeValue('sixteen', 30, 16, { height: '1.2%', width: '4.5%', top: '20.2%', left: '72%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 15 })) }} />
                  <Cell height={'1.2%'} width={'14.7%'} top={'20.2%'} left={'76.5%'} onClick={() => { return changeValue('sixteen', 100, 16, { height: '1.2%', width: '14.7%', top: '20.2%', left: '76.5%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 30 })) }} />

                  <Cell height={'1.3%'} width={'4.5%'} top={'21.4%'} left={'67.6%'} onClick={() => { return changeValue('seventeen', 8, 17, { height: '1.3%', width: '4.5%', top: '21.4%', left: '67.6%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 5 })) }} />
                  <Cell height={'1.3%'} width={'4.5%'} top={'21.4%'} left={'72%'} onClick={() => { return changeValue('seventeen', 30, 17, { height: '1.3%', width: '4.5%', top: '21.4%', left: '72%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 15 })) }} />
                  <Cell height={'1.3%'} width={'14.7%'} top={'21.4%'} left={'76.5%'} onClick={() => { return changeValue('seventeen', 100, 17, { height: '1.3%', width: '14.7%', top: '21.4%', left: '76.5%' }, 1), dispatch(setValue_Normal({ block: 'eight', value: 30 })) }} />






                  {/* twenty-four */}
                  <Interpolation name={'力量內插'} title={'力量傳遞/抓握條件'} content={'(範圍:0~4)'}
                    In_function={(n) => { return parseFloat(n) }} re_function={(e) => { return parseFloat(e) }}
                    block={'twenty-four'} bblock={24} position={{ height: '0.95%', width: '7%', top: '27%', left: '74.8%' }} page={2} />

                  <Cell height={'1.6%'} width={'8.9%'} top={'27.7%'} left={'82.2%'} onClick={() => changeValue('twenty-four', 0, 24, { height: '1.6%', width: '8.9%', top: '27.7%', left: '82.2%' }, 1)} />
                  <Cell height={'0.9%'} width={'8.9%'} top={'29.3%'} left={'82.2%'} onClick={() => changeValue('twenty-four', 2, 24, { height: '0.9%', width: '8.9%', top: '29.3%', left: '82.2%' }, 1)} />
                  <Cell height={'0.9%'} width={'8.9%'} top={'30.2%'} left={'82.2%'} onClick={() => changeValue('twenty-four', 4, 24, { height: '0.9%', width: '8.9%', top: '30.2%', left: '82.2%' }, 1)} />



                  {/* twenty-five */}
                  <Interpolation name={'動作內插'} title={'手臂位置及動作'} content={'(範圍:0~3)'}
                    In_function={(n) => { return parseFloat(n) }} re_function={(e) => { return parseFloat(e) }}
                    block={'twenty-five'} bblock={25} position={{ height: '0.95%', width: '7%', top: '31.5%', left: '78.8%' }} page={2} />

                  <Cell height={'1.6%'} width={'6%'} top={'32.4%'} left={'85%'} onClick={() => changeValue('twenty-five', 0, 25, { height: '1.6%', width: '6%', top: '32.4%', left: '85%' }, 1)} />
                  <Cell height={'1.5%'} width={'6%'} top={'34%'} left={'85%'} onClick={() => changeValue('twenty-five', 1, 25, { height: '1.5%', width: '6%', top: '34%', left: '85%' }, 1)} />
                  <Cell height={'1.2%'} width={'6%'} top={'35.5%'} left={'85%'} onClick={() => changeValue('twenty-five', 2, 25, { height: '1.2%', width: '6%', top: '35.5%', left: '85%' }, 1)} />
                  <Cell height={'1.2%'} width={'6%'} top={'36.7%'} left={'85%'} onClick={() => changeValue('twenty-five', 3, 25, { height: '1.2%', width: '6%', top: '36.7%', left: '85%' }, 1)} />


                  {/* twenty-six */}
                  <Interpolation name={'條件內插'} title={'不利的工作條件'} content={'(範圍:0~2)'}
                    In_function={(n) => { return parseFloat(n) }} re_function={(e) => { return parseFloat(e) }}
                    block={'twenty-six'} bblock={26} position={{ height: '0.95%', width: '7%', top: '39%', left: '78.8%' }} page={2} />

                  <Cell height={'0.9%'} width={'6.1%'} top={'39.8%'} left={'85%'} onClick={() => changeValue('twenty-six', 0, 26, { height: '0.9%', width: '6.1%', top: '39.8%', left: '85%' }, 1)} />
                  <Cell height={'1.5%'} width={'6.1%'} top={'40.6%'} left={'85%'} onClick={() => changeValue('twenty-six', 1, 26, { height: '1.5%', width: '6.1%', top: '40.6%', left: '85%' }, 1)} />
                  <Cell height={'1.5%'} width={'6.1%'} top={'42.1%'} left={'85%'} onClick={() => changeValue('twenty-six', 2, 26, { height: '1.5%', width: '6.1%', top: '42.1%', left: '85%' }, 1)} />






                </>
                <img
                  overflow='scroll'
                  width="100%"
                  src={`${KIM_MHO_1}`}
                />
              </Block>
              <Block id="Page-2">
                {okPosition[1].map((item, index) => (
                  <OkIcon height={item.height} width={item.width} top={item.top} left={item.left} />
                ))
                }

                <>
                  {/* twenty-eight */}
                  <Interpolation name={'姿勢內插'} title={'身體姿勢評級'} content={'(範圍:0~6)'}
                    In_function={(n) => { return parseFloat(n) }} re_function={(e) => { return parseFloat(e) }}
                    block={'twenty-seven'} bblock={27} position={{ height: '0.95%', width: '7%', top: '51%', left: '78.8%' }} page={1} />


                  <Cell height={'3.4%'} width={'6.1%'} top={'52.7%'} left={'85.2%'} onClick={() => changeValue('twenty-seven', 0, 27, { height: '3.4%', width: '6.1%', top: '52.7%', left: '85.2%' }, 1)} />
                  <Cell height={'3.5%'} width={'6.1%'} top={'56.1%'} left={'85.2%'} onClick={() => changeValue('twenty-seven', 2, 27, { height: '3.5%', width: '6.1%', top: '56.1%', left: '85.2%' }, 1)} />
                  <Cell height={'3.4%'} width={'6.1%'} top={'59.6%'} left={'85.2%'} onClick={() => changeValue('twenty-seven', 4, 27, { height: '3.4%', width: '6.1%', top: '59.6%', left: '85.2%' }, 1)} />
                  <Cell height={'3.6%'} width={'6.1%'} top={'63%'} left={'85.2%'} onClick={() => changeValue('twenty-seven', 6, 27, { height: '3.6%', width: '6.1%', top: '63%', left: '85.2%' }, 1)} />

                  {/* twenty-eight */}
                  <Interpolation name={'組織內插'} title={'時間分配評級'} content={'(範圍:0~4)'}
                    In_function={(n) => { return parseFloat(n) }} re_function={(e) => { return parseFloat(e) }}
                    block={'twenty-eight'} bblock={29} position={{ height: '0.95%', width: '7%', top: '68.2%', left: '78.8%' }} page={2} />

                  <Cell height={'1.3%'} width={'6.2%'} top={'69.9%'} left={'85.1%'} onClick={() => changeValue('twenty-eight', 0, 28, { height: '1.3%', width: '6.2%', top: '69.9%', left: '85.1%' }, 2)} />
                  <Cell height={'1.4%'} width={'6.2%'} top={'71.2%'} left={'85.1%'} onClick={() => changeValue('twenty-eight', 2, 28, { height: '1.4%', width: '6.2%', top: '71.2%', left: '85.1%' }, 2)} />
                  <Cell height={'1.4%'} width={'6.2%'} top={'72.6%'} left={'85.1%'} onClick={() => changeValue('twenty-eight', 4, 28, { height: '1.4%', width: '6.2%', top: '72.6%', left: '85.1%' }, 2)} />

                </>
                <img
                  overflow='scroll'
                  width="100%"
                  src={`${KIM_MHO_2}`}
                />

              </Block>
            </TransformComponent>
          </TransformWrapper>

          {/* Rating */}
          <Block height={'100vh'} id="Rating" >

            <Box
              sx={{
                border: '5px solid transparent',
                position: 'absolute',
                backgroundColor: totalRiskColor,
                //  ml: 'auto',
                //  mr: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                margin: 'auto',
                left: 0,
                right: 0,
                height: '100vh',
                //  width:'60vw',
                width: '100%',
              }}
            >
              {totalRisk >= 100 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 高 <br /> a.很可能超過身體負荷 <br />b.更明顯的疾病和/或功能障礙，具有病理意義的結構性損傷</Typography>
              }
              {totalRisk >= 50 && totalRisk < 100 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 中高<br /> a.對於一般耐受力的人，也有可能超過身體負荷 <br />b.疾病（疼痛），可能包括功能障礙，多數情況下可以恢復，沒有形態上的表現</Typography>
              }
              {totalRisk >= 20 && totalRisk < 50 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 中低<br /> a.對於耐受力較差的人，有可能超過身體負荷 <br />b.疲勞、低度適應問題，可於休息時間獲得恢復</Typography>
              }
              {totalRisk < 20 && totalRisk > 0 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 低<br /> a.不太可能會超過身體負荷 <br />b.預計不會有健康風險</Typography>
              }
              {totalRisk == 0 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值</Typography>
              }

              <Table>
                <Tr>
                  {KIM_MHO_Rating.map((item, index) =>

                  // <Th justify_content = 'center' font_weight='900'>{item}</Th>
                  {
                    if (index == 0 || index == 11) {
                      return (
                        <Th justify_content='center'
                          font_weight='900'>{item}</Th>
                      )
                    } else if (index == 7 || index == 9) {
                      return (
                        <Th
                          justify_content='center'
                          border_bottom='1px black solid'
                          font_weight='900'>{item}</Th>
                      )
                    } else if (index == 8 || index == 10) {
                      return (
                        <Th
                          justify_content='center'>{item}</Th>
                      )
                    } else {
                      return (
                        <Th justify_content='left'
                          border_bottom='1px gray solid'>{item}</Th>
                      )
                    }
                  }
                  )}
                </Tr>


                {total.map((item, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    {total[index].map((item, index) => {
                      if (index == 8 || index == 10) {
                        return (
                          <Td key={index} border_bottom='1px black solid'>{(Math.round(item * 100) / 100)}</Td>
                        )
                      } else if (index == 9 || index == 11 || index == 12) {
                        return (
                          <Td key={index} >{(Math.round(item * 100) / 100)}</Td>
                        )
                      } else {
                        return (
                          <Td key={index} border_bottom='1px gray solid'>{(Math.round(item * 100) / 100)}</Td>
                        )
                      }
                    }
                    )
                    }
                  </Tr>
                ))}


              </Table>
            </Box>
          </Block>
        </Union>
      </div>
    );
  } else if (currentProjectsKIM === 'KIM-ABP') {
    return (
      <div className={cssMain} style={styleMain}>
        <Union id="KIM">
          <ScollDownButton bottom={'100px'} onClick={() => scrollToDown1()} > &nbsp;P1&nbsp; </ScollDownButton>
          <ScollDownButton bottom={'50px'} onClick={() => scrollToDown2()} > &nbsp;P2&nbsp; </ScollDownButton>
          <ScollDownButton bottom={'0px'} onClick={() => scrollToDown3()} > Risk </ScollDownButton>

          <TransformWrapper doubleClick={{ disabled: true }} wheel={{ activationKeys: ["Alt"] }} >
            <TransformComponent>
              <Stack sx={{ position: 'absolute', padding: '10px', right: '50px' }} direction="row" spacing={1} alignItems="center">
                <Typography>女</Typography>
                <AntSwitch
                  checked={projects[currentProject - 1][currentSubProject - 1].sex === 'M' ? true : false}
                  onChange={(evt) => { return dispatch(setSex(evt.target.checked)), dispatch(setTotalCalcu()) }}
                  defaultChecked={projects[currentProject - 1][currentSubProject - 1].sex === 'M' ? true : false}
                  inputProps={{ 'aria-label': 'ant design' }} />
                <Typography>男</Typography>
              </Stack>

              <Block id="Page-1">
                {okPosition[0].map((item, index) => (
                  <OkIcon height={item.height} width={item.width} top={item.top} left={item.left} />
                ))
                }
                <>


                  {/* one */}
                  <Interpolation name={'時間內插'} title={'工作時間'} content={'(單位:分鐘)'}
                    In_function={(n) => { return n / 60 }} re_function={(e) => { return e * 60 }}
                    block={'one'} bblock={1} position={{ height: '0.95%', width: '7%', top: '7.8%', left: '81.5%' }} page={1} />


                  <Cell height={'1.8%'} width={'4.7%'} top={'8.8%'} left={'45.7%'} onClick={() => changeValue('one', 1, 1, { height: '1.8%', width: '4.7%', top: '8.8%', left: '45.7%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.7%'} top={'8.8%'} left={'50.4%'} onClick={() => changeValue('one', 2, 1, { height: '1.8%', width: '4.7%', top: '8.8%', left: '50.4%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.7%'} top={'8.8%'} left={'55.1%'} onClick={() => changeValue('one', 3, 1, { height: '1.8%', width: '4.7%', top: '8.8%', left: '55.1%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.7%'} top={'8.8%'} left={'59.8%'} onClick={() => changeValue('one', 4, 1, { height: '1.8%', width: '4.7%', top: '8.8%', left: '59.8%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.7%'} top={'8.8%'} left={'64.5%'} onClick={() => changeValue('one', 5, 1, { height: '1.8%', width: '4.7%', top: '8.8%', left: '64.5%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.7%'} top={'8.8%'} left={'69.2%'} onClick={() => changeValue('one', 6, 1, { height: '1.8%', width: '4.7%', top: '8.8%', left: '69.2%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.7%'} top={'8.8%'} left={'73.9%'} onClick={() => changeValue('one', 7, 1, { height: '1.8%', width: '4.7%', top: '8.8%', left: '73.9%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.7%'} top={'8.8%'} left={'78.6%'} onClick={() => changeValue('one', 8, 1, { height: '1.8%', width: '4.7%', top: '8.8%', left: '78.6%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.7%'} top={'8.8%'} left={'83.3%'} onClick={() => changeValue('one', 9, 1, { height: '1.8%', width: '4.7%', top: '8.8%', left: '83.3%' }, 1)} />
                  <Cell height={'1.8%'} width={'4.7%'} top={'8.8%'} left={'88%'} onClick={() => changeValue('one', 10, 1, { height: '1.8%', width: '4.7%', top: '8.8%', left: '88%' }, 1)} />



                  {/* A*/}
                  {/* 倒的 */}
                  {/* two */}
                  <Cell height={'2.45%'} width={'82.6%'} top={'14.3%'} left={'5.5%'} onClick={() => changeValue('two', 8, 2, { height: '2.45%', width: '82.6%', top: '14.3%', left: '5.5%' }, 1)} />
                  <Cell height={'2.45%'} width={'74.3%'} top={'14.3%'} left={'5.5%'} onClick={() => changeValue('two', 6, 2, { height: '2.45%', width: '74.3%', top: '14.3%', left: '5.5%' }, 1)} />
                  <Cell height={'2.45%'} width={'68.4%'} top={'14.3%'} left={'5.5%'} onClick={() => changeValue('two', 4, 2, { height: '2.45%', width: '68.4%', top: '14.3%', left: '5.5%' }, 1)} />
                  <Cell height={'2.45%'} width={'62.5%'} top={'14.3%'} left={'5.5%'} onClick={() => changeValue('two', 2, 2, { height: '2.45%', width: '62.5%', top: '14.3%', left: '5.5%' }, 1)} />


                  <Interpolation name={'內插'} title={'A: 直立背部姿勢'} content={'(單位: 在部分活動中保持該姿勢的時間百分比1~100%)'}
                      In_function={(p) => {
                        if(p>100){
                          p=100
                        }else if (p<0){
                          p=0
                        }
                        let result = 0.08*p
                        dispatch(setValue_Normal({ block: 'forty-two', value: p}))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '14.2%', left: '15.8%' }} page={1} />


                  {/* three */}
                  <Cell height={'1.8%'} width={'82.6%'} top={'16.75%'} left={'5.5%'} onClick={() => changeValue('three', 30, 3, { height: '1.8%', width: '82.6%', top: '16.75%', left: '5.5%' }, 1)} />
                  <Cell height={'1.8%'} width={'74.3%'} top={'16.75%'} left={'5.5%'} onClick={() => changeValue('three', 22, 3, { height: '1.8%', width: '74.3%', top: '16.75%', left: '5.5%' }, 1)} />
                  <Cell height={'1.8%'} width={'68.4%'} top={'16.75%'} left={'5.5%'} onClick={() => changeValue('three', 15, 3, { height: '1.8%', width: '68.4%', top: '16.75%', left: '5.5%' }, 1)} />
                  <Cell height={'1.8%'} width={'62.5%'} top={'16.75%'} left={'5.5%'} onClick={() => changeValue('three', 7, 3, { height: '1.8%', width: '62.5%', top: '16.75%', left: '5.5%' }, 1)} />

                  <Interpolation name={'內插'} title={'A: 軀幹適度前傾(>20~60°)'} content={'(單位: 在部分活動中保持該姿勢的時間百分比1~100%)'}
                      In_function={(p) => {
                        if(p>100){
                          p=100
                        }else if (p<0){
                          p=0
                        }
                        let result = 0.297*p
                        dispatch(setValue_Normal({ block: 'forty-three', value: p}))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'three'} bblock={3} position={{ height: '0.8%', width: '3%', top: '16.6%', left: '15.8%' }} page={1} />

                  {/* four */}
                  <Cell height={'1.65%'} width={'82.6%'} top={'18.55%'} left={'5.5%'} onClick={() => changeValue('four', 40, 4, { height: '1.65%', width: '82.6%', top: '18.55%', left: '5.5%' }, 1)} />
                  <Cell height={'1.65%'} width={'74.3%'} top={'18.55%'} left={'5.5%'} onClick={() => changeValue('four', 30, 4, { height: '1.65%', width: '74.3%', top: '18.55%', left: '5.5%' }, 1)} />
                  <Cell height={'1.65%'} width={'68.4%'} top={'18.55%'} left={'5.5%'} onClick={() => changeValue('four', 20, 4, { height: '1.65%', width: '68.4%', top: '18.55%', left: '5.5%' }, 1)} />
                  <Cell height={'1.65%'} width={'62.5%'} top={'18.55%'} left={'5.5%'} onClick={() => changeValue('four', 10, 4, { height: '1.65%', width: '62.5%', top: '18.55%', left: '5.5%' }, 1)} />

                  <Interpolation name={'內插'} title={'A: 軀幹嚴重前傾(>60°)'} content={'(單位: 在部分活動中保持該姿勢的時間百分比1~100%)'}
                      In_function={(p) => {
                        if(p>100){
                          p=100
                        }else if (p<0){
                          p=0
                        }
                        let result = 0.4*p
                        dispatch(setValue_Normal({ block: 'forty-four', value: p}))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'four'} bblock={4} position={{ height: '0.8%', width: '3%', top: '18.6%', left: '15.8%' }} page={1} />


                  {/* five */}
                  <Cell height={'2.3%'} width={'82.6%'} top={'20.2%'} left={'5.5%'} onClick={() => changeValue('five', 12, 5, { height: '2.3%', width: '82.6%', top: '20.2%', left: '5.5%' }, 1)} />
                  <Cell height={'2.3%'} width={'74.3%'} top={'20.2%'} left={'5.5%'} onClick={() => changeValue('five', 9, 5, { height: '2.3%', width: '74.3%', top: '20.2%', left: '5.5%' }, 1)} />
                  <Cell height={'2.3%'} width={'68.4%'} top={'20.2%'} left={'5.5%'} onClick={() => changeValue('five', 6, 5, { height: '2.3%', width: '68.4%', top: '20.2%', left: '5.5%' }, 1)} />
                  <Cell height={'2.3%'} width={'62.5%'} top={'20.2%'} left={'5.5%'} onClick={() => changeValue('five', 3, 5, { height: '2.3%', width: '62.5%', top: '20.2%', left: '5.5%' }, 1)} />

                  <Interpolation name={'內插'} title={'A: 被迫(受限)的坐姿'} content={'(單位: 在部分活動中保持該姿勢的時間百分比1~100%)'}
                      In_function={(p) => {
                        if(p>100){
                          p=100
                        }else if (p<0){
                          p=0
                        }
                        let result = 0.12*p
                        dispatch(setValue_Normal({ block: 'forty-five', value: p}))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'five'} bblock={5} position={{ height: '0.8%', width: '3%', top: '20.1%', left: '15.8%' }} page={1} />


                  {/* six */}
                  <Cell height={'1.1%'} width={'82.6%'} top={'22.5%'} left={'5.5%'} onClick={() => changeValue('six', 8, 6, { height: '1.1%', width: '82.6%', top: '22.5%', left: '5.5%' }, 1)} />
                  <Cell height={'1.1%'} width={'74.3%'} top={'22.5%'} left={'5.5%'} onClick={() => changeValue('six', 6, 6, { height: '1.1%', width: '74.3%', top: '22.5%', left: '5.5%' }, 1)} />
                  <Cell height={'1.1%'} width={'68.4%'} top={'22.5%'} left={'5.5%'} onClick={() => changeValue('six', 4, 6, { height: '1.1%', width: '68.4%', top: '22.5%', left: '5.5%' }, 1)} />
                  <Cell height={'1.1%'} width={'62.5%'} top={'22.5%'} left={'5.5%'} onClick={() => changeValue('six', 2, 6, { height: '1.1%', width: '62.5%', top: '22.5%', left: '5.5%' }, 1)} />

                  <Interpolation name={'內插'} title={'A: 姿勢可變的坐姿 (改變成站立行走是不可行的)'} content={'(單位: 在部分活動中保持該姿勢的時間百分比1~100%)'}
                      In_function={(p) => {
                        if(p>100){
                          p=100
                        }else if (p<0){
                          p=0
                        }
                        let result = 0.08*p
                        dispatch(setValue_Normal({ block: 'forty-six', value: p}))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'six'} bblock={6} position={{ height: '0.8%', width: '3%', top: '22.4%', left: '15.8%' }} page={1} />

                  {/* seven */}
                  <Cell height={'1%'} width={'82.6%'} top={'23.6%'} left={'5.5%'} onClick={() => changeValue('seven', 2, 7, { height: '1%', width: '82.6%', top: '23.6%', left: '5.5%' }, 1)} />
                  <Cell height={'1%'} width={'74.3%'} top={'23.6%'} left={'5.5%'} onClick={() => changeValue('seven', 1.5, 7, { height: '1%', width: '74.3%', top: '23.6%', left: '5.5%' }, 1)} />
                  <Cell height={'1%'} width={'68.4%'} top={'23.6%'} left={'5.5%'} onClick={() => changeValue('seven', 1, 7, { height: '1%', width: '68.4%', top: '23.6%', left: '5.5%' }, 1)} />
                  <Cell height={'1%'} width={'62.5%'} top={'23.6%'} left={'5.5%'} onClick={() => changeValue('seven', 0.5, 7, { height: '1%', width: '62.5%', top: '23.6%', left: '5.5%' }, 1)} />

                  <Interpolation name={'內插'} title={'A: 姿勢可變的坐姿 (改變成站立行走是可行的)'} content={'(單位: 在部分活動中保持該姿勢的時間百分比1~100%)'}
                      In_function={(p) => {
                        if(p>100){
                          p=100
                        }else if (p<0){
                          p=0
                        }
                        let result = 0.02*p
                        dispatch(setValue_Normal({ block: 'forty-seven', value: p}))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'seven'} bblock={7} position={{ height: '0.8%', width: '3%', top: '23.5%', left: '15.8%' }} page={1} />


                  {/* B */}
                  {/* 倒的 */}
                  {/* eight */}
                  <Cell height={'3%'} width={'82%'} top={'28.15%'} left={'5.5%'} onClick={() => changeValue('eight', 40, 8, { height: '3%', width: '82%', top: '28.15%', left: '5.5%' }, 1)} />
                  <Cell height={'3%'} width={'76%'} top={'28.15%'} left={'5.5%'} onClick={() => changeValue('eight', 30, 8, { height: '3%', width: '76%', top: '28.15%', left: '5.5%' }, 1)} />
                  <Cell height={'3%'} width={'69.8%'} top={'28.15%'} left={'5.5%'} onClick={() => changeValue('eight', 20, 8, { height: '3%', width: '69.8%', top: '28.15%', left: '5.5%' }, 1)} />
                  <Cell height={'3%'} width={'63.7%'} top={'28.15%'} left={'5.5%'} onClick={() => changeValue('eight', 10, 8, { height: '3%', width: '63.7%', top: '28.15%', left: '5.5%' }, 1)} />

                  <Interpolation name={'內插'} title={'B: 手臂舉起、雙手高度過肩'} content={'(單位: 在部分活動中保持該姿勢的時間百分比1~100%)'}
                      In_function={(p) => {
                        if(p>100){
                          p=100
                        }else if (p<0){
                          p=0
                        }
                        let result = 0.4*p
                        dispatch(setValue_Normal({ block: 'forty-eight', value: p}))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'eight'} bblock={8} position={{ height: '0.8%', width: '3%', top: '28.1%', left: '15.8%' }} page={1} />


                  {/* nine */}
                  <Cell height={'1.6%'} width={'82%'} top={'31.15%'} left={'5.5%'} onClick={() => changeValue('nine', 24, 9, { height: '1.6%', width: '82%', top: '31.15%', left: '5.5%' }, 1)} />
                  <Cell height={'1.6%'} width={'76%'} top={'31.15%'} left={'5.5%'} onClick={() => changeValue('nine', 18, 9, { height: '1.6%', width: '76%', top: '31.15%', left: '5.5%' }, 1)} />
                  <Cell height={'1.6%'} width={'69.8%'} top={'31.15%'} left={'5.5%'} onClick={() => changeValue('nine', 12, 9, { height: '1.6%', width: '69.8%', top: '31.15%', left: '5.5%' }, 1)} />
                  <Cell height={'1.6%'} width={'63.7%'} top={'31.15%'} left={'5.5%'} onClick={() => changeValue('nine', 6, 9, { height: '1.6%', width: '63.7%', top: '31.15%', left: '5.5%' }, 1)} />

                  <Interpolation name={'內插'} title={'B: 手臂舉起、雙手低於肩部或與身體保持一段距離'} content={'(單位: 在部分活動中保持該姿勢的時間百分比1~100%)'}
                      In_function={(p) => {
                        if(p>100){
                          p=100
                        }else if (p<0){
                          p=0
                        }
                        let result = 0.24*p
                        dispatch(setValue_Normal({ block: 'forty-nine', value: p}))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'nine'} bblock={9} position={{ height: '0.8%', width: '3%', top: '31.2%', left: '15.8%' }} page={1} />


                  {/* ten */}
                  <Cell height={'3.1%'} width={'82%'} top={'32.75%'} left={'5.5%'} onClick={() => changeValue('ten', 28, 10, { height: '3.1%', width: '82%', top: '32.75%', left: '5.5%' }, 1)} />
                  <Cell height={'3.1%'} width={'76%'} top={'32.75%'} left={'5.5%'} onClick={() => changeValue('ten', 21, 10, { height: '3.1%', width: '76%', top: '32.75%', left: '5.5%' }, 1)} />
                  <Cell height={'3.1%'} width={'69.8%'} top={'32.75%'} left={'5.5%'} onClick={() => changeValue('ten', 14, 10, { height: '3.1%', width: '69.8%', top: '32.75%', left: '5.5%' }, 1)} />
                  <Cell height={'3.1%'} width={'63.7%'} top={'32.75%'} left={'5.5%'} onClick={() => changeValue('ten', 7, 10, { height: '3.1%', width: '63.7%', top: '32.75%', left: '5.5%' }, 1)} />

                  <Interpolation name={'內插'} title={'B: 仰臥，雙臂舉過頭/俯臥，手臂在身體前方或下方'} content={'(單位: 在部分活動中保持該姿勢的時間百分比1~100%)'}
                      In_function={(p) => {
                        if(p>100){
                          p=100
                        }else if (p<0){
                          p=0
                        }
                        let result = 0.28*p
                        dispatch(setValue_Normal({ block: 'fifty', value: p}))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'ten'} bblock={10} position={{ height: '0.8%', width: '3%', top: '33%', left: '15.8%' }} page={1} />

                  {/* eleven */}
                  <Cell height={'0.85%'} width={'82%'} top={'35.85%'} left={'5.5%'} onClick={() => changeValue('eleven', 0, 11, { height: '0.85%', width: '82%', top: '35.85%', left: '5.5%' }, 1)} />
                  <Cell height={'0.85%'} width={'76%'} top={'35.85%'} left={'5.5%'} onClick={() => changeValue('eleven', 0, 11, { height: '0.85%', width: '76%', top: '35.85%', left: '5.5%' }, 1)} />
                  <Cell height={'0.85%'} width={'69.8%'} top={'35.85%'} left={'5.5%'} onClick={() => changeValue('eleven', 0, 11, { height: '0.85%', width: '69.8%', top: '35.85%', left: '5.5%' }, 1)} />
                  <Cell height={'0.85%'} width={'63.7%'} top={'35.85%'} left={'5.5%'} onClick={() => changeValue('eleven', 0, 11, { height: '0.85%', width: '63.7%', top: '35.85%', left: '5.5%' }, 1)} />

                  <Interpolation name={'內插'} title={'B: 沒有肩部/手臂姿勢負荷的部分評估期'} content={'(單位: 在部分活動中保持該姿勢的時間百分比1~100%)'}
                      In_function={(p) => {
                        if(p>100){
                          p=100
                        }else if (p<0){
                          p=0
                        }
                        let result = 0
                        dispatch(setValue_Normal({ block: 'fifty-one', value: p}))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'eleven'} bblock={11} position={{ height: '0.8%', width: '3%', top: '35.6%', left: '15.8%' }} page={1} />


                  {/* C */}
                  {/* 倒的 */}
                  {/* twelve */}
                  <Cell height={'1.8%'} width={'82%'} top={'40%'} left={'5.5%'} onClick={() => changeValue('twelve', 8, 12, { height: '1.8%', width: '82%', top: '40%', left: '5.5%' }, 1)} />
                  <Cell height={'1.8%'} width={'76%'} top={'40%'} left={'5.5%'} onClick={() => changeValue('twelve', 6, 12, { height: '1.8%', width: '76%', top: '40%', left: '5.5%' }, 1)} />
                  <Cell height={'1.8%'} width={'69.8%'} top={'40%'} left={'5.5%'} onClick={() => changeValue('twelve', 4, 12, { height: '1.8%', width: '69.8%', top: '40%', left: '5.5%' }, 1)} />
                  <Cell height={'1.8%'} width={'63.8%'} top={'40%'} left={'5.5%'} onClick={() => changeValue('twelve', 2, 12, { height: '1.8%', width: '63.8%', top: '40%', left: '5.5%' }, 1)} />

                  <Interpolation name={'內插'} title={'C: 持續地站立'} content={'(單位: 在部分活動中保持該姿勢的時間百分比1~100%)'}
                      In_function={(p) => {
                        if(p>100){
                          p=100
                        }else if (p<0){
                          p=0
                        }
                        let result = 0.08*p
                        dispatch(setValue_Normal({ block: 'fifty-two', value: p}))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'twelve'} bblock={12} position={{ height: '0.8%', width: '3%', top: '40%', left: '15.8%' }} page={1} />


                  {/* thirteen */}
                  <Cell height={'2.5%'} width={'82%'} top={'41.8%'} left={'5.5%'} onClick={() => changeValue('thirteen', 40, 13, { height: '2.5%', width: '82%', top: '41.8%', left: '5.5%' }, 1)} />
                  <Cell height={'2.5%'} width={'76%'} top={'41.8%'} left={'5.5%'} onClick={() => changeValue('thirteen', 30, 13, { height: '2.5%', width: '76%', top: '41.8%', left: '5.5%' }, 1)} />
                  <Cell height={'2.5%'} width={'69.8%'} top={'41.8%'} left={'5.5%'} onClick={() => changeValue('thirteen', 20, 13, { height: '2.5%', width: '69.8%', top: '41.8%', left: '5.5%' }, 1)} />
                  <Cell height={'2.5%'} width={'63.8%'} top={'41.8%'} left={'5.5%'} onClick={() => changeValue('thirteen', 10, 13, { height: '2.5%', width: '63.8%', top: '41.8%', left: '5.5%' }, 1)} />

                  <Interpolation name={'內插'} title={'C: 蹲、跪或盤腿坐'} content={'(單位: 在部分活動中保持該姿勢的時間百分比1~100%)'}
                      In_function={(p) => {
                        if(p>100){
                          p=100
                        }else if (p<0){
                          p=0
                        }
                        let result = 0.4*p
                        dispatch(setValue_Normal({ block: 'fifty-three', value: p}))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'thirteen'} bblock={13} position={{ height: '0.8%', width: '3%', top: '41.6%', left: '15.8%' }} page={1} />


                  {/* fourteen */}
                  <Cell height={'1.1%'} width={'82%'} top={'44.3%'} left={'5.5%'} onClick={() => changeValue('fourteen', 0, 14, { height: '1.1%', width: '82%', top: '44.3%', left: '5.5%' }, 1)} />
                  <Cell height={'1.1%'} width={'76%'} top={'44.3%'} left={'5.5%'} onClick={() => changeValue('fourteen', 0, 14, { height: '1.1%', width: '76%', top: '44.3%', left: '5.5%' }, 1)} />
                  <Cell height={'1.1%'} width={'69.8%'} top={'44.3%'} left={'5.5%'} onClick={() => changeValue('fourteen', 0, 14, { height: '1.1%', width: '69.8%', top: '44.3%', left: '5.5%' }, 1)} />
                  <Cell height={'1.1%'} width={'63.8%'} top={'44.3%'} left={'5.5%'} onClick={() => changeValue('fourteen', 0, 14, { height: '1.1%', width: '63.8%', top: '44.3%', left: '5.5%' }, 1)} />

                  <Interpolation name={'內插'} title={'C: 沒有膝部姿勢負荷的期間'} content={'(單位: 在部分活動中保持該姿勢的時間百分比1~100%)'}
                      In_function={(p) => {
                        if(p>100){
                          p=100
                        }else if (p<0){
                          p=0
                        }
                        let result = 0
                        dispatch(setValue_Normal({ block: 'fifty-four', value: p}))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'fourteen'} bblock={14} position={{ height: '0.8%', width: '3%', top: '44%', left: '15.8%' }} page={1} />

                </>
                <img
                  overflow='scroll'
                  width="100%"
                  src={`${KIM_ABP_1}`}
                />
              </Block>
              <Block id="Page-2">
                {okPosition[1].map((item, index) => (
                  <OkIcon height={item.height} width={item.width} top={item.top} left={item.left} />
                ))
                }

                <>
                  {/* 不利的工作條件*/}

                  {/* seventeen*/}
                  {/* C*/}
                  <Cell height={'0.85%'} width={'87.6%'} top={'55.8%'} left={'5.5%'} onClick={() => changeValue('seventeen', 0, 17, { height: '0.85%', width: '87.6%', top: '55.8%', left: '5.5%' }, 2)} />
                  <Cell height={'0.8%'} width={'87.6%'} top={'56.55%'} left={'5.5%'} onClick={() => changeValue('seventeen', 1, 17, { height: '0.8%', width: '87.6%', top: '56.55%', left: '5.5%' }, 2)} />

                  {/* sixteen*/}
                  {/* B*/}
                  <Cell height={'0.85%'} width={'79%'} top={'55.8%'} left={'5.5%'} onClick={() => changeValue('sixteen', 0, 16, { height: '0.85%', width: '79%', top: '55.8%', left: '5.5%' }, 2)} />
                  <Cell height={'0.8%'} width={'79%'} top={'56.55%'} left={'5.5%'} onClick={() => changeValue('sixteen', 0, 16, { height: '0.8%', width: '79%', top: '56.55%', left: '5.5%' }, 2)} />

                  {/* fifteen*/}
                  {/* A*/}
                  <Cell height={'0.85%'} width={'68%'} top={'55.8%'} left={'5.5%'} onClick={() => changeValue('fifteen', 1, 15, { height: '0.85%', width: '68%', top: '55.8%', left: '5.5%' }, 2)} />
                  <Cell height={'0.8%'} width={'68%'} top={'56.55%'} left={'5.5%'} onClick={() => changeValue('fifteen', 2, 15, { height: '0.8%', width: '68%', top: '56.55%', left: '5.5%' }, 2)} />


                  {/* twenty*/}
                  {/* C*/}
                  <Cell height={'1.1%'} width={'87.6%'} top={'57.35%'} left={'5.5%'} onClick={() => changeValue('twenty', 0, 20, { height: '1.1%', width: '87.6%', top: '57.35%', left: '5.5%' }, 2)} />
                  {/* nineteen*/}
                  {/* B*/}
                  <Cell height={'1.1%'} width={'79%'} top={'57.35%'} left={'5.5%'} onClick={() => changeValue('nineteen', 1, 19, { height: '1.1%', width: '79%', top: '57.35%', left: '5.5%' }, 2)} />
                  {/* eighteen*/}
                  {/* A*/}
                  <Cell height={'1.1%'} width={'68%'} top={'57.35%'} left={'5.5%'} onClick={() => changeValue('eighteen', 1, 18, { height: '1.1%', width: '68%', top: '57.35%', left: '5.5%' }, 2)} />

                  {/* twenty-three*/}
                  {/* C*/}
                  <Cell height={'1.6%'} width={'87.6%'} top={'58.45%'} left={'5.5%'} onClick={() => changeValue('twenty-three', 0, 23, { height: '1.6%', width: '87.6%', top: '58.45%', left: '5.5%' }, 2)} />
                  {/* twenty-two*/}
                  {/* B*/}
                  <Cell height={'1.6%'} width={'79%'} top={'58.45%'} left={'5.5%'} onClick={() => changeValue('twenty-two', 0, 22, { height: '1.6%', width: '79%', top: '58.45%', left: '5.5%' }, 2)} />
                  {/* twenty-one*/}
                  {/* A*/}
                  <Cell height={'1.6%'} width={'68%'} top={'58.45%'} left={'5.5%'} onClick={() => changeValue('twenty-one', 2, 21, { height: '1.6%', width: '68%', top: '58.45%', left: '5.5%' }, 2)} />


                  {/* twenty-six*/}
                  {/* C*/}
                  <Cell height={'0.85%'} width={'87.6%'} top={'60.05%'} left={'5.5%'} onClick={() => changeValue('twenty-six', 2, 26, { height: '0.85%', width: '87.6%', top: '60.05%', left: '5.5%' }, 2)} />
                  {/* twenty-five*/}
                  {/* B*/}
                  <Cell height={'0.85%'} width={'79%'} top={'60.05%'} left={'5.5%'} onClick={() => changeValue('twenty-five', 2, 25, { height: '0.85%', width: '79%', top: '60.05%', left: '5.5%' }, 2)} />
                  {/* twenty-four*/}
                  {/* A*/}
                  <Cell height={'0.85%'} width={'68%'} top={'60.05%'} left={'5.5%'} onClick={() => changeValue('twenty-four', 2, 24, { height: '0.85%', width: '68%', top: '60.05%', left: '5.5%' }, 2)} />



                  {/* 其他的工作條件*/}

                  {/* C*/}
                  {/* thirty-seven*/}
                  <Cell height={'0.9%'} width={'87.5%'} top={'64.1%'} left={'5.5%'} onClick={() => changeValue('thirty-seven', 1, 37, { height: '0.9%', width: '87.5%', top: '64.1%', left: '5.5%' }, 2)} />
                  {/* thirty-eight*/}
                  <Cell height={'0.9%'} width={'87.5%'} top={'65%'} left={'5.5%'} onClick={() => changeValue('thirty-eight', 0, 38, { height: '0.9%', width: '87.5%', top: '65%', left: '5.5%' }, 2)} />
                  {/* thirty-nine*/}
                  <Cell height={'0.9%'} width={'87.5%'} top={'65.9%'} left={'5.5%'} onClick={() => changeValue('thirty-nine', 0, 39, { height: '0.9%', width: '87.5%', top: '65.9%', left: '5.5%' }, 2)} />
                  {/* forty*/}
                  <Cell height={'0.85%'} width={'87.5%'} top={'66.8%'} left={'5.5%'} onClick={() => changeValue('forty', 0, 40, { height: '0.85%', width: '87.5%', top: '66.8%', left: '5.5%' }, 2)} />
                  {/* forty-one*/}
                  <Cell height={'1.3%'} width={'87.5%'} top={'68.7%'} left={'5.5%'} onClick={() => changeValue('forty-one', 0, 41, { height: '1.3%', width: '87.5%', top: '68.7%', left: '5.5%' }, 2)} />

                  {/* B*/}
                  {/* thirty-two*/}
                  <Cell height={'0.9%'} width={'80.2%'} top={'64.1%'} left={'5.5%'} onClick={() => changeValue('thirty-two', 1, 32, { height: '0.9%', width: '80.2%', top: '64.1%', left: '5.5%' }, 2)} />
                  {/* thirty-three*/}
                  <Cell height={'0.9%'} width={'80.2%'} top={'65%'} left={'5.5%'} onClick={() => changeValue('thirty-three', 1, 33, { height: '0.9%', width: '80.2%', top: '65%', left: '5.5%' }, 2)} />
                  {/* thirty-four*/}
                  <Cell height={'0.9%'} width={'80.2%'} top={'65.9%'} left={'5.5%'} onClick={() => changeValue('thirty-four', 1, 34, { height: '0.9%', width: '80.2%', top: '65.9%', left: '5.5%' }, 2)} />
                  {/* thirty-five*/}
                  <Cell height={'0.85%'} width={'80.2%'} top={'66.8%'} left={'5.5%'} onClick={() => changeValue('thirty-five', 1, 35, { height: '0.85%', width: '80.2%', top: '66.8%', left: '5.5%' }, 2)} />
                  {/* thirty-six*/}
                  <Cell height={'1.3%'} width={'80.2%'} top={'68.7%'} left={'5.5%'} onClick={() => changeValue('thirty-six', 0, 36, { height: '1.3%', width: '80.2%', top: '68.7%', left: '5.5%' }, 2)} />


                  {/* A*/}
                  {/* twenty-seven*/}
                  <Cell height={'0.9%'} width={'73.35%'} top={'64.1%'} left={'5.5%'} onClick={() => changeValue('twenty-seven', 1, 27, { height: '0.9%', width: '73.35%', top: '64.1%', left: '5.5%' }, 2)} />
                  {/* twenty-eight*/}
                  <Cell height={'0.9%'} width={'73.35%'} top={'65%'} left={'5.5%'} onClick={() => changeValue('twenty-eight', 1, 28, { height: '0.9%', width: '73.35%', top: '65%', left: '5.5%' }, 2)} />
                  {/* twenty-nine*/}
                  <Cell height={'0.9%'} width={'73.35%'} top={'65.9%'} left={'5.5%'} onClick={() => changeValue('twenty-nine', 1, 29, { height: '0.9%', width: '73.35%', top: '65.9%', left: '5.5%' }, 2)} />
                  {/* thirty*/}
                  <Cell height={'0.85%'} width={'73.35%'} top={'66.8%'} left={'5.5%'} onClick={() => changeValue('thirty', 1, 30, { height: '0.85%', width: '73.35%', top: '66.8%', left: '5.5%' }, 2)} />
                  {/* thirty-one*/}
                  <Cell height={'1.3%'} width={'73.35%'} top={'68.7%'} left={'5.5%'} onClick={() => changeValue('thirty-one', 0, 31, { height: '1.3%', width: '73.35%', top: '68.7%', left: '5.5%' }, 2)} />




                </>
                <img
                  overflow='scroll'
                  width="100%"
                  src={`${KIM_ABP_2}`}
                />

              </Block>
            </TransformComponent>
          </TransformWrapper>

          {/* Rating */}
          <Block height={'100vh'} id="Rating" >

            <Box
              sx={{
                border: '5px solid transparent',
                position: 'absolute',
                backgroundColor: totalRiskColor,
                //  ml: 'auto',
                //  mr: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                margin: 'auto',
                left: 0,
                right: 0,
                height: '100vh',
                //  width:'60vw',
                width: '100%',
              }}
            >
              {totalRisk >= 100 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px', textAlign: 'center' }} >風險值: 高 <br /> a.很可能超過身體負荷 <br />b.更明顯的疾病和/或功能障礙，具有病理意義的結構性損傷</Typography>
              }
              {totalRisk >= 50 && totalRisk < 100 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 中高<br /> a.對於一般耐受力的人，也有可能超過身體負荷 <br />b.疾病（疼痛），可能包括功能障礙，多數情況下可以恢復，沒有形態上的表現</Typography>
              }
              {totalRisk >= 20 && totalRisk < 50 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 中低<br /> a.對於耐受力較差的人，有可能超過身體負荷 <br />b.疲勞、低度適應問題，可於休息時間獲得恢復</Typography>
              }
              {totalRisk < 20 && totalRisk > 0 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 低<br /> a.不太可能會超過身體負荷 <br />b.預計不會有健康風險</Typography>
              }
              {totalRisk == 0 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值</Typography>
              }

              <Table>
                <Tr>
                  {KIM_ABP_Rating.map((item, index) =>

                  // <Th justify_content = 'center' font_weight='900'>{item}</Th>
                  {
                    if (index == 0 || index == 8) {
                      return (
                        <Th justify_content='center'
                          font_weight='900'>{item}</Th>
                      )
                    } else if (index == 4 || index == 6) {
                      return (
                        <Th
                          justify_content='center'
                          border_bottom='1px black solid'
                          font_weight='900'>{item}</Th>
                      )
                    } else if (index == 5 || index == 7) {
                      return (
                        <Th
                          justify_content='center'>{item}</Th>
                      )
                    } else {
                      return (
                        <Th justify_content='left'
                          border_bottom='1px gray solid'>{item}</Th>
                      )
                    }
                  }
                  )}
                </Tr>

                {total.map((item, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    {total[index].map((item, index) => {
                      if (index == 6 || index == 8) {
                        return (
                          <Td key={index} border_bottom='1px black solid'>{(Math.round(item * 100) / 100)}</Td>
                        )
                      } else if (index == 9 || index == 11 || index == 12) {
                        return (
                          <Td key={index} >{(Math.round(item * 100) / 100)}</Td>
                        )
                      } else if (index == 0 || index == 1 || index == 2) {
                        return (
                          <Td key={index} border_bottom='1px black solid'>
                            <div style={{ fontWeight: 900 }}>A</div>: {(Math.round(item[0] * 100) / 100)} &nbsp;&nbsp;
                            <div style={{ fontWeight: 900 }}>B</div>: {(Math.round(item[1] * 100) / 100)} &nbsp;&nbsp;
                            <div style={{ fontWeight: 900 }}>C</div>:{(Math.round(item[2] * 100) / 100)}</Td>
                        )
                      } else {
                        return (
                          <Td key={index} border_bottom='1px gray solid'>{(Math.round(item * 100) / 100)}</Td>
                        )
                      }
                    }
                    )
                    }
                  </Tr>
                ))}


              </Table>
            </Box>
          </Block>
        </Union>
      </div>
    );
  } else if (currentProjectsKIM === 'KIM-BF') {
    return (
      <div className={cssMain} style={styleMain}>
        <Union id="KIM">
          <ScollDownButton bottom={'100px'} onClick={() => scrollToDown1()} > &nbsp;P1&nbsp; </ScollDownButton>
          <ScollDownButton bottom={'50px'} onClick={() => scrollToDown2()} > &nbsp;P2&nbsp; </ScollDownButton>
          <ScollDownButton bottom={'0px'} onClick={() => scrollToDown3()} > Risk </ScollDownButton>

          <TransformWrapper doubleClick={{ disabled: true }} wheel={{ activationKeys: ["Alt"] }}>
            <TransformComponent>
              <Stack sx={{ position: 'absolute', padding: '10px', right: '50px' }} direction="row" spacing={1} alignItems="center">
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '900' }} >女</Typography>
                <AntSwitch
                  sx={{}}
                  checked={projects[currentProject - 1][currentSubProject - 1].sex === 'M' ? true : false}
                  onChange={(evt) => { return dispatch(setSex(evt.target.checked)), dispatch(setTotalCalcu()) }}
                  defaultChecked={projects[currentProject - 1][currentSubProject - 1].sex === 'M' ? true : false}
                  inputProps={{ 'aria-label': 'ant design' }} />
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '900' }}
                >男</Typography>
              </Stack>

              <Block id="Page-1">
                {okPosition[0].map((item, index) => (
                  <OkIcon height={item.height} width={item.width} top={item.top} left={item.left} />
                ))
                }
                <>
                  {/* one */}
                  <Interpolation name={'時間內插'} title={'工作時間'} content={'(單位:分鐘)'}
                    In_function={(n) => { return 0.56 + 0.44 * Math.pow((n), 0.5) }} re_function={(e) => { return (Math.pow((e - 0.56), 2)) * (1 / 0.1936) }}
                    block={'one'} bblock={1} position={{ height: '0.95%', width: '7%', top: '7.3%', left: '84.5%' }} page={1} />


                  <Cell height={'2.6%'} width={'5%'} top={'8.4%'} left={'27.3%'} onClick={() => changeValue('one', 1, 1, { height: '2.6%', width: '5%', top: '8.4%', left: '27.3%' }, 1)} />
                  <Cell height={'2.6%'} width={'5%'} top={'8.4%'} left={'32.3%'} onClick={() => changeValue('one', 1.5, 1, { height: '2.6%', width: '5%', top: '8.4%', left: '32.3%' }, 1)} />
                  <Cell height={'2.6%'} width={'4.9%'} top={'8.4%'} left={'37.3%'} onClick={() => changeValue('one', 2, 1, { height: '2.6%', width: '4.9%', top: '8.4%', left: '37.3%' }, 1)} />
                  <Cell height={'2.6%'} width={'4.8%'} top={'8.4%'} left={'42.2%'} onClick={() => changeValue('one', 2.5, 1, { height: '2.6%', width: '4.8%', top: '8.4%', left: '42.2%' }, 1)} />
                  <Cell height={'2.6%'} width={'4.8%'} top={'8.4%'} left={'47%'} onClick={() => changeValue('one', 3, 1, { height: '2.6%', width: '4.8%', top: '8.4%', left: '47%' }, 1)} />
                  <Cell height={'2.6%'} width={'4.9%'} top={'8.4%'} left={'51.8%'} onClick={() => changeValue('one', 3.5, 1, { height: '2.6%', width: '4.9%', top: '8.4%', left: '51.8%' }, 1)} />
                  <Cell height={'2.6%'} width={'4.9%'} top={'8.4%'} left={'56.7%'} onClick={() => changeValue('one', 4, 1, { height: '2.6%', width: '4.9%', top: '8.4%', left: '56.7%' }, 1)} />
                  <Cell height={'2.6%'} width={'5.3%'} top={'8.4%'} left={'61.6%'} onClick={() => changeValue('one', 5, 1, { height: '2.6%', width: '5.3%', top: '8.4%', left: '61.6%' }, 1)} />
                  <Cell height={'2.6%'} width={'5.4%'} top={'8.4%'} left={'66.9%'} onClick={() => changeValue('one', 6, 1, { height: '2.6%', width: '5.4%', top: '8.4%', left: '66.9%' }, 1)} />
                  <Cell height={'2.6%'} width={'5.4%'} top={'8.4%'} left={'72.3%'} onClick={() => changeValue('one', 7, 1, { height: '2.6%', width: '5.4%', top: '8.4%', left: '72.3%' }, 1)} />
                  <Cell height={'2.6%'} width={'5.4%'} top={'8.4%'} left={'77.7%'} onClick={() => changeValue('one', 8, 1, { height: '2.6%', width: '5.4%', top: '8.4%', left: '77.7%' }, 1)} />
                  <Cell height={'2.6%'} width={'5.4%'} top={'8.4%'} left={'83.1%'} onClick={() => changeValue('one', 9, 1, { height: '2.6%', width: '5.4%', top: '8.4%', left: '83.1%' }, 1)} />
                  <Cell height={'2.6%'} width={'5.5%'} top={'8.4%'} left={'88.5%'} onClick={() => changeValue('one', 10, 1, { height: '2.6%', width: '5.5%', top: '8.4%', left: '88.5%' }, 1)} />



                  {/* two */}

                  {/* 倒數 */}

                  <Cell height={'2.1%'} width={'80%'} top={'18.3%'} left={'14%'} onClick={() => { return changeValue('two', 18, 2, { height: '2.1%', width: '80%', top: '18.3%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 45 })) }} />
                  <Cell height={'2.65%'} width={'80%'} top={'20.4%'} left={'14%'} onClick={() => { return changeValue('two', 25, 2, { height: '2.65%', width: '80%', top: '20.4%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 45 })) }} />
                  <Cell height={'2.1%'} width={'80%'} top={'23.05%'} left={'14%'} onClick={() => { return changeValue('two', 100, 2, { height: '2.1%', width: '80%', top: '23.05%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 45 })) }} />
                  <Cell height={'2.7%'} width={'80%'} top={'25.15%'} left={'14%'} onClick={() => { return changeValue('two', 100, 2, { height: '2.7%', width: '80%', top: '25.15%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 45 })) }} />

                  <Cell height={'2.1%'} width={'75.7%'} top={'18.3%'} left={'14%'} onClick={() => { return changeValue('two', 12, 2, { height: '2.1%', width: '75.7%', top: '18.3%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 30 })) }} />
                  <Cell height={'2.65%'} width={'75.7%'} top={'20.4%'} left={'14%'} onClick={() => { return changeValue('two', 17, 2, { height: '2.65%', width: '75.7%', top: '20.4%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 30 })) }} />
                  <Cell height={'2.1%'} width={'75.7%'} top={'23.05%'} left={'14%'} onClick={() => { return changeValue('two', 32, 2, { height: '2.1%', width: '75.7%', top: '23.05%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 30 })) }} />
                  <Cell height={'2.7%'} width={'75.7%'} top={'25.15%'} left={'14%'} onClick={() => { return changeValue('two', 50, 2, { height: '2.7%', width: '75.7%', top: '25.15%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 30 })) }} />


                  <Cell height={'2.1%'} width={'71%'} top={'18.3%'} left={'14%'} onClick={() => { return changeValue('two', 6, 2, { height: '2.1%', width: '71%', top: '18.3%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 15 })) }} />
                  <Cell height={'2.65%'} width={'71%'} top={'20.4%'} left={'14%'} onClick={() => { return changeValue('two', 8, 2, { height: '2.65%', width: '71%', top: '20.4%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 15 })) }} />
                  <Cell height={'2.1%'} width={'71%'} top={'23.05%'} left={'14%'} onClick={() => { return changeValue('two', 15, 2, { height: '2.1%', width: '71%', top: '23.05%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 15 })) }} />
                  <Cell height={'2.7%'} width={'71%'} top={'25.15%'} left={'14%'} onClick={() => { return changeValue('two', 25, 2, { height: '2.7%', width: '71%', top: '25.15%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 15 })) }} />


                  <Cell height={'2.1%'} width={'66.2%'} top={'18.3%'} left={'14%'} onClick={() => { return changeValue('two', 1.5, 2, { height: '2.1%', width: '66.2%', top: '18.3%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 4 })) }} />
                  <Cell height={'2.65%'} width={'66.2%'} top={'20.4%'} left={'14%'} onClick={() => { return changeValue('two', 2, 2, { height: '2.65%', width: '66.2%', top: '20.4%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 4 })) }} />
                  <Cell height={'2.1%'} width={'66.2%'} top={'23.05%'} left={'14%'} onClick={() => { return changeValue('two', 4, 2, { height: '2.1%', width: '66.2%', top: '23.05%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 4 })) }} />
                  <Cell height={'2.7%'} width={'66.2%'} top={'25.15%'} left={'14%'} onClick={() => { return changeValue('two', 6, 2, { height: '2.7%', width: '66.2%', top: '25.15%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 4 })) }} />


                  <Cell height={'2.1%'} width={'61.8%'} top={'18.3%'} left={'14%'} onClick={() => { return changeValue('two', 6, 2, { height: '2.1%', width: '61.8%', top: '18.3%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 15 })) }} />
                  <Cell height={'2.65%'} width={'61.8%'} top={'20.4%'} left={'14%'} onClick={() => { return changeValue('two', 8, 2, { height: '2.65%', width: '61.8%', top: '20.4%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 15 })) }} />
                  <Cell height={'2.1%'} width={'61.8%'} top={'23.05%'} left={'14%'} onClick={() => { return changeValue('two', 15, 2, { height: '2.1%', width: '61.8%', top: '23.05%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 15 })) }} />
                  <Cell height={'2.7%'} width={'61.8%'} top={'25.15%'} left={'14%'} onClick={() => { return changeValue('two', 25, 2, { height: '2.7%', width: '61.8%', top: '25.15%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 15 })) }} />


                  <Cell height={'2.1%'} width={'57%'} top={'18.3%'} left={'14%'} onClick={() => { return changeValue('two', 12, 2, { height: '2.1%', width: '57%', top: '18.3%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 30 })) }} />
                  <Cell height={'2.65%'} width={'57%'} top={'20.4%'} left={'14%'} onClick={() => { return changeValue('two', 17, 2, { height: '2.65%', width: '57%', top: '20.4%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 30 })) }} />
                  <Cell height={'2.1%'} width={'57%'} top={'23.05%'} left={'14%'} onClick={() => { return changeValue('two', 32, 2, { height: '2.1%', width: '57%', top: '23.05%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 30 })) }} />


                  <Cell height={'2.1%'} width={'52.3%'} top={'18.3%'} left={'14%'} onClick={() => { return changeValue('two', 18, 2, { height: '2.1%', width: '52.3%', top: '18.3%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 45 })) }} />
                  <Cell height={'2.65%'} width={'52.3%'} top={'20.4%'} left={'14%'} onClick={() => { return changeValue('two', 25, 2, { height: '2.65%', width: '52.3%', top: '20.4%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 45 })) }} />
                  <Cell height={'2.1%'} width={'52.3%'} top={'23.05%'} left={'14%'} onClick={() => { return changeValue('two', 100, 2, { height: '2.1%', width: '52.3%', top: '23.05%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 45 })) }} />
                  <Cell height={'2.7%'} width={'57%'} top={'25.15%'} left={'14%'} onClick={() => { return changeValue('two', 100, 2, { height: '2.7%', width: '57%', top: '25.15%', left: '14%' }, 1), dispatch(setValue_Normal({ block: 'twelve', value: 45 })) }} />


                  {/* 握持 */}
                  <>
                    <Interpolation name={'內插'} title={'握持 中等施力'} content={'(單位: 秒/每分鐘)'}
                      In_function={(n) => {
                        let result = 17.27 * 1.39 * n / 60
                        dispatch(setValue_Normal({ block: 'twelve', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '18.3%', left: '58.2%' }} page={1} />


                    <Interpolation name={'內插'} title={'握持 高施力'} content={'(單位: 秒/每分鐘)'}
                      In_function={(n) => {
                        let result = 17.27 * 1.93 * n / 60
                        dispatch(setValue_Normal({ block: 'twelve', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '20.4%', left: '58.2%' }} page={1} />

                    <Interpolation name={'內插'} title={'握持 極高施力'} content={'(單位: 秒/每分鐘)'}
                      In_function={(n) => {
                        let result = 0

                        if (1 <= n && n <= 30) {
                          result = 17.27 * 3.73 * n / 60
                        } else if (n > 30) {
                          result = 100
                        }
                        dispatch(setValue_Normal({ block: 'twelve', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '23.05%', left: '58.2%' }} page={1} />

                    <Interpolation name={'內插'} title={'握持 峰值施力'} content={'(單位: 秒/每分鐘)'}
                      In_function={(n) => {
                        let result = 0

                        if (4 >= n) {
                          result = 17.27 * 3.73 * n / 60
                        } else if (4 < n && n <= 15) {
                          result = 17.27 * 5.18 * n / 60
                        } else if (n > 15) {
                          result = 100
                        }
                        dispatch(setValue_Normal({ block: 'twelve', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '25.15%', left: '58.2%' }} page={1} />
                  </>

                  {/* 移動 */}
                  <>

                    <Interpolation name={'內插'} title={'移動 中等施力'} content={'(單位: 次/每分鐘)'}
                      In_function={(n) => {
                        let result = 17.27 * 1.39 * n / 60
                        dispatch(setValue_Normal({ block: 'twelve', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '18.3%', left: '94.3%' }} page={1} />


                    <Interpolation name={'內插'} title={'移動 高施力'} content={'(單位: 次/每分鐘)'}
                      In_function={(n) => {
                        let result = 17.27 * 1.93 * n / 60
                        dispatch(setValue_Normal({ block: 'twelve', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '20.4%', left: '94.3%' }} page={1} />

                    <Interpolation name={'內插'} title={'移動 極高施力'} content={'(單位: 次/每分鐘)'}
                      In_function={(n) => {
                        let result = 0

                        if (1 <= n && n <= 30) {
                          result = 17.27 * 3.73 * n / 60
                        } else if (n > 30) {
                          result = 100
                        }
                        dispatch(setValue_Normal({ block: 'twelve', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '23.05%', left: '94.3%' }} page={1} />

                    <Interpolation name={'內插'} title={'移動 峰值施力'} content={'(單位: 次/每分鐘)'}
                      In_function={(n) => {
                        let result = 0

                        if (4 >= n) {
                          result = 17.27 * 3.73 * n / 60
                        } else if (4 < n && n <= 15) {
                          result = 17.27 * 5.18 * n / 60
                        } else if (15 < n && n <= 30) {
                          result = 50
                        } else if (n > 30) {
                          result = 100
                        }
                        dispatch(setValue_Normal({ block: 'twelve', value: n }))
                        return result
                      }}
                      re_function={(e) => {
                        return e
                      }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '3%', top: '25.15%', left: '94.3%' }} page={1} />

                  </>


                  {/* three */}

                  <Interpolation name={'對稱內插'} title={'施力對稱'} content={'(範圍:0~4)'}
                    In_function={(n) => { return parseFloat(n) }} re_function={(e) => { return parseFloat(e) }}
                    block={'three'} bblock={3} position={{ height: '0.95%', width: '7%', top: '31.8%', left: '77.8%' }} page={1} />


                  <Cell height={'0.7%'} width={'88.4%'} top={'32.7%'} left={'5.5%'} onClick={() => changeValue('three', 0, 3, { height: '0.7%', width: '88.4%', top: '32.7%', left: '5.5%' }, 1)} />
                  <Cell height={'0.7%'} width={'88.4%'} top={'33.4%'} left={'5.5%'} onClick={() => changeValue('three', 2, 3, { height: '0.7%', width: '88.4%', top: '33.4%', left: '5.5%' }, 1)} />
                  <Cell height={'0.8%'} width={'88.4%'} top={'34.1%'} left={'5.5%'} onClick={() => changeValue('three', 4, 3, { height: '0.8%', width: '88.4%', top: '34.1%', left: '5.5%' }, 1)} />


                  {/* four */}
                  <Interpolation name={'姿勢內插'} title={'身體姿勢評級'} content={'(範圍:0~9)'}
                    In_function={(n) => { return parseFloat(n) }} re_function={(e) => { return parseFloat(e) }}
                    block={'four'} bblock={4} position={{ height: '0.95%', width: '7%', top: '35.3%', left: '77.8%' }} page={1} />


                  <Cell height={'2.1%'} width={'88.45%'} top={'36%'} left={'5.5%'} onClick={() => changeValue('four', 0, 4, { height: '2.1%', width: '88.45%', top: '36%', left: '5.5%' }, 1)} />
                  <Cell height={'2.1%'} width={'88.45%'} top={'38.1%'} left={'5.5%'} onClick={() => changeValue('four', 3, 4, { height: '2.1%', width: '88.45%', top: '38.1%', left: '5.5%' }, 1)} />
                  <Cell height={'2.9%'} width={'88.45%'} top={'40.2%'} left={'5.5%'} onClick={() => changeValue('four', 6, 4, { height: '2.9%', width: '88.45%', top: '40.2%', left: '5.5%' }, 1)} />
                  <Cell height={'2.7%'} width={'88.45%'} top={'43.1%'} left={'5.5%'} onClick={() => changeValue('four', 9, 4, { height: '2.7%', width: '88.45%', top: '43.1%', left: '5.5%' }, 1)} />







                </>
                <img
                  overflow='scroll'
                  width="100%"
                  src={`${KIM_BF_1}`}
                />
              </Block>
              <Block id="Page-2">
                {okPosition[1].map((item, index) => (
                  <OkIcon height={item.height} width={item.width} top={item.top} left={item.left} />
                ))
                }

                <>

                  {/* five */}

                  <Interpolation name={'條件內插'} title={'不利的工作條件'} content={'(範圍:0~10)'}
                    In_function={(n) => { return parseFloat(n) }} re_function={(e) => { return parseFloat(e) }}
                    block={'five'} bblock={5} position={{ height: '0.95%', width: '7%', top: '52.3%', left: '78.8%' }} page={2} />

                  <Cell height={'0.9%'} width={'84.3%'} top={'54.9%'} left={'5.5%'} onClick={() => changeValue('five', 1, 5, { height: '0.9%', width: '84.3%', top: '54.9%', left: '5.5%' }, 2)} />
                  <Cell height={'1.2%'} width={'84.3%'} top={'55.8%'} left={'5.5%'} onClick={() => changeValue('five', 2, 5, { height: '1.2%', width: '84.3%', top: '55.8%', left: '5.5%' }, 2)} />

                  {/* six */}
                  <Cell height={'1.7%'} width={'84.3%'} top={'57%'} left={'5.5%'} onClick={() => changeValue('six', 1, 6, { height: '1.7%', width: '84.3%', top: '57%', left: '5.5%' }, 2)} />
                  <Cell height={'1.6%'} width={'84.3%'} top={'58.7%'} left={'5.5%'} onClick={() => changeValue('six', 2, 6, { height: '1.6%', width: '84.3%', top: '58.7%', left: '5.5%' }, 2)} />

                  {/* seven */}
                  <Cell height={'0.8%'} width={'84.3%'} top={'60.3%'} left={'5.5%'} onClick={() => changeValue('seven', 1, 7, { height: '0.8%', width: '84.3%', top: '60.3%', left: '5.5%' }, 2)} />
                  <Cell height={'0.8%'} width={'84.3%'} top={'61.1%'} left={'5.5%'} onClick={() => changeValue('seven', 2, 7, { height: '0.8%', width: '84.3%', top: '61.1%', left: '5.5%' }, 2)} />

                  {/* eight */}
                  <Cell height={'1.7%'} width={'84.3%'} top={'61.9%'} left={'5.5%'} onClick={() => changeValue('eight', 1, 8, { height: '1.7%', width: '84.3%', top: '61.9%', left: '5.5%' }, 2)} />
                  <Cell height={'1.6%'} width={'84.3%'} top={'63.6%'} left={'5.5%'} onClick={() => changeValue('eight', 2, 8, { height: '1.6%', width: '84.3%', top: '63.6%', left: '5.5%' }, 2)} />

                  {/* nine */}
                  <Cell height={'1.5%'} width={'84.3%'} top={'65.2%'} left={'5.5%'} onClick={() => changeValue('nine', 2, 9, { height: '1.5%', width: '84.3%', top: '65.2%', left: '5.5%' }, 2)} />

                  {/* ten */}
                  <Cell height={'1%'} width={'84.3%'} top={'66.7%'} left={'5.5%'} onClick={() => changeValue('ten', 0, 10, { height: '1%', width: '84.3%', top: '66.7%', left: '5.5%' }, 2)} />





                  {/* eleven */}

                  <Interpolation name={'組織內插'} title={'時間分配評級'} content={'(範圍:0~4)'}
                    In_function={(n) => { return parseFloat(n) }} re_function={(e) => { return parseFloat(e) }}
                    block={'eleven'} bblock={11} position={{ height: '0.95%', width: '7%', top: '68.5%', left: '78.8%' }} page={2} />

                  <Cell height={'1.5%'} width={'88.65%'} top={'70.5%'} left={'5.5%'} onClick={() => changeValue('eleven', 0, 11, { height: '1.5%', width: '88.65%', top: '70.5%', left: '5.5%' }, 2)} />
                  <Cell height={'1.4%'} width={'88.65%'} top={'72%'} left={'5.5%'} onClick={() => changeValue('eleven', 2, 11, { height: '1.4%', width: '88.65%', top: '72%', left: '5.5%' }, 2)} />
                  <Cell height={'1.4%'} width={'88.55%'} top={'73.4%'} left={'5.5%'} onClick={() => changeValue('eleven', 4, 11, { height: '1.4%', width: '88.55%', top: '73.4%', left: '5.5%' }, 2)} />



                </>
                <img
                  overflow='scroll'
                  width="100%"
                  src={`${KIM_BF_2}`}
                />

              </Block>
            </TransformComponent>
          </TransformWrapper>

          {/* Rating */}
          <Block height={'100vh'} id="Rating" >

            <Box
              sx={{
                border: '5px solid transparent',
                position: 'absolute',
                backgroundColor: totalRiskColor,
                //  ml: 'auto',
                //  mr: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                margin: 'auto',
                left: 0,
                right: 0,
                height: '100vh',
                //  width:'60vw',
                width: '100%',
              }}
            >
              {totalRisk >= 100 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 高 <br /> a.很可能超過身體負荷 <br />b.更明顯的疾病和/或功能障礙，具有病理意義的結構性損傷</Typography>
              }
              {totalRisk >= 50 && totalRisk < 100 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 中高<br /> a.對於一般耐受力的人，也有可能超過身體負荷 <br />b.疾病（疼痛），可能包括功能障礙，多數情況下可以恢復，沒有形態上的表現</Typography>
              }
              {totalRisk >= 20 && totalRisk < 50 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 中低<br /> a.對於耐受力較差的人，有可能超過身體負荷 <br />b.疲勞、低度適應問題，可於休息時間獲得恢復</Typography>
              }
              {totalRisk < 20 && totalRisk > 0 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 低<br /> a.不太可能會超過身體負荷 <br />b.預計不會有健康風險</Typography>
              }
              {totalRisk == 0 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值</Typography>
              }

              <Table>
                <Tr>
                  {KIM_BF_Rating.map((item, index) =>

                  // <Th justify_content = 'center' font_weight='900'>{item}</Th>
                  {
                    if (index == 0 || index == 10) {
                      return (
                        <Th justify_content='center'
                          font_weight='900'>{item}</Th>
                      )
                    } else if (index == 6 || index == 8) {
                      return (
                        <Th
                          justify_content='center'
                          border_bottom='1px black solid'
                          font_weight='900'>{item}</Th>
                      )
                    } else if (index == 7 || index == 9) {
                      return (
                        <Th
                          justify_content='center'>{item}</Th>
                      )
                    } else {
                      return (
                        <Th justify_content='left'
                          border_bottom='1px gray solid'>{item}</Th>
                      )
                    }
                  }
                  )}
                </Tr>


                {total.map((item, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    {total[index].map((item, index) => {
                      if (index == 8 || index == 10) {
                        return (
                          <Td key={index} border_bottom='1px black solid'>{(Math.round(item * 100) / 100)}</Td>
                        )
                      } else if (index == 9 || index == 11 || index == 12) {
                        return (
                          <Td key={index} >{(Math.round(item * 100) / 100)}</Td>
                        )
                      } else {
                        return (
                          <Td key={index} border_bottom='1px gray solid'>{(Math.round(item * 100) / 100)}</Td>
                        )
                      }
                    }
                    )
                    }
                  </Tr>
                ))}


              </Table>
            </Box>
          </Block>
        </Union>
      </div>
    );
  } else if (currentProjectsKIM === 'KIM-BM') {
    return (
      <div className={cssMain} style={styleMain}>
        <Union id="KIM">
          <ScollDownButton bottom={'100px'} onClick={() => scrollToDown1()} > &nbsp;P1&nbsp; </ScollDownButton>
          <ScollDownButton bottom={'50px'} onClick={() => scrollToDown2()} > &nbsp;P2&nbsp; </ScollDownButton>
          <ScollDownButton bottom={'0px'} onClick={() => scrollToDown3()} > Risk </ScollDownButton>

          <TransformWrapper doubleClick={{ disabled: true }} wheel={{ activationKeys: ["Alt"] }}>
            <TransformComponent>
              <Stack sx={{ position: 'absolute', padding: '10px', right: '50px' }} direction="row" spacing={1} alignItems="center">
                <Typography>女</Typography>
                <AntSwitch
                  checked={projects[currentProject - 1][currentSubProject - 1].sex === 'M' ? true : false}
                  onChange={(evt) => { return dispatch(setSex(evt.target.checked)), dispatch(setTotalCalcu()) }}
                  defaultChecked={projects[currentProject - 1][currentSubProject - 1].sex === 'M' ? true : false}
                  inputProps={{ 'aria-label': 'ant design' }} />
                <Typography>男</Typography>
              </Stack>

              <Block id="Page-1">
                {okPosition[0].map((item, index) => (
                  <OkIcon height={item.height} width={item.width} top={item.top} left={item.left} />
                ))
                }


                <>

                  {currentProjectsKIMBM === 'A' ?
                    <>
                      <KIMBM_AB_active top={'0.7%'}>A: 沒有設備輔助的身體運動</KIMBM_AB_active>
                      <KIMBM_AB top={'1.7%'} onClick={() => { return dispatch(setCurrentProjectsKIMBM('B')), dispatch(setTotalCalcu()) }}> B: 以肌肉力量驅動的身體運動</KIMBM_AB>
                    </> : <>
                      <KIMBM_AB top={'0.7%'} onClick={() => { return dispatch(setCurrentProjectsKIMBM('A')), dispatch(setTotalCalcu()) }}>A: 沒有設備輔助的身體運動</KIMBM_AB>
                      <KIMBM_AB_active top={'1.7%'} > B: 以肌肉力量驅動的身體運動</KIMBM_AB_active>
                    </>}


                  {/* B: 以肌肉力量驅動的身體運動 */}

                  <Interpolation name={'時間內插'} title={'工作時間'} content={'(單位:分鐘)'}
                    In_function={(n) => { return 0.56 + 0.44 * Math.pow((n), 0.5) }} re_function={(e) => { return (Math.pow((e - 0.56), 2)) * (1 / 0.1936) }}
                    block={'one'} bblock={1} position={{ height: '0.95%', width: '7%', top: '7.4%', left: '82.5%' }} page={1} />


                  {/* one */}
                  <Cell height={'2.4%'} width={'5.2%'} top={'8.6%'} left={'27.2%'} onClick={() => changeValue('one', 1, 1, { height: '2.4%', width: '5.2%', top: '8.6%', left: '27.2%' }, 1)} />
                  <Cell height={'2.4%'} width={'5.2%'} top={'8.6%'} left={'32.4%'} onClick={() => changeValue('one', 1.5, 1, { height: '2.4%', width: '5.2%', top: '8.6%', left: '32.4%' }, 1)} />
                  <Cell height={'2.4%'} width={'5.1%'} top={'8.6%'} left={'37.6%'} onClick={() => changeValue('one', 2, 1, { height: '2.4%', width: '5.1%', top: '8.6%', left: '37.6%' }, 1)} />
                  <Cell height={'2.4%'} width={'5.1%'} top={'8.6%'} left={'42.7%'} onClick={() => changeValue('one', 2.5, 1, { height: '2.4%', width: '5.1%', top: '8.6%', left: '42.7%' }, 1)} />
                  <Cell height={'2.4%'} width={'5.2%'} top={'8.6%'} left={'47.8%'} onClick={() => changeValue('one', 3, 1, { height: '2.4%', width: '5.2%', top: '8.6%', left: '47.8%' }, 1)} />
                  <Cell height={'2.4%'} width={'5.2%'} top={'8.6%'} left={'53%'} onClick={() => changeValue('one', 3.5, 1, { height: '2.4%', width: '5.2%', top: '8.6%', left: '53%' }, 1)} />
                  <Cell height={'2.4%'} width={'5.2%'} top={'8.6%'} left={'58.2%'} onClick={() => changeValue('one', 4, 1, { height: '2.4%', width: '5.2%', top: '8.6%', left: '58.2%' }, 1)} />
                  <Cell height={'2.4%'} width={'5.1%'} top={'8.6%'} left={'63.4%'} onClick={() => changeValue('one', 5, 1, { height: '2.4%', width: '5.1%', top: '8.6%', left: '63.4%' }, 1)} />
                  <Cell height={'2.4%'} width={'5.2%'} top={'8.6%'} left={'68.5%'} onClick={() => changeValue('one', 6, 1, { height: '2.4%', width: '5.2%', top: '8.6%', left: '68.5%' }, 1)} />
                  <Cell height={'2.4%'} width={'5.2%'} top={'8.6%'} left={'73.7%'} onClick={() => changeValue('one', 7, 1, { height: '2.4%', width: '5.2%', top: '8.6%', left: '73.7%' }, 1)} />
                  <Cell height={'2.4%'} width={'5.1%'} top={'8.6%'} left={'78.8%'} onClick={() => changeValue('one', 8, 1, { height: '2.4%', width: '5.1%', top: '8.6%', left: '78.8%' }, 1)} />
                  <Cell height={'2.4%'} width={'5.1%'} top={'8.6%'} left={'83.9%'} onClick={() => changeValue('one', 9, 1, { height: '2.4%', width: '5.1%', top: '8.6%', left: '83.9%' }, 1)} />
                  <Cell height={'2.4%'} width={'5.2%'} top={'8.6%'} left={'89%'} onClick={() => changeValue('one', 10, 1, { height: '2.4%', width: '5.2%', top: '8.6%', left: '89%' }, 1)} />


                  {currentProjectsKIMBM === 'A' ?
                    <>{/* A */}

                      <div style={{
                        width: '5%',
                        position: 'absolute',
                        top: '12.3%',
                        left: '5.3%',
                        height: '0.7%',
                        backgroundColor: 'rgba(255, 251, 135,1)',
                        mixBlendMode: 'multiply'
                      }}> </div>
                      {/* two */}
                      {/* 倒著放 */}
                      <Cell height={'13%'} width={'4.8%'} top={'15.3%'} left={'89.3%'} onClick={() => changeValue('two', 100, 2, { height: '13%', width: '4.8%', top: '15.3%', left: '89.3%' }, 1)} />

                      <Cell height={'0.75%'} width={'62.4%'} top={'15.3%'} left={'26.9%'} onClick={() => changeValue('two', 35, 2, { height: '0.75%', width: '62.4%', top: '15.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'62.4%'} top={'16.05%'} left={'26.9%'} onClick={() => changeValue('two', 40, 2, { height: '0.7%', width: '62.4%', top: '16.05%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'62.4%'} top={'16.75%'} left={'26.9%'} onClick={() => changeValue('two', 50, 2, { height: '0.7%', width: '62.4%', top: '16.75%', left: '26.9%' }, 1)} />
                      <Cell height={'0.85%'} width={'62.4%'} top={'17.45%'} left={'26.9%'} onClick={() => changeValue('two', 50, 2, { height: '0.85%', width: '62.4%', top: '17.45%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'62.4%'} top={'18.3%'} left={'26.9%'} onClick={() => changeValue('two', 50, 2, { height: '0.9%', width: '62.4%', top: '18.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'62.4%'} top={'19.2%'} left={'26.9%'} onClick={() => changeValue('two', 50, 2, { height: '0.9%', width: '62.4%', top: '19.2%', left: '26.9%' }, 1)} />

                      <Cell height={'0.75%'} width={'57.6%'} top={'15.3%'} left={'26.9%'} onClick={() => changeValue('two', 25, 2, { height: '0.75%', width: '57.6%', top: '15.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'57.6%'} top={'16.05%'} left={'26.9%'} onClick={() => changeValue('two', 30, 2, { height: '0.7%', width: '57.6%', top: '16.05%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'57.6%'} top={'16.75%'} left={'26.9%'} onClick={() => changeValue('two', 35, 2, { height: '0.7%', width: '57.6%', top: '16.75%', left: '26.9%' }, 1)} />
                      <Cell height={'0.85%'} width={'57.6%'} top={'17.45%'} left={'26.9%'} onClick={() => changeValue('two', 35, 2, { height: '0.85%', width: '57.6%', top: '17.45%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'57.6%'} top={'18.3%'} left={'26.9%'} onClick={() => changeValue('two', 35, 2, { height: '0.9%', width: '57.6%', top: '18.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'57.6%'} top={'19.2%'} left={'26.9%'} onClick={() => changeValue('two', 40, 2, { height: '0.9%', width: '57.6%', top: '19.2%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'62.4%'} top={'20.1%'} left={'26.9%'} onClick={() => changeValue('two', 100, 2, { height: '0.7%', width: '62.4%', top: '20.1%', left: '26.9%' }, 1)} />

                      <Cell height={'0.75%'} width={'52.8%'} top={'15.3%'} left={'26.9%'} onClick={() => changeValue('two', 14, 2, { height: '0.75%', width: '52.8%', top: '15.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'52.8%'} top={'16.05%'} left={'26.9%'} onClick={() => changeValue('two', 18, 2, { height: '0.7%', width: '52.8%', top: '16.05%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'52.8%'} top={'16.75%'} left={'26.9%'} onClick={() => changeValue('two', 22, 2, { height: '0.7%', width: '52.8%', top: '16.75%', left: '26.9%' }, 1)} />
                      <Cell height={'0.85%'} width={'52.8%'} top={'17.45%'} left={'26.9%'} onClick={() => changeValue('two', 20, 2, { height: '0.85%', width: '52.8%', top: '17.45%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'52.8%'} top={'18.3%'} left={'26.9%'} onClick={() => changeValue('two', 22, 2, { height: '0.9%', width: '52.8%', top: '18.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'52.8%'} top={'19.2%'} left={'26.9%'} onClick={() => changeValue('two', 34, 2, { height: '0.9%', width: '52.8%', top: '19.2%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'52.8%'} top={'20.1%'} left={'26.9%'} onClick={() => changeValue('two', 50, 2, { height: '0.7%', width: '52.8%', top: '20.1%', left: '26.9%' }, 1)} />
                      <Cell height={'0.85%'} width={'62.5%'} top={'20.8%'} left={'26.9%'} onClick={() => changeValue('two', 100, 2, { height: '0.85%', width: '62.5%', top: '20.8%', left: '26.9%' }, 1)} />



                      <Cell height={'0.75%'} width={'48%'} top={'15.3%'} left={'26.9%'} onClick={() => changeValue('two', 12, 2, { height: '0.75%', width: '48%', top: '15.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'48%'} top={'16.05%'} left={'26.9%'} onClick={() => changeValue('two', 16, 2, { height: '0.7%', width: '48%', top: '16.05%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'48%'} top={'16.75%'} left={'26.9%'} onClick={() => changeValue('two', 20, 2, { height: '0.7%', width: '48%', top: '16.75%', left: '26.9%' }, 1)} />
                      <Cell height={'0.85%'} width={'48%'} top={'17.45%'} left={'26.9%'} onClick={() => changeValue('two', 18, 2, { height: '0.85%', width: '48%', top: '17.45%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'48%'} top={'18.3%'} left={'26.9%'} onClick={() => changeValue('two', 20, 2, { height: '0.9%', width: '48%', top: '18.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'48%'} top={'19.2%'} left={'26.9%'} onClick={() => changeValue('two', 32, 2, { height: '0.9%', width: '48%', top: '19.2%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'48%'} top={'20.1%'} left={'26.9%'} onClick={() => changeValue('two', 26, 2, { height: '0.7%', width: '48%', top: '20.1%', left: '26.9%' }, 1)} />
                      <Cell height={'0.85%'} width={'48%'} top={'20.8%'} left={'26.9%'} onClick={() => changeValue('two', 50, 2, { height: '0.85%', width: '48%', top: '20.8%', left: '26.9%' }, 1)} />
                      <Cell height={'0.8%'} width={'62.5%'} top={'21.65%'} left={'26.9%'} onClick={() => changeValue('two', 100, 2, { height: '0.8%', width: '62.5%', top: '21.65%', left: '26.9%' }, 1)} />


                      <Cell height={'0.75%'} width={'43.2%'} top={'15.3%'} left={'26.9%'} onClick={() => changeValue('two', 10, 2, { height: '0.75%', width: '43.2%', top: '15.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'43.2%'} top={'16.05%'} left={'26.9%'} onClick={() => changeValue('two', 14, 2, { height: '0.7%', width: '43.2%', top: '16.05%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'43.2%'} top={'16.75%'} left={'26.9%'} onClick={() => changeValue('two', 18, 2, { height: '0.7%', width: '43.2%', top: '16.75%', left: '26.9%' }, 1)} />
                      <Cell height={'0.85%'} width={'43.2%'} top={'17.45%'} left={'26.9%'} onClick={() => changeValue('two', 16, 2, { height: '0.85%', width: '43.2%', top: '17.45%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'43.2%'} top={'18.3%'} left={'26.9%'} onClick={() => changeValue('two', 18, 2, { height: '0.9%', width: '43.2%', top: '18.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'43.2%'} top={'19.2%'} left={'26.9%'} onClick={() => changeValue('two', 30, 2, { height: '0.9%', width: '43.2%', top: '19.2%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'43.2%'} top={'20.1%'} left={'26.9%'} onClick={() => changeValue('two', 24, 2, { height: '0.7%', width: '43.2%', top: '20.1%', left: '26.9%' }, 1)} />
                      <Cell height={'0.85%'} width={'43.2%'} top={'20.8%'} left={'26.9%'} onClick={() => changeValue('two', 30, 2, { height: '0.85%', width: '43.2%', top: '20.8%', left: '26.9%' }, 1)} />
                      <Cell height={'0.8%'} width={'43.2%'} top={'21.65%'} left={'26.9%'} onClick={() => changeValue('two', 50, 2, { height: '0.8%', width: '43.2%', top: '21.65%', left: '26.9%' }, 1)} />
                      <Cell height={'1.5%'} width={'24.1%'} top={'22.4%'} left={'65.2%'} onClick={() => changeValue('two', 100, 2, { height: '1.5%', width: '24.1%', top: '22.4%', left: '65.2%' }, 1)} />
                      <Cell height={'2.2%'} width={'24.1%'} top={'23.9%'} left={'65.2%'} onClick={() => changeValue('two', 100, 2, { height: '2.2%', width: '24.1%', top: '23.9%', left: '65.2%' }, 1)} />
                      <Cell height={'2.2%'} width={'24.1%'} top={'26.1%'} left={'65.2%'} onClick={() => changeValue('two', 100, 2, { height: '2.2%', width: '24.1%', top: '26.1%', left: '65.2%' }, 1)} />

                      <Cell height={'0.75%'} width={'38.6%'} top={'15.3%'} left={'26.9%'} onClick={() => changeValue('two', 8, 2, { height: '0.75%', width: '38.6%', top: '15.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'38.6%'} top={'16.05%'} left={'26.9%'} onClick={() => changeValue('two', 12, 2, { height: '0.7%', width: '38.6%', top: '16.05%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'38.6%'} top={'16.75%'} left={'26.9%'} onClick={() => changeValue('two', 16, 2, { height: '0.7%', width: '38.6%', top: '16.75%', left: '26.9%' }, 1)} />
                      <Cell height={'0.85%'} width={'38.6%'} top={'17.45%'} left={'26.9%'} onClick={() => changeValue('two', 14, 2, { height: '0.85%', width: '38.6%', top: '17.45%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'38.6%'} top={'18.3%'} left={'26.9%'} onClick={() => changeValue('two', 16, 2, { height: '0.9%', width: '38.6%', top: '18.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'38.6%'} top={'19.2%'} left={'26.9%'} onClick={() => changeValue('two', 28, 2, { height: '0.9%', width: '38.6%', top: '19.2%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'38.6%'} top={'20.1%'} left={'26.9%'} onClick={() => changeValue('two', 22, 2, { height: '0.7%', width: '38.6%', top: '20.1%', left: '26.9%' }, 1)} />
                      <Cell height={'0.85%'} width={'38.6%'} top={'20.8%'} left={'26.9%'} onClick={() => changeValue('two', 28, 2, { height: '0.85%', width: '38.6%', top: '20.8%', left: '26.9%' }, 1)} />
                      <Cell height={'0.8%'} width={'38.6%'} top={'21.65%'} left={'26.9%'} onClick={() => changeValue('two', 34, 2, { height: '0.8%', width: '38.6%', top: '21.65%', left: '26.9%' }, 1)} />
                      <Cell height={'1.5%'} width={'4.8%'} top={'22.4%'} left={'60.6%'} onClick={() => changeValue('two', 50, 2, { height: '1.5%', width: '4.8%', top: '22.4%', left: '60.6%' }, 1)} />
                      <Cell height={'2.2%'} width={'4.8%'} top={'23.9%'} left={'60.6%'} onClick={() => changeValue('two', 50, 2, { height: '2.2%', width: '4.8%', top: '23.9%', left: '60.6%' }, 1)} />
                      <Cell height={'2.2%'} width={'4.8%'} top={'26.1%'} left={'60.6%'} onClick={() => changeValue('two', 50, 2, { height: '2.2%', width: '4.8%', top: '26.1%', left: '60.6%' }, 1)} />


                      <Cell height={'0.75%'} width={'33.8%'} top={'15.3%'} left={'26.9%'} onClick={() => changeValue('two', 6, 2, { height: '0.75%', width: '33.8%', top: '15.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'33.8%'} top={'16.05%'} left={'26.9%'} onClick={() => changeValue('two', 10, 2, { height: '0.7%', width: '33.8%', top: '16.05%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'33.8%'} top={'16.75%'} left={'26.9%'} onClick={() => changeValue('two', 14, 2, { height: '0.7%', width: '33.8%', top: '16.75%', left: '26.9%' }, 1)} />
                      <Cell height={'0.85%'} width={'33.8%'} top={'17.45%'} left={'26.9%'} onClick={() => changeValue('two', 12, 2, { height: '0.85%', width: '33.8%', top: '17.45%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'33.8%'} top={'18.3%'} left={'26.9%'} onClick={() => changeValue('two', 14, 2, { height: '0.9%', width: '33.8%', top: '18.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'33.8%'} top={'19.2%'} left={'26.9%'} onClick={() => changeValue('two', 26, 2, { height: '0.9%', width: '33.8%', top: '19.2%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'33.8%'} top={'20.1%'} left={'26.9%'} onClick={() => changeValue('two', 20, 2, { height: '0.7%', width: '33.8%', top: '20.1%', left: '26.9%' }, 1)} />
                      <Cell height={'0.85%'} width={'33.8%'} top={'20.8%'} left={'26.9%'} onClick={() => changeValue('two', 26, 2, { height: '0.85%', width: '33.8%', top: '20.8%', left: '26.9%' }, 1)} />
                      <Cell height={'0.8%'} width={'33.8%'} top={'21.65%'} left={'26.9%'} onClick={() => changeValue('two', 32, 2, { height: '0.8%', width: '33.8%', top: '21.65%', left: '26.9%' }, 1)} />
                      <Cell height={'1.5%'} width={'4.8%'} top={'22.4%'} left={'55.8%'} onClick={() => changeValue('two', 26, 2, { height: '1.5%', width: '4.8%', top: '22.4%', left: '55.8%' }, 1)} />
                      <Cell height={'2.2%'} width={'4.8%'} top={'23.9%'} left={'55.8%'} onClick={() => changeValue('two', 32, 2, { height: '2.2%', width: '4.8%', top: '23.9%', left: '55.8%' }, 1)} />
                      <Cell height={'2.2%'} width={'4.8%'} top={'26.1%'} left={'55.8%'} onClick={() => changeValue('two', 26, 2, { height: '2.2%', width: '4.8%', top: '26.1%', left: '55.8%' }, 1)} />


                      <Cell height={'0.75%'} width={'28.9%'} top={'15.3%'} left={'26.9%'} onClick={() => changeValue('two', 4, 2, { height: '0.75%', width: '28.9%', top: '15.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'28.9%'} top={'16.05%'} left={'26.9%'} onClick={() => changeValue('two', 8, 2, { height: '0.7%', width: '28.9%', top: '16.05%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'28.9%'} top={'16.75%'} left={'26.9%'} onClick={() => changeValue('two', 12, 2, { height: '0.7%', width: '28.9%', top: '16.75%', left: '26.9%' }, 1)} />
                      <Cell height={'0.85%'} width={'28.9%'} top={'17.45%'} left={'26.9%'} onClick={() => changeValue('two', 10, 2, { height: '0.85%', width: '28.9%', top: '17.45%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'28.9%'} top={'18.3%'} left={'26.9%'} onClick={() => changeValue('two', 12, 2, { height: '0.9%', width: '28.9%', top: '18.3%', left: '26.9%' }, 1)} />
                      <Cell height={'0.9%'} width={'28.9%'} top={'19.2%'} left={'26.9%'} onClick={() => changeValue('two', 24, 2, { height: '0.9%', width: '28.9%', top: '19.2%', left: '26.9%' }, 1)} />
                      <Cell height={'0.7%'} width={'28.9%'} top={'20.1%'} left={'26.9%'} onClick={() => changeValue('two', 18, 2, { height: '0.7%', width: '28.9%', top: '20.1%', left: '26.9%' }, 1)} />
                      <Cell height={'0.85%'} width={'28.9%'} top={'20.8%'} left={'26.9%'} onClick={() => changeValue('two', 24, 2, { height: '0.85%', width: '28.9%', top: '20.8%', left: '26.9%' }, 1)} />
                      <Cell height={'0.8%'} width={'28.9%'} top={'21.65%'} left={'26.9%'} onClick={() => changeValue('two', 30, 2, { height: '0.8%', width: '28.9%', top: '21.65%', left: '26.9%' }, 1)} />
                      <Cell height={'1.5%'} width={'5.5%'} top={'22.4%'} left={'50.3%'} onClick={() => changeValue('two', 24, 2, { height: '1.5%', width: '5.5%', top: '22.4%', left: '50.3%' }, 1)} />
                      <Cell height={'2.2%'} width={'5.5%'} top={'23.9%'} left={'50.3%'} onClick={() => changeValue('two', 30, 2, { height: '2.2%', width: '5.5%', top: '23.9%', left: '50.3%' }, 1)} />
                      <Cell height={'2.2%'} width={'5.5%'} top={'26.1%'} left={'50.3%'} onClick={() => changeValue('two', 24, 2, { height: '2.2%', width: '5.5%', top: '26.1%', left: '50.3%' }, 1)} />


                      {/*行走*/}
                     <>
                      {/*慢*/}
                      <Interpolation name={'內插'} title={'行走 慢'} content={'(單位:kg)'}
                      In_function={(n) => {
                        if(n<0){
                          n = 0
                        }
                        let result=0
                        if(n>=0 && n <3){
                          result = 4
                        }else if(n>=3 && n <=30){
                          result = 0.0025*(n**2) + 0.2955*n + 3.0013
                        }else if(n>30 && n <=40){
                          result = -0.02*(n**2) + 3.5*n-73
                        }
          
                        dispatch(setValue_Normal({ block: 'seven', value: n }))
                        return result
                      }} 
                      re_function={(e) => { return e }}
                      block={'two'} bblock={2} position={{ height: '0.75%', width: '7%', top: '15.25%', left: '19.5%' }} page={1} />
                      
                      {/*適中*/}
                      <Interpolation name={'內插'} title={'行走 適中'} content={'(單位:kg)'}
                      In_function={(n) => {
                        if(n<0){
                          n = 0
                        }
                        let result=0
                        if(n>=0 && n <3){
                          result = 8
                        }else if(n>=3 && n <=30){
                          result = 0.0025*(n**2) + 0.2955*n + 7.0013
                        }else if(n>30 && n <=40){
                          result = -0.04*(n**2) + 5*n - 96
                        }
          
                        dispatch(setValue_Normal({ block: 'seven', value: n }))
                        return result
                      }} 
                      re_function={(e) => { return e }}
                      block={'two'} bblock={2} position={{ height: '0.75%', width: '7%', top: '16%', left: '19.5%' }} page={1} />

                       {/*快速地*/}
                       <Interpolation name={'內插'} title={'行走 快速地'} content={'(單位:kg)'}
                        In_function={(n) => {
                          if(n<0){
                            n = 0
                          }
                          let result=0
                          if(n>=0 && n <3){
                            result = 12
                          }else if(n>=3 && n <=30){
                            result = 0.0025*(n**2) + 0.2955*n + 11.0013
                          }else if(n>30 && n <=35){
                            result = 0.04*(n**2) - 5*(10**-14)*n - 14
                          }else if(n>35 && n <=40){
                            result = 50
                          }
                          dispatch(setValue_Normal({ block: 'seven', value: n }))
                          return result
                      }} 
                      re_function={(e) => { return e }}
                      block={'two'} bblock={2} position={{ height: '0.70%', width: '7%', top: '16.7%', left: '19.5%' }} page={1} />

                     </>
                      {/*爬坡*/}
                      <>
                      {/*傾斜角度<5°*/}
                      <Interpolation name={'內插'} title={'爬坡 傾斜角度<5°'} content={'(單位:kg)'}
                      In_function={(n) => {
                        if(n<0){
                          n = 0
                        }
                        let result=0
                        if(n>=0 && n <3){
                          result = 10
                        }else if(n>=3 && n <=30){
                          result = 0.0025*(n**2) + 0.2955*n + 9.0013
                        }else if(n>30 && n <=35){
                          result = 3*n-70
                        }else if(n>35 && n <=40){
                          result = 50
                        }
          
                        dispatch(setValue_Normal({ block: 'seven', value: n }))
                        return result
                      }} 
                      re_function={(e) => { return e }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '7%', top: '17.45%', left: '19.5%' }} page={1} />

                      {/*傾斜角度5~15°*/}
                      <Interpolation name={'內插'} title={'爬坡 傾斜角度5~15°'} content={'(單位:kg)'}
                      In_function={(n) => {
                        if(n<0){
                          n = 0
                        }
                        let result=0
                        if(n>=0 && n <3){
                          result = 12
                        }else if(n>=3 && n <=30){
                          result = 0.0025*(n**2) + 0.2955*n + 11.0013
                        }else if(n>30 && n <=35){
                          result = 0.04*(n**2) - 5*(10**-14)*n - 14;
                        }else if(n>35 && n <=40){
                          result = 50
                        }
          
                        dispatch(setValue_Normal({ block: 'seven', value: n }))
                        return result
                      }} 
                      re_function={(e) => { return e }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '7%', top: '18.30%', left: '19.5%' }} page={1} />

                      {/*傾斜角度>15°*/}
                      <Interpolation name={'內插'} title={'爬坡 傾斜角度>15°'} content={'(單位:kg)'}
                      In_function={(n) => {
                        if(n<0){
                          n = 0
                        }
                        let result=0
                        if(n>=0 && n <3){
                          result = 24
                        }else if(n>=3 && n <=30){
                          result = 0.0025*(n**2)+ 0.2955*n + 23.0013
                        }else if(n>30 && n <=35){
                          result =0.08*(n**2) - 4*n + 82
                        }else if(n>35 && n <=40){
                          result = 50
                        }
          
                        dispatch(setValue_Normal({ block: 'seven', value: n }))
                        return result
                      }} 
                      re_function={(e) => { return e }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '7%', top: '19.15%', left: '19.5%' }} page={1} />
    
                     </>

                     {/*爬樓梯*/}
                     <>
                      {/*普通樓梯*/}
                      <Interpolation name={'內插'} title={'爬樓梯 普通樓梯'} content={'(單位:kg)'}
                      In_function={(n) => {
                        if(n<0){
                          n = 0
                        }
                        let result=0
                        if(n>=0 && n <3){
                          result = 18
                        }else if(n>=3 && n <=25){
                          result = 0.0036*(n**2) + 0.2668*n + 17.116
                        }else if(n>25 && n <=30){
                          result = 50
                        }else if(n>30 && n <=40){
                          result = 100
                        }
          
                        dispatch(setValue_Normal({ block: 'seven', value: n }))
                        return result
                      }} 
                      re_function={(e) => { return e }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '7%', top: '20%', left: '19.5%' }} page={1} />

                      {/*陡峭的樓梯(35~50°)*/}
                      <Interpolation name={'內插'} title={'爬樓梯 陡峭的樓梯(35~50°)'} content={'(單位:kg)'}
                      In_function={(n) => {
                        if(n<0){
                          n = 0
                        }
                        let result=0
                        if(n>=0 && n <3){
                          result = 24
                        }else if(n>=3 && n <=20){
                          result = 0.0057*(n**2) + 0.2255*n + 23.254
                        }else if(n>20 && n <=25){
                          result = 50
                        }else if(n>25 && n <=40){
                          result = 100
                        }
          
                        dispatch(setValue_Normal({ block: 'seven', value: n }))
                        return result
                      }} 
                      re_function={(e) => { return e }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '7%', top: '20.8%', left: '19.5%' }} page={1} />

                      {/*非常陡峭的樓梯(>50°)*/}
                      <Interpolation name={'內插'} title={'爬樓梯 非常陡峭的樓梯(>50°)'} content={'(單位:kg)'}
                      In_function={(n) => {
                        if(n<0){
                          n = 0
                        }
                        let result=0
                        if(n>=0 && n <3){
                          result = 30
                        }else if(n>=3 && n <=15){
                          result = 0.0095*(n**2) + 0.1619*n + 29.429
                        }else if(n>15 && n <=20){
                          result = 50
                        }else if(n>20 && n <=40){
                          result = 100
                        }
          
                        dispatch(setValue_Normal({ block: 'seven', value: n }))
                        return result
                      }} 
                      re_function={(e) => { return e }}
                      block={'two'} bblock={2} position={{ height: '0.8%', width: '7%', top: '21.6%', left: '19.5%' }} page={1} />
    
                     </>


                    {/*爬梯*/}
                    <>
                    <Interpolation name={'內插'} title={'爬梯 傾角65~75°'} content={'(單位:kg)'}
                    In_function={(n) => {
                      if(n<0){
                        n = 0
                      }
                      let result=0
                      if(n>=0 && n <3){
                        result = 24
                      }else if(n>=3 && n <=10){
                        result =  0.2857*n + 23.143
                      }else if(n>10 && n <=15){
                        result = 50
                      }else if(n>15 && n <=40){
                        result = 100
                      }
        
                      dispatch(setValue_Normal({ block: 'seven', value: n }))
                      return result
                    }} 
                    re_function={(e) => { return e }}
                    block={'two'} bblock={2} position={{ height: '1%', width: '7%', top: '22.4%', left: '28%' }} page={1} />
                    </>

                     {/*攀登*/}
                     <>
                    <Interpolation name={'內插'} title={'攀登 傾角>80°'} content={'(單位:kg)'}
                    In_function={(n) => {
                      if(n<0){
                        n = 0
                      }
                      let result=0
                      if(n>=0 && n <3){
                        result = 30
                      }else if(n>=3 && n <=10){
                        result =  0.2857*n + 29.143
                      }else if(n>10 && n <=15){
                        result = 50
                      }else if(n>15 && n <=40){
                        result = 100
                      }
        
                      dispatch(setValue_Normal({ block: 'seven', value: n }))
                      return result
                    }} 
                    re_function={(e) => { return e }}
                    block={'two'} bblock={2} position={{ height: '1%', width: '7%', top: '24%', left: '28%' }} page={1} />
                    </>

                     {/*爬行，走路時嚴重彎腰*/}
                     <>
                    <Interpolation name={'內插'} title={'爬行，走路時嚴重彎腰'} content={'(單位:kg)'}
                    In_function={(n) => {
                      if(n<0){
                        n = 0
                      }
                      let result=0
                      if(n>=0 && n <3){
                        result = 24
                      }else if(n>=3 && n <=10){
                        result =  0.2857*n + 23.143
                      }else if(n>10 && n <=15){
                        result = 50
                      }else if(n>15 && n <=40){
                        result = 100
                      }
        
                      dispatch(setValue_Normal({ block: 'seven', value: n }))
                      return result
                    }} 
                    re_function={(e) => { return e }}
                    block={'two'} bblock={2} position={{ height: '1%', width: '7%', top: '26.2%', left: '34%' }} page={1} />
                    </>




                      {/* three */}

                      <Cell height={'0.7%'} width={'88.9%'} top={'32.6%'} left={'5.3%'} onClick={() => changeValue('three', 12, 3, { height: '0.7%', width: '88.9%', top: '32.6%', left: '5.3%' }, 1)} />
                      <Cell height={'0.6%'} width={'88.9%'} top={'33.3%'} left={'5.3%'} onClick={() => changeValue('three', 16, 3, { height: '0.6%', width: '88.9%', top: '33.3%', left: '5.3%' }, 1)} />

                      <Cell height={'0.7%'} width={'78.2%'} top={'32.6%'} left={'5.3%'} onClick={() => changeValue('three', 8, 3, { height: '0.7%', width: '78.2%', top: '32.6%', left: '5.3%' }, 1)} />
                      <Cell height={'0.6%'} width={'78.2%'} top={'33.3%'} left={'5.3%'} onClick={() => changeValue('three', 12, 3, { height: '0.6%', width: '78.2%', top: '33.3%', left: '5.3%' }, 1)} />

                      <Cell height={'0.7%'} width={'67.9%'} top={'32.6%'} left={'5.3%'} onClick={() => changeValue('three', 4, 3, { height: '0.7%', width: '67.9%', top: '32.6%', left: '5.3%' }, 1)} />
                      <Cell height={'0.6%'} width={'67.9%'} top={'33.3%'} left={'5.3%'} onClick={() => changeValue('three', 8, 3, { height: '0.6%', width: '67.9%', top: '33.3%', left: '5.3%' }, 1)} />

                      <Cell height={'0.8%'} width={'88.9%'} top={'31.9%'} left={'5.3%'} onClick={() => changeValue('three', 0, 3, { height: '0.8%', width: '88.9%', top: '31.9%', left: '5.3%' }, 1)} />

                      {/* four*/}

                      <Cell height={'0.6%'} width={'88.9%'} top={'35.9%'} left={'5.3%'} onClick={() => changeValue('four', 6, 4, { height: '0.6%', width: '88.9%', top: '35.9%', left: '5.3%' }, 1)} />
                      <Cell height={'0.95%'} width={'88.9%'} top={'36.5%'} left={'5.3%'} onClick={() => changeValue('four', 8, 4, { height: '0.95%', width: '88.9%', top: '36.5%', left: '5.3%' }, 1)} />

                      <Cell height={'0.6%'} width={'78.2%'} top={'35.9%'} left={'5.3%'} onClick={() => changeValue('four', 4, 4, { height: '0.6%', width: '78.2%', top: '35.9%', left: '5.3%' }, 1)} />
                      <Cell height={'0.95%'} width={'78.2%'} top={'36.5%'} left={'5.3%'} onClick={() => changeValue('four', 6, 4, { height: '0.95%', width: '78.2%', top: '36.5%', left: '5.3%' }, 1)} />

                      <Cell height={'0.6%'} width={'67.7%'} top={'35.9%'} left={'5.3%'} onClick={() => changeValue('four', 2, 4, { height: '0.6%', width: '67.7%', top: '35.9%', left: '5.3%' }, 1)} />
                      <Cell height={'0.95%'} width={'67.7%'} top={'36.5%'} left={'5.3%'} onClick={() => changeValue('four', 4, 4, { height: '0.95%', width: '67.7%', top: '36.5%', left: '5.3%' }, 1)} />


                      {/* five*/}

                      <Cell height={'1.1%'} width={'89%'} top={'40.2%'} left={'5.3%'} onClick={() => changeValue('five', 3, 5, { height: '1.1%', width: '89%', top: '40.2%', left: '5.3%' }, 1)} />
                      <Cell height={'1.1%'} width={'89%'} top={'41.3%'} left={'5.3%'} onClick={() => changeValue('five', 5, 5, { height: '1.1%', width: '89%', top: '41.3%', left: '5.3%' }, 1)} />
                      <Cell height={'1.1%'} width={'89%'} top={'42.4%'} left={'5.3%'} onClick={() => changeValue('five', 15, 5, { height: '1.1%', width: '89%', top: '42.4%', left: '5.3%' }, 1)} />

                      <Cell height={'1%'} width={'89%'} top={'43.5%'} left={'5.3%'} onClick={() => changeValue('five', 8, 5, { height: '1%', width: '89%', top: '43.5%', left: '5.3%' }, 1)} />
                      <Cell height={'1%'} width={'83.4%'} top={'43.5%'} left={'5.3%'} onClick={() => changeValue('five', 4, 5, { height: '1%', width: '83.4%', top: '43.5%', left: '5.3%' }, 1)} />



                    </> : null
                  }








                </>
                <img
                  overflow='scroll'
                  width="100%"
                  src={`${KIM_BM_1}`}
                />
              </Block>
              <Block id="Page-2">
                {okPosition[1].map((item, index) => (
                  <OkIcon height={item.height} width={item.width} top={item.top} left={item.left} />
                ))
                }

                <>


                  {currentProjectsKIMBM == 'B' ?
                    <>{/* B */}

                      <div style={{
                        width: '5%',
                        position: 'absolute',
                        top: '52.8%',
                        left: '5.3%',
                        width: '26%',
                        height: '0.7%',
                        backgroundColor: 'rgba(255, 251, 135,1)',
                        mixBlendMode: 'multiply'
                      }}> </div>

                      {/*two */}
                      {/* B */}

                      <Cell height={'0.8%'} width={'89%'} top={'55.3%'} left={'5.3%'} onClick={() => changeValue('two', 9, 2, { height: '0.8%', width: '89%', top: '55.3%', left: '5.3%' }, 1)} />
                      <Cell height={'0.95%'} width={'89%'} top={'56.1%'} left={'5.3%'} onClick={() => changeValue('two', 14, 2, { height: '0.95%', width: '89%', top: '56.1%', left: '5.3%' }, 1)} />
                      <Cell height={'0.8%'} width={'89%'} top={'57.05%'} left={'5.3%'} onClick={() => changeValue('two', 21, 2, { height: '0.8%', width: '89%', top: '57.05%', left: '5.3%' }, 1)} />


                      <Cell height={'0.8%'} width={'78.5%'} top={'55.3%'} left={'5.3%'} onClick={() => changeValue('two', 6, 2, { height: '0.8%', width: '78.5%', top: '55.3%', left: '5.3%' }, 1)} />
                      <Cell height={'0.95%'} width={'78.5%'} top={'56.1%'} left={'5.3%'} onClick={() => changeValue('two', 10, 2, { height: '0.95%', width: '78.5%', top: '56.1%', left: '5.3%' }, 1)} />
                      <Cell height={'0.8%'} width={'78.5%'} top={'57.05%'} left={'5.3%'} onClick={() => changeValue('two', 15, 2, { height: '0.8%', width: '78.5%', top: '57.05%', left: '5.3%' }, 1)} />


                      <Cell height={'0.8%'} width={'68%'} top={'55.3%'} left={'5.3%'} onClick={() => changeValue('two', 3, 2, { height: '0.8%', width: '68%', top: '55.3%', left: '5.3%' }, 1)} />
                      <Cell height={'0.95%'} width={'68%'} top={'56.1%'} left={'5.3%'} onClick={() => changeValue('two', 6, 2, { height: '0.95%', width: '68%', top: '56.1%', left: '5.3%' }, 1)} />
                      <Cell height={'0.8%'} width={'68%'} top={'57.05%'} left={'5.3%'} onClick={() => changeValue('two', 9, 2, { height: '0.8%', width: '68%', top: '57.05%', left: '5.3%' }, 1)} />


                      {/* four*/}
                      {/* B */}

                      <Cell height={'1.1%'} width={'89%'} top={'60.3%'} left={'5.3%'} onClick={() => changeValue('four', 16, 4, { height: '1.1%', width: '89%', top: '60.3%', left: '5.3%' }, 1)} />
                      <Cell height={'1.1%'} width={'78.5%'} top={'60.3%'} left={'5.3%'} onClick={() => changeValue('four', 12, 4, { height: '1.1%', width: '78.5%', top: '60.3%', left: '5.3%' }, 1)} />
                      <Cell height={'1.1%'} width={'67.8%'} top={'60.3%'} left={'5.3%'} onClick={() => changeValue('four', 8, 4, { height: '1.1%', width: '67.8%', top: '60.3%', left: '5.3%' }, 1)} />

                      {/* five*/}
                      {/* B */}
                      <Cell height={'1.71%'} width={'89%'} top={'61.4%'} left={'5.3%'} onClick={() => changeValue('five', 8, 5, { height: '1.71%', width: '89%', top: '61.4%', left: '5.3%' }, 1)} />
                      <Cell height={'1.71%'} width={'73.1%'} top={'61.4%'} left={'5.3%'} onClick={() => changeValue('five', 4, 5, { height: '1.71%', width: '73.1%', top: '61.4%', left: '5.3%' }, 1)} />


                    </> : null
                  }







                  {/* six */}
                  <Cell height={'1.4%'} width={'89%'} top={'66.6%'} left={'5.3%'} onClick={() => changeValue('six', 0, 6, { height: '1.4%', width: '89%', top: '66.6%', left: '5.3%' }, 2)} />
                  <Cell height={'1.45%'} width={'89%'} top={'68%'} left={'5.3%'} onClick={() => changeValue('six', 2, 6, { height: '1.45%', width: '89%', top: '68%', left: '5.3%' }, 2)} />
                  <Cell height={'1.4%'} width={'89%'} top={'69.45%'} left={'5.3%'} onClick={() => changeValue('six', 4, 6, { height: '1.4%', width: '89%', top: '69.45%', left: '5.3%' }, 2)} />


                </>
                <img
                  overflow='scroll'
                  width="100%"
                  src={`${KIM_BM_2}`}
                />

              </Block>
            </TransformComponent>
          </TransformWrapper>

          {/* Rating */}
          <Block height={'100vh'} id="Rating" >

            <Box
              sx={{
                border: '5px solid transparent',
                position: 'absolute',
                backgroundColor: totalRiskColor,
                //  ml: 'auto',
                //  mr: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                margin: 'auto',
                left: 0,
                right: 0,
                height: '100vh',
                //  width:'60vw',
                width: '100%',
              }}
            >
              {totalRisk >= 100 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 高 <br /> a.很可能超過身體負荷 <br />b.更明顯的疾病和/或功能障礙，具有病理意義的結構性損傷</Typography>
              }
              {totalRisk >= 50 && totalRisk < 100 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 中高<br /> a.對於一般耐受力的人，也有可能超過身體負荷 <br />b.疾病（疼痛），可能包括功能障礙，多數情況下可以恢復，沒有形態上的表現</Typography>
              }
              {totalRisk >= 20 && totalRisk < 50 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 中低<br /> a.對於耐受力較差的人，有可能超過身體負荷 <br />b.疲勞、低度適應問題，可於休息時間獲得恢復</Typography>
              }
              {totalRisk < 20 && totalRisk > 0 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值: 低<br /> a.不太可能會超過身體負荷 <br />b.預計不會有健康風險</Typography>
              }
              {totalRisk == 0 &&
                <Typography sx={{ fontSize: '20px', fontWeight: '900', mt: '40px' }} >風險值</Typography>
              }

              <Table>
                <Tr>
                  {KIM_BM_Rating.map((item, index) =>

                  // <Th justify_content = 'center' font_weight='900'>{item}</Th>
                  {
                    if (index == 0 || index == 10) {
                      return (
                        <Th justify_content='center'
                          font_weight='900'>{item}</Th>
                      )
                    } else if (index == 6 || index == 8) {
                      return (
                        <Th
                          justify_content='center'
                          border_bottom='1px black solid'
                          font_weight='900'>{item}</Th>
                      )
                    } else if (index == 7 || index == 9) {
                      return (
                        <Th
                          justify_content='center'>{item}</Th>
                      )
                    } else {
                      return (
                        <Th justify_content='left'
                          border_bottom='1px gray solid'>{item}</Th>
                      )
                    }
                  }
                  )}
                </Tr>

                {total.map((item, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    {total[index].map((item, index) => {
                      if (index == 8 || index == 10) {
                        return (
                          <Td key={index} border_bottom='1px black solid'>{(Math.round(item * 100) / 100)}</Td>
                        )
                      } else if (index == 9 || index == 11 || index == 12) {
                        return (
                          <Td key={index} >{(Math.round(item * 100) / 100)}</Td>
                        )
                      } else {
                        return (
                          <Td key={index} border_bottom='1px gray solid'>{(Math.round(item * 100) / 100)}</Td>
                        )
                      }
                    }
                    )
                    }
                  </Tr>
                ))}


              </Table>
            </Box>
          </Block>
        </Union>
      </div>
    );
  }


  return (
    <div className={cssMain} style={styleMain}>
      <div style={{
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#42abed',
        color: '#fff8e3',
        display: 'flex',
        alignItems: 'center',
      }}>

        <Box
          onClick={() => { return (dispatch(addProject())) }}
          sx={{
            zIndex: 100,
            position: 'absolute',
            display: 'flex',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: '16px',
            fontWeight: '900',
            border: '0px solid white',
            background: 'white',
            borderRadius: '10px',
            marginLeft: '25%',
            padding: '30px',
            color: 'rgb(33, 166, 255)',
            cursor: 'pointer',
            '&:hover': {
              background: 'rgba(247,246,240,0.2)',
              color: 'white',
              border: '2px solid rgba(247,246,240,0.6)',
              boxShadow: '0px 0px 6px 1px rgba(0, 0, 0, 0.2)',
            }
          }}>
          點選新增KIM專案
        </Box>

      </div>

      {/* &nbsp;
          <div style={{ 
            color: '#42abed', 
            fontWeight:'600',
            borderRadius: '30px', 
            backgroundColor: '#fff8e3', 
            width: '20px', height: '20px', 
            alignItems: 'center',
             position: 'relative',
             display:'flex',
             justifyContent:'center' }}>  + </div> */}

    </div>
  );
};

// src={`https://file.rendit.io/n/Uwh483GVBgi0mZXBbNt0.svg`}
//transform: scale(1.5) perspective(1px)


const ScollDownButton = styled.div`
  z-index:1;
  position:fixed;
  bottom: ${(props) => props.bottom};
  right:0px; 
  background-color: #42abed;
  font-size:14px;
  font-weight:900;
  border-radius:100px;
  color: #f5fbff;
  padding:10px;
  margin:20px;
  text-align:center;
  &:hover{
    border:5px solid rgba(239, 71, 111,0.8);
    background-color: rgba(239, 71, 111,0.8);
    cursor: pointer;
  }
`;

const Table = styled.div`
  width: 80%;
  height: 80%;
  margin:auto;
  padding:40px;
  // justify-content:center;
  // aligin-item:center;
  display: flex;
  position: relative;
  background-color: rgb(255, 255, 255,0.8);
  border-radius: 30px;
`;
const Tr = styled.div`
  height: 100%;
  width: 100%;
  flex-basis: 33.33333333%;
  display: flex;
  flex-wrap: wrap;
  // align-content: flex-start;
  align-content: stretch; 
  // padding: 1px 10px;
  margin:10px;
  
`;
const Th = styled.div`
  height:5%;
  width:10vw;
  // border: 3px solid green;
  border-bottom: ${(props) => props.border_bottom};
  flex-basis: 100%;
  display: flex;
  // padding: 5px;
  // text-align: left;
  justify-content: ${(props) => props.justify_content};
  text-align:center;
  // margin:auto;
  font-weight:  ${(props) => props.font_weight};

`;
const Td = styled.div`
  height:5%;
  // border: 3px solid blue;
  border-bottom: ${(props) => props.border_bottom};
  flex-basis: 100%;
  // text-align: left;
  justify-content:center;
  display: flex;
  // text-align:center;
  // margin:auto;
  font-weight:  ${(props) => props.font_weight};
`;

const CityscopreScopeFunctionRoot = styled.div`
  width: 100vw;
  background-color: #121212;
  overflow: hidden;
  position: relative;
`;

const Union = styled.div`

  backgroundColor:red;
  min-height: 100vh;
  // height: 100vh;
  scroll-behavior: smooth;
  position: relative;
  // display: flex;
  // flex-direction: column;

  background-color: #FFFF;
  background-size: cover;
  // position: absolute;

  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // padding: 0px 0px 0px 0px;

`;

const Block = styled.div`
    width:100%;
    height: ${(props) => props.height};
    display: flex;
    align-items: center;
    // flex-direction: column;
    overflow-y: ${(props) => props.overflow == 'scroll' ? 'scroll' : 'hidden'};
    ::-webkit-scrollbar {
      display: none;
    }
`;



const Cell = styled.div`
    font-weight:900;
    //border:1px solid #ff8484; 
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    position: absolute;
    top:${(props) => props.top};
    left:${(props) => props.left};
    text-align:center;
    &:hover{
      // background-color:rgba(255, 252, 156,0.4);
      // border:5px solid #E4E116; 
      background-color:rgba(255, 251, 135,0.35);
      mix-blend-mode: multiply;
      cursor: url(${pointer}) 2 2, pointer;
    }
`;


const OkIcon = styled.div`
    // border:3px solid red;  

    // background: url(${OK});
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position: center;

    background-color:rgba(255, 251, 135,0.35);
    border:5px solid rgba(232, 186, 58,1);
    mix-blend-mode: multiply;
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    position: absolute;
    top:${(props) => props.top};
    left:${(props) => props.left};
    // text-align:center;
    
    // &:hover{
    //   // background-color:rgba(255, 252, 156,0.4);
    //   border:3px solid orange; 
    // }
`;



const Blank = styled.div`
    padding: 3.8vh 0px 3.8vh 0px;
    color:transparent;
    background: transparent;
`;

const Block_Check = styled.div`
    // width: 60vw;
    // height: ${(props) => props.height};
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    bottom:5vh;
`;
const Icon = styled.div`
  width: 47px;
  height: 47px;
  border-radius:30px;
  transition: background 100ms ease-in-out;
  background-color: #D9D9D9;

  background-image:url('./image/done.png');
  background-repeat: space;
  background-position: center;
  &:hover{
    background-color:#828282;
    transition: background 100ms ease-in-out;
  }
`;


const KIMBM_AB = styled.div`
    display:flex;
    font-weight:900;
    font-size:15px;
    position:absolute;
    top: ${(props) => props.top};
    padding:10px;
    justify-content:center;
    align-items: center;
    height:1%;
    left:4%;
    border:2px solid #d4d4d4;
    border-radius:5px;
    color:#9c9c9c;
    font:10px;
    &:hover{
      color:#705509;
      background-color:rgba(255, 251, 135,0.6);
      cursor: url(${pointer}) 2 2, pointer;
      border:2px solid rgba(255, 211, 92,1);
    }
    
`;

const KIMBM_AB_active = styled.div`
    display:flex;
    font-weight:900;
    font-size:15px;
    position:absolute;
    top: ${(props) => props.top};
    padding:10px;
    justify-content:center;
    align-items: center;
    height:1%;
    left:4%;
    border:2px solid rgba(255, 211, 92,1);
    background-color:rgba(255, 251, 135,0.6);
    border-radius:5px;
    color:#705509;
    font:10px;    
`;


// const Icon = styled.img`
//   width: 47px;
//   height: 47px;
// `;

export default Main;