import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import { setValue, setValue_Normal, addOkPostion, changeOkPosition, setTotalCalcu, setSex, setScrollHeight, setCurrentProjectsKIMBM } from "../../redux/features/KIM_info/KIMSlice";

export default function Interpolation(props) {
  const [open, setOpen] = React.useState(false);
  const [inputNumber, setInputNumber] = React.useState(0);
  const { projects, okPosition, currentProject, currentSubProject, currentProjectsKIM, total, currentProjectsKIMBM } = useSelector(state => state.kim)
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const project = currentProject - 1
  const block = props.block
  const subProject = currentSubProject - 1
  const page = props.page - 1
  const KIMNAME = currentProjectsKIM
  let Sex = projects[project][subProject].sex
  let weightValue = 0

  //PlaceHolder
  if (KIMNAME === 'KIM-LHC') {
    if (props.bblock === 1) {//時間
      weightValue = projects[project][subProject][block]
    } else if (props.bblock === 2) {
      if (Sex === 'M') {//負荷
        weightValue = projects[project][subProject]['eighteen']
      } else {
        weightValue = projects[project][subProject]['eighteen']
      }
    } else {
      weightValue = projects[project][subProject][block]
    }
  } else if (KIMNAME === 'KIM-PP') {
    if (props.bblock === 2) {//負荷
      weightValue = projects[project][subProject]['fourteen']
    } else {
      weightValue = projects[project][subProject][block]
    }

  } else if (KIMNAME === 'KIM-MHO') {
    if (props.bblock === 2) {//施力
      weightValue = projects[project][subProject]['seven']
    } else if (props.bblock === 3) {//施力
      weightValue = projects[project][subProject]['eight']
    } else if (props.bblock === 4) {//施力
      weightValue = projects[project][subProject]['nine']
    } else if (props.bblock === 5) {//施力
      weightValue = projects[project][subProject]['ten']
    } else if (props.bblock === 6) {//施力
      weightValue = projects[project][subProject]['eleven']
    } else if (props.bblock === 12) {//施力
      weightValue = projects[project][subProject]['eighteen']
    } else if (props.bblock === 13) {//施力
      weightValue = projects[project][subProject]['nineteen']
    } else if (props.bblock === 14) {//施力
      weightValue = projects[project][subProject]['twenty']
    } else if (props.bblock === 15) {//施力
      weightValue = projects[project][subProject]['twenty-one']
    } else if (props.bblock === 16) {//施力
      weightValue = projects[project][subProject]['twenty-two']
    } else if (props.bblock === 17) {//施力
      weightValue = projects[project][subProject]['twenty-three']
    } else {
      weightValue = projects[project][subProject][block]
    }

  } else if (KIMNAME === 'KIM-BF') {
    if (props.bblock === 2) {//施力
      weightValue = projects[project][subProject]['twelve']
      
    } else {
      weightValue = projects[project][subProject][block]
    }

  }else if (KIMNAME === 'KIM-BM') {
    if (props.bblock === 2) {//施力
      weightValue = projects[project][subProject]['seven']
      
    } else {
      weightValue = projects[project][subProject][block]
    }

  }else if (KIMNAME === 'KIM-ABP') {
    if (props.bblock === 2) {//P
      weightValue = projects[project][subProject]['forty-two']
    } else if (props.bblock === 3) {//P
      weightValue = projects[project][subProject]['forty-three']
    } else if (props.bblock === 4) {//P
      weightValue = projects[project][subProject]['forty-four']
    } else if (props.bblock === 5) {//P
      weightValue = projects[project][subProject]['forty-five']
    } else if (props.bblock === 6) {//P
      weightValue = projects[project][subProject]['forty-six']
    } else if (props.bblock === 7) {//P
      weightValue = projects[project][subProject]['forty-seven']
    } else if (props.bblock === 8) {//P
      weightValue = projects[project][subProject]['forty-eight']
    } else if (props.bblock === 9) {//P
      weightValue = projects[project][subProject]['forty-nine']
    } else if (props.bblock === 10) {//P
      weightValue = projects[project][subProject]['fifty']
    } else if (props.bblock === 11) {//P
      weightValue = projects[project][subProject]['fifty-one']
    } else if (props.bblock === 12) {//P
      weightValue = projects[project][subProject]['fifty-two']
    } else if (props.bblock === 13) {//P
      weightValue = projects[project][subProject]['fifty-three']
    } else if (props.bblock === 14) {//P
      weightValue = projects[project][subProject]['fifty-four']
    } else {
      weightValue = projects[project][subProject][block]
    }

  }


  function changeValue(Block, Value, bBlock, Position, Page) {
    dispatch(setValue({ block: Block, bblock: bBlock, position: Position, page: Page, value: Value }))
    dispatch(addOkPostion({ block: Block, bblock: bBlock, position: Position, page: Page, value: Value }))
    dispatch(setTotalCalcu())

  }


  //   const action = props.action;
  const In_function = props.In_function;
  const re_function = props.re_function;


  const OK = () => {
    if (isNaN(inputNumber)) { return alert('請輸入數字') }
    changeValue(props.block, In_function(inputNumber), props.bblock, props.position, props.page);
    handleClose();
  };




  return (
    <div style={{ position: 'absolute', height: props.position.height, width: props.position.width, top: props.position.top, left: props.position.left }}>
      {
        props.position.width === '7%' ?
          <><Button sx={{
            width: '5.2vw',
            minWidth: '40px',
            padding: '1px',
            height: '100%',
            color: 'black', borderColor: 'black',
            ':hover': {
              backgroundColor: 'rgba(255, 251, 135,0.35)',
              borderColor: 'black',
              mixBlendMode: 'multiply'
            }
          }} variant="outlined" onClick={handleClickOpen}>
            <div style={{ fontSize: '10px' }}>{props.name}</div>
          </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{props.title}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {props.content}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  // label="Email Address"
                  type="email"
                  fullWidth
                  variant="standard"
                  placeholder={parseInt(weightValue) === 0 ? '請輸入值' : Math.round(re_function(weightValue) * 100) / 100}
                  onChange={(event) => dispatch(setInputNumber(event.target.value))}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                {/* <Button onClick={oldvalue==='old value' && oldpostion ==='new postion'? handleClose: oldpostion ==='new postion'? OK_nopo:OK}>OK</Button> */}
                <Button onClick={OK}>OK</Button>
              </DialogActions>
            </Dialog></> :
          <><Button sx={{
            minWidth: '40px',
            padding: '0px',
            height: '100%',
            color: 'black', borderColor: 'black',
            ':hover': {
              backgroundColor: 'rgba(255, 251, 135,0.35)',
              borderColor: 'black',
              mixBlendMode: 'multiply'
            }
          }} variant="outlined" onClick={handleClickOpen}>
            <div style={{ fontSize: '10px' }}>{props.name}</div>
          </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{props.title}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {props.content}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  // label="Email Address"
                  type="email"
                  fullWidth
                  variant="standard"
                  placeholder={parseInt(weightValue) === 0 ? '請輸入值' : Math.round(re_function(weightValue) * 100) / 100}
                  onChange={(event) => dispatch(setInputNumber(event.target.value))}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                {/* <Button onClick={oldvalue==='old value' && oldpostion ==='new postion'? handleClose: oldpostion ==='new postion'? OK_nopo:OK}>OK</Button> */}
                <Button onClick={OK}>OK</Button>
              </DialogActions>
            </Dialog></>
      }
    </div>

  );
}
